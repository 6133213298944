import React from 'react';
import { syncProducts, resyncProducts, syncProduct, saveProducts, resaveProducts, saveMetadata, syncProductMetafields, syncProductVariantMetafields, syncProductImages, createProductCustomContents, deleteProductCustomContent, exportProducts, importProducts, exportProductMetafields, importProductMetafields, clearExportUrls, resetErrors } from '../../modules/products'
import withRouter from 'with-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ItemsList from '../../components/ItemsList'
import ItemsListSkeleton from '../../components/ItemsListSkeleton'
import LoadingIndicator from '../../components/LoadingIndicator';
import Button from '@material-ui/core/Button';
import EmptyView from '../../components/EmptyView';
//import BulkTranslations from '../../components/BulkTranslations';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import OptionsMenu from '../../components/OptionsMenu';
import NewFlexItemList from '../../components/NewFlexItemList';
import cloneDeep from 'lodash/cloneDeep';
import API from '../../api/api.js';
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import ExportImportDialogs from '../../components/ExportImportDialogs';
import { _clearUnchangedFields } from '../../helper';

const styles = theme => ({
  
});

class Products extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      products: [],
      metadata: [
      {
        id: 'tags',
        synchronized: false,
        title: 'Tags',
        info: '',
        fields: []
      },{
        id: 'types',
        synchronized: false,
        title: 'Types',
        info: '',
        fields: []
      },{
        id: 'vendors',
        synchronized: false,
        title: 'Vendors',
        info: '',
        fields: []
      }
      ],
      addingNewCustomContent: false,
      addingNewCustomContentProductId: null,
      customContentPlainTextData: [],
      forceOpenExportDialog: false,
      forceOpenImportDialog: false,
      showDownloadDialog: false,
      showImportCompleteDialog: false,
      showBulkDialog: false,
      errorLockCollapse: false,
    }
  }

  componentDidMount() {
    this.props.syncProducts(this.props.editLanguage)
    if(this.props.products.export_po_download_url || this.props.products.export_csv_download_url) {
      this.setState({showDownloadDialog: false})
    }
  }

  componentWillUnmount() {
    API.cancelCurrentBulkTimeout();
  }

  getErrorsFor(key, item) {
    if (typeof item.errors === 'undefined') return false;
    let error = item.errors.find(x => x.object_key === key);
    return error;
  }

  componentWillReceiveProps(newProps) {
    const { t } = this.props;
    const currentLanguage = this.props.editLanguage;
    const newLanguage = newProps.editLanguage;
    if(currentLanguage != newLanguage) {
      this.props.syncProducts(newLanguage)
      return
    }

    if(this.props.products.import_products_pending === true && newProps.products.import_products_pending === false) {
      this.setState({showImportCompleteDialog: true}, () => {
        this.setState({showImportCompleteDialog: false});
      })
    }

    if((this.props.products.sync_products_pending === true && newProps.products.sync_products_pending === false) || (this.props.products.sync_product_pending === true && newProps.products.sync_product_pending === false) || (this.props.products.save_products_pending === true && newProps.products.save_products_pending === false) || (this.props.products.save_metadata_pending === true && newProps.products.save_metadata_pending === false)) {
      const newProducts = [];

      newProps.products.products.forEach(item => {
        let optionFields = []
        if(item.options) {
          item.options.forEach((option) => {
            if(option.source !== 'Default Title') {
              optionFields.push({
                id: option.source,
                type: 'text',
                source: option.source,
                target: option.target || ''
              })
            }
          })
        }
        
        let optionNameFields = []
        if(item.optionNames) {
          item.optionNames.forEach((optionName) => {
            if(optionName.source !== 'Title') {
              optionNameFields.push({
                id: optionName.id,
                type: 'text',
                source: optionName.source,
                target: optionName.target || ''
              })
            }
          })
        }

        let customFields = []
        if(item.custom_contents) {
          item.custom_contents.forEach(custom_content => {
            customFields.push({
              id: custom_content.id,
              type: 'text',
              deletable: true,
              multiline: true,
              rows: 7,
              source: custom_content.source.text,
              target: custom_content.target.text
            })
          })
        }

        let seoFields = []

        if(typeof item.target.page_title !== 'undefined') {
          seoFields.push({
            id: 'page_title',
            type: 'text',
            label: 'SEO Title',
            source: item.source.page_title || '',
            target: item.target.page_title || '',
            maxLength: 70,
            customized: item.customSeo.meta_title
          })
        }

        if(typeof item.target.page_description !== 'undefined') {
          seoFields.push({
            multiline: true,
            rows: 6,
            id: 'page_description',
            type: 'text',
            label: 'SEO Description',
            source: item.source.page_description || '',
            target: item.target.page_description || '',
            maxLength: 320,
            customized: item.customSeo.meta_description
          })
        }

        const usedFields = [
            {
              id: 'title',
              type: 'text',
              label: 'Title',
              source: item.source.title,
              target: item.target.title || ''
            },
            {
              multiline: true,
              rows: 10,
              id: 'body_html',
              type: 'richtext',
              label: 'Description',
              source: item.source.body_html || '',
              target: item.target.body_html || '',
            },
            {
              id: 'handle',
              type: 'text',
              label: 'Handle',
              source: item.source.handle,
              target: item.target.handle || '',
              errors: this.getErrorsFor('handle', item) || false
            },
            {
              id: 'seofields',
              type: 'subfields',
              fields: seoFields
            }
        ]

        let currentMetafields = null;
        let currentVariantMetafields = null;
        let currentImagefields = null;
        if(!(this.props.products.sync_products_pending === true && newProps.products.sync_products_pending === false)) {
          const currentProduct = this.state.products.find(x => x.id == item.id);
          if(currentProduct) {
            currentMetafields = currentProduct.fields.find(x => x.id === 'metafields');
            currentVariantMetafields = currentProduct.fields.find(x => x.id === 'variant_metafields');
            currentImagefields = currentProduct.fields.find(x => x.id === 'images');
          }
        }
        
        usedFields.push({
            id: 'images',
            type: 'subfields',
            fields: currentImagefields ? cloneDeep(currentImagefields.fields) : []
        })

        usedFields.push({ 
            id: 'option_names',
            type: 'subfields', 
            fields: optionNameFields
        })
        
        usedFields.push({
            id: 'metafields',
            type: 'subfields',
            fields: currentMetafields ? cloneDeep(currentMetafields.fields) : []
        })

        usedFields.push({ 
            id: 'variants',
            type: 'subfields', 
            fields: optionFields
        })


        usedFields.push({
            id: 'variant_metafields',
            type: 'subfields',
            fields: currentVariantMetafields ? cloneDeep(currentVariantMetafields.fields) : []
        })

        usedFields.push({
            id: 'customfields',
            onAddItem: this.openNewCustomContentDialog,
            onDeleteItem: this.onDeleteCustomContent(item.id),
            emptyMessage: <EmptyView 
              title={t('customContents.noCustomContentInSection')}
              description={t('customContents.info1')}
              bottom={<Button className={this.props.classes.empty_button} variant="outlined" size="small" onClick={e => {this.openNewCustomContentDialog(item.id)}}>{t('customContents.addNewCustomContent')}</Button>}
              dense={true} 
            />,
            type: 'subfields',
            fields: customFields
        })

        newProducts.push({
          type: 'product',
          id: item.id,
          shopify_id: item.shopifyId,
          progress: item.progress,
          synchronized: item.synchronized,
          title: item.source.title,
          metafields_synchronized: item.metafields_synchronized,
          images_synchronized: item.images_synchronized,
          fields: usedFields
        })
      });

      const tagFields = []
      let translatedTags = 0;
      let tagsProgress = 0

      const typeFields = []
      let translatedTypes = 0;
      let typesProgress = 0

      const vendorFields = []
      let translatedVendors = 0;
      let vendorsProgress = 0

      for(let key in newProps.products.tags) {
        const item = newProps.products.tags[key];
        if(item.target) {
          translatedTags++;
        }
        tagFields.push({
                multiline: true,
                rows: 4,
                id: item.id,
                type: 'text',
                source: item.source,
                target: item.target || '',
                vitualized: true,
        })
      }
      if(tagFields.length > 0) {
        tagsProgress = Math.floor(translatedTags / tagFields.length * 100);
        if(tagsProgress > 100) {
          tagsProgress = 100;
        }
      } else {
        tagsProgress = 100;
      }

      for(let key in newProps.products.types) {
        const item = newProps.products.types[key];
        if(item.target) {
          translatedTypes++;
        }
        typeFields.push({
                multiline: true,
                rows: 4,
                id: item.id,
                type: 'text',
                source: item.source,
                target: item.target || '',
                vitualized: true,
        })
      }
      if(typeFields.length > 0) {
        typesProgress = Math.floor(translatedTypes / typeFields.length * 100);
        if(typesProgress > 100) {
          typesProgress = 100;
        }
      } else {
        typesProgress = 100;
      }

      for(let key in newProps.products.vendors) {
        const item = newProps.products.vendors[key];

        //console.log(key)

        if(item.target) {
          translatedVendors++;
        }
        vendorFields.push({
                multiline: true,
                rows: 4,
                id: item.id,
                type: 'text',
                source: item.source,
                target: item.target || '',
                vitualized: true,
        })
      }
      if(vendorFields.length > 0) {
        vendorsProgress = Math.floor(translatedVendors / vendorFields.length * 100);
        if(vendorsProgress > 100) {
          vendorsProgress = 100;
        }
      } else {
        vendorsProgress = 100;
      }

      const newMetadata = [
        {
          id: 'tags',
          progress: tagsProgress,
          synchronized: true,
          title: 'Tags',
          info: '',
          fields: tagFields
        },{
          id: 'types',
          progress: typesProgress,
          synchronized: true,
          title: 'Types',
          info: '',
          fields: typeFields
        },{
          id: 'vendors',
          progress: vendorsProgress,
          synchronized: true,
          title: 'Vendors',
          info: '',
          fields: vendorFields
        }
      ]

      this.setState({
        products:newProducts, 
        metadata:newMetadata
      });
    }
    
    if(this.props.products.sync_product_metafields_pending === true && newProps.products.sync_product_metafields_pending === false) {
      let newProducts = cloneDeep(this.state.products);
      newProps.products.products.forEach(product => {
        if(product.metafields) {
            let productToUpdate = newProducts.find(x => x.id == product.id)

            let newMetafields = []
            product.metafields.forEach(metafield => {
              const currentField = productToUpdate.fields.find(x => x.id === 'metafields').fields.find(x => x.id == metafield.id);
              if(!currentField) {
                newMetafields.push({
                  id: metafield.id,
                  type: 'text',
                  multiline: true,
                  rows: 7,
                  source: metafield.source,
                  target: metafield.target || '',
                  label: metafield.namespace + ' -> ' + metafield.key
                })
              } else {
                if(product.metafields.find(x => x.id === currentField.id)) {
                  newMetafields.push({...currentField});
                }
              }
            })

            productToUpdate.metafields_synchronized = product.metafields_synchronized
            productToUpdate.fields.find(x => x.id === 'metafields').fields = newMetafields
        }
      })

      this.setState({products:newProducts});
    }

    if(this.props.products.sync_product_variant_metafields_pending === true && newProps.products.sync_product_variant_metafields_pending === false) {
      let newProducts = cloneDeep(this.state.products);
      newProps.products.products.forEach(product => {
        if(product.variant_metafields) {
            let productToUpdate = newProducts.find(x => x.id == product.id)

            let newMetafields = []
            product.variant_metafields.forEach(metafield => {
              const currentField = productToUpdate.fields.find(x => x.id === 'variant_metafields').fields.find(x => x.id == metafield.id);
              if(!currentField) {
                newMetafields.push({
                  id: metafield.id,
                  type: 'text',
                  multiline: true,
                  rows: 7,
                  source: metafield.source,
                  target: metafield.target || '',
                  label: metafield.namespace + ' -> ' + metafield.key
                })
              } else {
                if(product.variant_metafields.find(x => x.id === currentField.id)) {
                  newMetafields.push({...currentField});
                }
              }
            })

            productToUpdate.variant_metafields_synchronized = product.variant_metafields_synchronized
            productToUpdate.fields.find(x => x.id === 'variant_metafields').fields = newMetafields
        }
      })

      this.setState({products:newProducts});
    }


    if(this.props.products.sync_product_images_pending === true && newProps.products.sync_product_images_pending === false) {
      
    let newProductsToUpdate = this.state.products;
      newProps.products.products.forEach(product => {
        if(product.images) {
            let productToUpdate = newProductsToUpdate.find(x => x.id == product.id)
            let newImages = []

            product.images.forEach(image => {
              const currentField = productToUpdate.fields.find(x => x.id === 'images').fields.find(x => x.id == image.id);

              if(!currentField) {
                newImages.push({
                  id: image.id,
                  type: 'image',
                  source: image.source.src,
                  target: image.target.src
                })              
              } else {
                if(product.images.find(x => x.id === currentField.id)) {
                  newImages.push({
                    id: currentField.id,
                    type: 'image',
                    source: currentField.source,
                    target: image.target.src
                  })  
                }
              }
            });

            productToUpdate.images_synchronized = product.images_synchronized;
            productToUpdate.fields.find(x => x.id === 'images').fields = newImages;
        }
      })

      this.setState({products:newProductsToUpdate});
    }

    if((this.props.products.create_product_custom_contents_pending === true && newProps.products.create_product_custom_contents_pending === false) || (this.props.products.delete_product_custom_content_pending === true && newProps.products.delete_product_custom_content_pending === false)) {
      let newProducts = cloneDeep(this.state.products);
      newProps.products.products.forEach(product => {
        if(product.custom_contents) {
          let productToUpdate = newProducts.find(x => x.id == product.id)

          let newCustomContents = []
          product.custom_contents.forEach(custom_content => {
            const currentField = productToUpdate.fields.find(x => x.id === 'customfields').fields.find(x => x.id == custom_content.id);
            if(!currentField) {
              newCustomContents.push({
                id: custom_content.id,
                type: 'text',
                deletable: true,
                multiline: true,
                rows: 7,
                source: custom_content.source.text,
                target: custom_content.target.text
              })
            } else {
              if(product.custom_contents.find(x => x.id === currentField.id)) {
                newCustomContents.push({...currentField});
              }
            }
          })

          productToUpdate.fields.find(x => x.id === 'customfields').fields = newCustomContents
        }
      })

      this.setState({products:newProducts});
    }
  }

  onExpandProduct(id) {
    if(this.state.products.find(x => x.id == id).synchronized !== true) {
      this.props.syncProduct(id, this.props.editLanguage);
    }
  }

  onCollapsedProduct(id) {
    
  }

  onSaveProduct = items => {
    const usedItems = [];
    items.forEach(item => {
      if(this.props.shop_version > 2) {
        usedItems.push({
          id: item.id,
          title: item.fields.find(x => x.id === 'title').target,
          body_html: item.fields.find(x => x.id === 'body_html').target,
          handle: item.fields.find(x => x.id === 'handle').source == item.fields.find(x => x.id === 'handle').target ? '' : item.fields.find(x => x.id === 'handle').target,
          page_title: item.fields.find(x => x.id === 'seofields').fields.find(x => x.id === 'page_title').target,
          page_description: item.fields.find(x => x.id === 'seofields').fields.find(x => x.id === 'page_description').target,
          options: item.fields.filter(x => (x.type === 'subfields' && x.id === 'variants'))[0].fields.map(z => ({ source: z.source, target: z.target })),
          option_names: item.fields.filter(x => (x.type === 'subfields' && x.id === 'option_names'))[0].fields.map(z => ({ id: z.id, target: z.target })),
          metafields: item.fields.filter(x => (x.type === 'subfields' && x.id === 'metafields'))[0].fields.map(z => ({ id: z.id, target: z.target })),
          variant_metafields: item.fields.filter(x => (x.type === 'subfields' && x.id === 'variant_metafields'))[0].fields.map(z => ({ id: z.id, target: z.target })),
          images: item.fields.filter(x => (x.type === 'subfields' && x.id === 'images'))[0].fields.map(z => ({ id: z.id, target: z.target })),
          custom_contents: item.fields.filter(x => (x.type === 'subfields' && x.id === 'customfields'))[0].fields.map(z => ({ id: z.id, target: z.target })),
        });
      } else {
        usedItems.push({
          id: item.id,
          title: item.fields.find(x => x.id === 'title').target,
          body_html: item.fields.find(x => x.id === 'body_html').target,
          options: item.fields.filter(x => (x.type === 'subfields' && x.id === 'variants'))[0].fields.map(z => ({ source: z.source, target: z.target })),
          metafields: item.fields.filter(x => (x.type === 'subfields' && x.id === 'metafields'))[0].fields.map(z => ({ source: z.source, target: z.target })),
          images: item.fields.filter(x => (x.type === 'subfields' && x.id === 'images'))[0].fields.map(z => ({ source: z.source, target: z.target })),
        });
      }
    })

    this.props.saveProducts(this.props.editLanguage, usedItems)
  }

  onResaveAllProduct = (itemId) => {
    const item = this.state.products.find(x => x.id == itemId);
    if(item) {
      const usedItems = [{
          id: item.id,
          title: item.fields.find(x => x.id === 'title').target,
          body_html: item.fields.find(x => x.id === 'body_html').target,
          page_title: item.fields.find(x => x.id === 'seofields').fields.find(x => x.id === 'page_title').target,
          page_description: item.fields.find(x => x.id === 'seofields').fields.find(x => x.id === 'page_description').target,
          options: item.fields.filter(x => (x.type === 'subfields' && x.id === 'variants'))[0].fields.map(z => ({ source: z.source, target: z.target })),
          option_names: item.fields.filter(x => (x.type === 'subfields' && x.id === 'option_names'))[0].fields.map(z => ({ id: z.id, target: z.target })),
          metafields: item.fields.filter(x => (x.type === 'subfields' && x.id === 'metafields'))[0].fields.map(z => ({ id: z.id, target: z.target })),
          variant_metafields: item.fields.filter(x => (x.type === 'subfields' && x.id === 'variant_metafields'))[0].fields.map(z => ({ id: z.id, target: z.target })),
          images: item.fields.filter(x => (x.type === 'subfields' && x.id === 'images'))[0].fields.map(z => ({ id: z.id, target: z.target })),
          custom_contents: item.fields.filter(x => (x.type === 'subfields' && x.id === 'customfields'))[0].fields.map(z => ({ id: z.id, target: z.target })),
        }];

      this.props.saveProducts(this.props.editLanguage, usedItems, 1)
    }
  }

  onExpandMetadata(id) {
    
  }

  onCollapsedMetadata(id) {
    
  }

  onExpandCollapsableFields = (type, id) => {
    if(type === 'metafields' && this.state.products.find(x => x.id == id).metafields_synchronized != true) {
      this.props.syncProductMetafields(id, this.props.editLanguage)
    }

    if(type === 'variant_metafields' && this.state.products.find(x => x.id == id).variant_metafields_synchronized != true) {
      this.props.syncProductVariantMetafields(id, this.props.editLanguage)
    }

    if(type === 'images' && this.state.products.find(x => x.id == id).images_synchronized != true) {
      this.props.syncProductImages(id, this.props.editLanguage)
    }
  }

  onSaveMetadata = items => {
    let tags = []
    let types = []
    let vendors = []
    items.forEach(item => {
      if(item.id === 'tags') {
        item.fields.forEach(field => {
          tags.push({
            id: field.id,
            source: field.source,
            target: field.target
          })
        })
      } else if(item.id === 'types') {
        item.fields.forEach(field => {
          types.push({
            id: field.id,
            source: field.source,
            target: field.target
          })
        })
      } else if(item.id === 'vendors') {
        item.fields.forEach(field => {
          vendors.push({
            id: field.id,
            source: field.source,
            target: field.target
          })
        })
      }
    })

    this.props.saveMetadata(this.props.editLanguage, tags, types, vendors)
  }

  onResaveAllMetadata = (itemId) => {
    const item = this.state.metadata.find(x => x.id == itemId);
    if(item) {
      let tags = []
      let types = []
      let vendors = []

      if(item.id === 'tags') {
        item.fields.forEach(field => {
          tags.push({
            id: field.id,
            source: field.source,
            target: field.target
          })
        })
      } else if(item.id === 'types') {
        item.fields.forEach(field => {
          types.push({
            id: field.id,
            source: field.source,
            target: field.target
          })
        })
      } else if(item.id === 'vendors') {
        item.fields.forEach(field => {
          vendors.push({
            id: field.id,
            source: field.source,
            target: field.target
          })
        })
      }

      this.props.saveMetadata(this.props.editLanguage, tags, types, vendors, 1)
    }
  }

  openNewCustomContentDialog = (itemId) => {
    const customContentPlainTextData = [];
    const product = this.state.products.find(x => x.id == itemId);

    product.fields.find(x => x.id == 'customfields').fields.forEach((custom_content) => {
      customContentPlainTextData.push(custom_content.source);
    })

    this.setState({addingNewCustomContent: true, addingNewCustomContentProductId: itemId, customContentPlainTextData: customContentPlainTextData})
  }

  closeNewCustomContentDialog = ()  => {
    this.setState({addingNewCustomContent: false, addingNewCustomContentProductId: null, customContentPlainTextData: []})
  }

  onSaveNewCustomContentDialog = (productId, items) => {
    const usedItems = [];
    items.forEach(item => {
      if(item.text != '') {
        usedItems.push({
          text: item.text
        })
      }
    })
    
    this.setState({addingNewCustomContent: false, addingNewCustomContentProductId: null});
    this.props.createProductCustomContents(productId, this.props.editLanguage, usedItems);
  }

  onDeleteCustomContent = productId => {
    return itemId => {
      this.props.deleteProductCustomContent(productId, itemId);
    }
  }


  onImport = (e) => {
    this.setState({forceOpenImportDialog: true}, () => {
      this.setState({forceOpenImportDialog: false});
    });   
    this.importEvent = e;
    this.setState({ optionsMenuAnchorEl: null });
  }

  onExport = (e) => {
    this.setState({forceOpenExportDialog: true}, () => {
      this.setState({forceOpenExportDialog: false});
    });   
  }

  onImportMetafields = (e) => {
    this.setState({ optionsMenuAnchorEl: null });
    
    const file = e.target.files[0];
    const fileType = file.name.substr(file.name.lastIndexOf('.') + 1).toLowerCase();
    if(fileType != 'mo' && fileType != 'csv') {
      return;
    }
    
    const formData = new FormData();
    formData.append('file', file);

    this.props.importProductMetafields(formData, fileType, this.props.editLanguage)
  }

  onExportMetafields = (e) => {
    /*
    this.setState({forceOpenExportDialog: true}, () => {
      this.setState({forceOpenExportDialog: false});
    });   
    */
    if(!this.props.products.export_po_download_url && !this.props.products.export_csv_download_url) {
      this.props.exportProductMetafields(this.props.editLanguage, () => {
        this.setState({forceOpenExportDialog: true}, () => {
          this.setState({forceOpenExportDialog: false});
        });
      });
    } else {
      //this.setState({showDownloadDialog: true})
      this.setState({forceOpenExportDialog: true}, () => {
        this.setState({forceOpenExportDialog: false});
      });   
    }
  }

  onResaveAll = (e) => {
    const usedItems = [];
    this.state.products.forEach(item => {
      usedItems.push(item.id)
    });
    this.props.resaveProducts(this.props.editLanguage, usedItems)
  }

  onResyncAll = (e) => {
    this.props.resyncProducts(this.props.editLanguage)
  }

  onCloseDownloadDialog = () => {
    //this.setState({showDownloadDialog: false})
  }

  onCloseImportCompleteDialog = () => {
    window.location.reload();
  }

  onBulkTranslateAll = () => {
    this.setState({showBulkDialog: false}, () => {
      this.setState({showBulkDialog: true});
    });
  }

  render() {
    const { t, classes } = this.props
    const targetLanguageCode = this.props.languages.languages.find(x => x.id == this.props.editLanguage) ? this.props.languages.languages.find(x => x.id == this.props.editLanguage).code : null;

    if(this.props.products.sync_products_pending === true || (this.props.products.error && this.props.products.error.level === 0)) {
      return <React.Fragment>
        <Typography variant="h4" gutterBottom component="h2">
          {t('pages.products.title')}
        </Typography>
        <Divider className="divider-white" />
        <ItemsListSkeleton rows="3" hideBottomToolbar/>
        <ItemsListSkeleton rows="10"/>
        <LoadingIndicator
          pending={this.props.products.sync_products_pending || this.props.products.sync_product_pending || this.props.products.save_products_pending || this.props.products.save_metadata_pending || this.props.products.export_products_pending || this.props.products.import_products_pending}
          progress={this.props.products.progress}
          error={this.props.products.error}
          withLogo
        />
      </React.Fragment>  
    }

    return <React.Fragment>
      <Typography variant="h4" gutterBottom component="h2">
        {t('pages.products.title')}

        <OptionsMenu
          onImport={this.onImport}
          onExport={this.onExport}
          onImportMetafields={(this.props.langify_features && this.props.langify_features.find(x => x.name === 'import_export_metafields') && this.props.langify_features.find(x => x.name === 'import_export_metafields').is_enabled == true) ? this.onImportMetafields : null}
          onExportMetafields={(this.props.langify_features && this.props.langify_features.find(x => x.name === 'import_export_metafields') && this.props.langify_features.find(x => x.name === 'import_export_metafields').is_enabled == true) ? this.onExportMetafields : null}
          onResaveAll={this.onResaveAll}
          onResyncAll={this.onResyncAll}
          //onBulkTranslateAll={(this.props.langify_features && this.props.langify_features.find(x => x.name === 'auto_translation_bulk') && this.props.langify_features.find(x => x.name === 'auto_translation_bulk').is_enabled == true) ? this.onBulkTranslateAll : null}
        />
      </Typography>

      {this.state.addingNewCustomContent ? (
        <NewFlexItemList
          scope={this.state.addingNewCustomContentProductId}
          data={this.state.customContentPlainTextData}
          onSave={this.onSaveNewCustomContentDialog}
          onCancel={this.closeNewCustomContentDialog}
          isPending={false}
        />
      ) : (
        null
      )}

      <Divider className="divider-white" />

      <ItemsList 
        type="products"
        virtualized={true}
        pagination={false}
        onExpand={(id) => {this.onExpandMetadata(id)}}
        onCollapsed={this.onCollapsedMetadata}
        isPending={
          this.props.products.save_metadata_pending
        }
        isSaving={
          this.props.products.save_metadata_pending
        }
        deletable={false}
        onSave={this.onSaveMetadata}
        onResaveAll={this.onResaveAllMetadata}
        data={this.state.metadata}
        isSticky={false}
        paginate={false}
        hideFilterbar={true}
        hideSorting={true}
        allowAutoTranslation={true}
        //error={this.props.products.error}
      />
      <Divider className="divider-white" />
      <ItemsList 
        type="products"
        pagination={true}
        onExpand={(id) => {this.onExpandProduct(id)}}
        onCollapsed={this.onCollapsedProduct}
        isPending={
          this.props.products.sync_products_pending ||
          this.props.products.sync_product_pending ||
          this.props.products.sync_product_metafields_pending ||
          this.props.products.sync_product_variant_metafields_pending ||
          this.props.products.sync_product_images_pending ||
          this.props.products.create_product_custom_contents_pending ||
          this.props.products.delete_product_custom_content_pending ||
          this.props.products.save_products_pending ||
          this.props.products.export_products_pending ||
          this.props.products.import_products_pending
        }
        isSubfieldsPending={
          this.props.products.create_product_custom_contents_pending ||
          this.props.products.delete_product_custom_content_pending ||
          this.props.products.sync_product_images_pending ||
          this.props.products.sync_product_metafields_pending ||
          this.props.products.sync_product_variant_metafields_pending
        }
        isSubfieldsRemoving={
          this.props.products.delete_product_custom_content_pending
        }
        isSaving={
          this.props.products.save_products_pending
        }
        deletable={false}
        onSave={this.onSaveProduct}
        onResaveAll={this.onResaveAllProduct}
        data={this.state.products}
        onExpandCollapsableFields={this.onExpandCollapsableFields}
        allowAutoTranslation={true}
        error={this.props.products.error}
        errorLockCollapse={this.state.errorLockCollapse}
        saveErrors={this.props.products.saveErrors}
        emptyMessage={
          <EmptyView 
            title={'No products available'}
            bottom={<Button href={`https://${this.props.shopUrl}/admin/products`} variant="contained" color="secondary">{t('system.createOneHere')}</Button>}
          />
        }
      />
      <LoadingIndicator
        pending={this.props.products.sync_products_pending || this.props.products.sync_product_pending || this.props.products.save_products_pending || this.props.products.save_metadata_pending || this.props.products.sync_product_metafields_pending || this.props.products.sync_product_variant_metafields_pending || this.props.products.sync_product_images_pending || this.props.products.create_product_custom_contents_pending || this.props.products.delete_product_custom_content_pending || this.props.products.export_products_pending || this.props.products.import_products_pending}
        loadingType={(this.props.products.save_products_pending || this.props.products.save_metadata_pending) ? 'saving' : 'loading'}
        progress={this.props.products.progress}
        error={this.props.products.error}
        onErrorCancel={this.props.resetErrors}
        onErrorRetry={() => {
          this.setState({ errorLockCollapse: true }, () => {
            this.setState({ errorLockCollapse: false });
          });
        }}
        withLogo
      />

      {/*this.props.langify_features && this.props.langify_features.find(x => x.name === 'auto_translation_bulk') && this.props.langify_features.find(x => x.name === 'auto_translation_bulk').is_enabled === true && targetLanguageCode &&
        <BulkTranslations
          scope="products"
          targetLanguageCode={targetLanguageCode}
          open={this.state.showBulkDialog}
        />
      */}

      <ExportImportDialogs
        showDownloadDialog={this.state.showDownloadDialog}
        showImportCompleteDialog={this.state.showImportCompleteDialog}
        imported_entries={this.props.products.imported_entries}
        export_po_download_url={this.props.products.export_po_download_url}
        export_csv_download_url={this.props.products.export_csv_download_url}
        export_advanced_csv_download_url={this.props.products.export_advanced_csv_download_url}
        forceOpenExportDialog={this.state.forceOpenExportDialog}
        forceOpenImportDialog={this.state.forceOpenImportDialog}
        exportFunc={(options) => { this.props.exportProducts(this.props.editLanguage, options) }}
        exportMetafieldsFunc={(options) => { this.props.exportProductMetafields(this.props.editLanguage, options) }}
        importFunc={(formData, fileType, options) => { this.props.importProducts(formData, fileType, options, this.props.editLanguage) }}
        syncFunc={() => { this.props.syncProducts(this.props.editLanguage)}}
        clearFunc={this.props.clearExportUrls}
        pending={this.props.products.export_products_pending}
        scope={'products'}
        additionalFields={['custom_contents', 'product_tags', 'product_types', 'product_vendors']}
      />
    </React.Fragment> 
  }
}

const mapStateToProps = ({ products, shop, languages }) => ({
  products: products,
  editLanguage: shop.shop.edit_language,
  shop_version: shop.shop.version,
  shopUrl: shop.shop.url,
  langify_features: shop.shop.langify_features,
  languages: languages,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      syncProducts,
      resyncProducts,
      syncProduct,
      saveProducts,
      resaveProducts,
      saveMetadata,
      syncProductMetafields,
      syncProductVariantMetafields,
      syncProductImages,
      createProductCustomContents,
      deleteProductCustomContent,
      exportProducts,
      importProducts,
      exportProductMetafields,
      importProductMetafields,
      clearExportUrls,
      resetErrors
    },
    dispatch
  )

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(Products))))