import React from 'react';
import { setLangifyNotificationAsUnviewed, setLangifyNotificationAsViewed, getLangifyNotifications, resetErrors } from '../../modules/langifyNotifications'
import withRouter from 'with-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ItemsList from '../../components/ItemsList'
import ItemsListSkeleton from '../../components/ItemsListSkeleton'
import LoadingIndicator from '../../components/LoadingIndicator';
import EmptyView from '../../components/EmptyView';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import OptionsMenu from '../../components/OptionsMenu';
import NewFlexItemList from '../../components/NewFlexItemList';
import cloneDeep from 'lodash/cloneDeep';
import Link from '@material-ui/core/Link';
import { Trans, withTranslation, useTranslation } from 'react-i18next';

const styles = theme => ({
  
});

class Profile extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      
    }
  }

  render() {
    return <React.Fragment>
      <h1>Profile</h1>
      <Link href='/profile/notifications'>Notifications</Link>
    </React.Fragment>
  }
}
const mapStateToProps = ({ langifyNotifications, shop }) => ({
  notifications: langifyNotifications.notifications,
  editLanguage: shop.shop.edit_language,
  shopUrl: shop.shop.url,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getLangifyNotifications,
      setLangifyNotificationAsUnviewed,
      setLangifyNotificationAsViewed,
      resetErrors,
    },
    dispatch
  )

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(Profile))))