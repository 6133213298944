import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux'
import withRouter from 'with-router';
import { connect } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import classNames from 'classnames';
import Radio from '@material-ui/core/Radio';
import Fade from '@material-ui/core/Fade';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputBase from '@material-ui/core/InputBase';
import Slider from '@material-ui/core/Slider';
import MenuItem from '@material-ui/core/MenuItem';
import Link from '@material-ui/core/Link';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import LaptopMacOutlined from '@material-ui/icons/LaptopMacOutlined';
import TabletMacOutlined from '@material-ui/icons/TabletMacOutlined';
import PhoneIphoneOutlined from '@material-ui/icons/PhoneIphoneOutlined';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import CloseIcon from '@material-ui/icons/Close';

import TranslateOutlinedIcon from '@material-ui/icons/TranslateOutlined';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import FlipToFrontOutlinedIcon from '@material-ui/icons/FlipToFrontOutlined';
import CompareOutlinedIcon from '@material-ui/icons/CompareOutlined';
import FormatLineSpacingOutlinedIcon from '@material-ui/icons/FormatLineSpacingOutlined';
import ColorLensOutlinedIcon from '@material-ui/icons/ColorLensOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';

import { deprecatedPropType, TextField } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Popper from '@material-ui/core/Popper';
import Alert from '@material-ui/lab/Alert';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Switch from '@material-ui/core/Switch';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import Toolbar from '@material-ui/core/Toolbar';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import 'codemirror/lib/codemirror.css'; 
import 'codemirror/theme/material.css';
import 'codemirror/mode/css/css';
import {Controlled as CodeMirror} from 'react-codemirror2';
import { Resizable } from "re-resizable";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import _ from 'lodash';

import API from '../api/api';
//import SwitcherConfiguratorAssistant from './SwitcherConfiguratorAssistant';
import { CustomSwitcher, PopupSwitcher, PopupSwitcherModal, NativeSwitcher, ListSwitcher, LinkSwitcher, CurrencySwitcher, Recommendation } from './SwitcherConfiguratorSwitchers';
import CollapsableSection from './CollapsableSection';
import OptionsMenu from './OptionsMenu';
import DomainLocationMapper from "./DomainLocationMapper";
import SortableList from './SortableList';
import ColorPicker from './ColorPicker';
import SolutionsArticleLink from './SolutionsArticleLink';
import TextObjectTranslator from './TextObjectTranslator';
import switcherConfigurator, { uploadFlagIcons } from '../modules/switcherConfigurator';
import languages from "../modules/languages";
import LoadingIndicator from '../components/LoadingIndicator';
import { NoEncryption, ThumbDownSharp } from "@material-ui/icons";
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import { data } from "jquery";
import { updateSwitcherLanguages, getSwitcherConfig, getCustomIcons, updateCustomIconsMetafield } from '../modules/switcherConfigurator';
import { syncMarkets } from '../modules/markets';
import ConfirmationDialog from './ConfirmationDialog';

const REACT_APP_ENABLE_DOMAIN_FEATURE = window.loadEnv("REACT_APP_ENABLE_DOMAIN_FEATURE");

function TabContainer(props) {
  return (
    <div className={props.rootClass} style={{ padding: typeof props.padding !== 'undefined' ? props.padding : 24, width: '100%', position: 'relative' }}>
      {props.children}
    </div>
  );
}

class SwitcherConfigurator extends React.Component {

  delimiterStart = '{% comment %}ly_switcher_code_start{% endcomment %}';
  delimiterEnd = '{% comment %}ly_switcher_code_end{% endcomment %}';
  defaultConfig = {
    type: 'custom',
    corner: 'bottom_right',
    position: 'fixed',
    show_flags: true,
    rectangle_icons: true,
    square_icons: false,
    round_icons: false,
    show_label: true,
    show_custom_name: true,
    show_name: false,
    show_iso_code: false,
    is_dark: false,
    is_transparent: false,
    is_dropup: false,
    arrow_size: 100,
    arrow_width: 1,
    arrow_filled: false,
    h_space: 0,
    v_space: 0,
    h_item_space: 0,
    v_item_space: 0,
    h_item_padding: 0,
    v_item_padding: 0,
    text_color: '#000',
    link_color: '#000',
    arrow_color: '#000',
    bg_color: '#fff',
    border_color: '#000',
    border_width: 0,
    border_radius: 0,
    fontsize: 14,
    button_bg_color: '#999',
    button_text_color: '#fff',
    recommendation_fontsize: 14, 
    recommendation_border_width: 0, 
    recommendation_border_radius: 0,
    recommendation_text_color: '#000',
    recommendation_link_color: '#000',
    recommendation_button_bg_color: '#999',
    recommendation_button_text_color: '#fff',
    recommendation_bg_color: '#fff',
    recommendation_border_color: '#000',
    currency_switcher_enabled: false,
    show_currency_selector: false,
    show_country_selector_inside_popup: false,
    show_currency_selector_inside_popup: false,
    country_switcher_enabled: false,
    country_switcher_format: '[[country_name]] ([[country_currency_code]] [[country_currency_symbol]])',
    popup_switcher_type: 'links_list',
    popup_show_world_icon: false,
    use_submit_button: false,
  };
  switcherSettings = {};
  wireframes = {
    desktop: { img: 'Desktop-x1.png', viewport: { width: 1309, height: 868, ratio: 1.51 }, padding: '72px 85px' },
    tablet_landscape: { img: 'iPadPro-Landscape-x1.png', viewport: { width: 1275, height: 952, ratio: 1.33 }, padding: '61px 105px' },
    tablet: { img: 'iPadPro-Portrait-x1.png', viewport: { width: 950, height: 1273, ratio: 0.75 }, padding: '105px 52px' },
    mobile: { img: 'Mobile-x1.png', viewport: { width: 352, height: 635, ratio: 0.56 }, padding: '100px 30px' },
  }
  previewImageGenerationInterval = null;
  shiftIsPressed = false;
  mouseOverPreviewFrame = false;

  constructor(props) {
    super(props);

    this.state = {
      pending: false,
      loaded: false,
      configuratorIsOpen: false,
      hasOptions: false,
      tab: 'config',
      type: 'custom',
      tabIndex: 0,
      corner: 'bottom_right',
      position: 'fixed',
      show_flags: true,
      rectangle_icons: true,
      square_icons: false,
      round_icons: false,
      show_label: true,
      show_custom_name: true,
      show_name: false,
      show_iso_code: false,
      is_dark: false,
      is_transparent: false,
      is_dropup: false,
      border_width: 0,
      border_radius: 0,
      arrow_size: 100,
      arrow_width: 1,
      arrow_filled: false,
      open: false,
      dropdown_open: false,
      currency_dropdown_open: false,
      country_dropdown_open: false,
      popup_open: false,
      h_space: 0,
      v_space: 0,
      h_item_space: 0,
      v_item_space: 0,
      h_item_padding: 0,
      v_item_padding: 0,
      text_color: '#000',
      link_color: '#000',
      arrow_color: '#000',
      bg_color: '#fff',
      border_color: '#000',
      button_bg_color: '#999',
      button_text_color: '#fff',
      show_currency_selector: false,
      currency_switcher_enabled: false,
      country_switcher_enabled: false,
      recommendation_enabled: false,
      recommendation_type: 'popup',
      recommendation_switcher_key: -1,
      recommendation_open: false,
      recommendation_backdrop_show: true, 
      recommendation_corner: 'center_center',
      recommendation_banner_corner: 'top',
      recommendation_fontsize: 14, 
      recommendation_border_width: 0, 
      recommendation_border_radius: 0,
      recommendation_text_color: '#000',
      recommendation_link_color: '#000',
      recommendation_button_bg_color: '#999',
      recommendation_button_text_color: '#fff',
      recommendation_bg_color: '#fff',
      recommendation_border_color: '#000',
      recommendation_strings: {},
      fontsize: 14,
      customCSS: '',
      languageDetection: false,
      languageDetectionDefault: props.languages.find(x => x.base === true).code,
      enableDefaultLanguage: false,
      currentBreakpoint: 1000,
      newBreakpointInput: '',
      newBreakpointFormVisible: false,
      breakpoints: [
        { key: 0, label: '0px', config: Object.assign({}, this.defaultConfig) },
        { key: 1000, label: '1000px', config: Object.assign({}, this.defaultConfig) },
      ],
      labelWidth: 100,
      appearance: ['show_custom_name', 'show_name', 'show_iso_code', 'show_flags', 'is-dark', 'is-transparent'],
      themeId: '',
      previewImageLoaded: false,
      previewContainerMargin: 0,
      switcherLabel: 'show_custom_name',
      switcherIcons: 'rectangle_icons',
      settingsChanged: false,
      openSections: ['alignment', 'country', 'positioning', 'appearance', 'spacings', 'type', 'text', 'popup'],
      wireframe: 'desktop',
      scaledMode: true,
      openBreakpointSettings: false,
      arrowRef: null,
      anchorEl: null,
      limitToBounds: true,
      disablePreviewZoomScroll: true,
      itemsOrder: [],
      languages: null, // [],
      marketLanguages: null,
      scrollToTop: false,
      advancedOptions: false,
      showVersionDiffMsg: false,
      migrateConfigDialogOpen: false,
      migrationSourceTheme: '',
      migrationConfig: null,
      country_switcher_format: '[[country_name]] ([[country_currency_code]] [[country_currency_symbol]])',
      popup_switcher_type: 'links_list',
      popup_show_world_icon: false,
      show_country_selector_inside_popup: false,
      show_currency_selector_inside_popup: false,
      use_submit_button: false,
    };

    this.previewFrameRef = React.createRef();
    this.previewFrameWrapper = React.createRef();
    this.previewFrameContainer = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.loadSwitcherSettings(); 
    //this.handleToggleSidebarEntry('alignment');
    this.props.syncMarkets();
  }

  componentWillReceiveProps(newProps) {
    const currentTheme = this.props.editTheme;
    const newTheme = newProps.editTheme;
    if(currentTheme != newTheme) {
      this.loadSwitcherSettings();
    }
  }

  componentDidUpdate(prevProps) {
    // Update Config from outside eg. when a language is disabled
    if(this.props.updateSwitcherConfig !== prevProps.updateSwitcherConfig && typeof this.props.updateSwitcherConfig === 'object') {
      this.processLoadedOptions(this.props.updateSwitcherConfig);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  convertLanguagesToShopifyFormat(languages) {
    var newLanguages = [];
    languages.map((language, index) => {
      if(language.published) {
        let _domain = language.domain ? language.domain : false;
        let _root_url = language.base ? '/' : '/' + language.code;
        if(typeof language.assigned_to_domains.find(item => item.is_default === true) !== 'undefined') {
          _domain = language.assigned_to_domains.find(item => item.is_default === true).host;
          _root_url = '/';
        }  
        newLanguages.push({
          iso_code: language.code,
          country_code: language.country_code ? language.country_code : null,
          currency_code: language.currency_code ? language.currency_code : null,
          root_url: _root_url,
          name: language.name,
          primary: language.base,
          domain: _domain,
          published: language.published,
          custom_name: language.custom_name ? language.custom_name : false
        });      
      }
    });
    return newLanguages;
  }

  loadSwitcherSettings = () => {
    if(!this.props.themes.themes.find(x => x.id == this.props.editTheme)) {
      return;
    }


    this.setState({
      themeId: this.props.themes.themes.find(x => x.id == this.props.editTheme).shopify_id,
      loaded: false,
    });

    const currentTheme = this.props.themes.themes.find(x => x.id == this.props.editTheme);
    this.props.getCustomIcons();
    this.props.getSwitcherConfig(currentTheme.shopify_id, (data) => {

      if(data.success && data.result && data.result.value) {
        let options = JSON.parse(data.result.value);
        if(options) {
          // Checken ob ly_switcher_data_global 
          API.getMetafield('global', 'ly_switcher_data_global', (data2) => {
            if(data2.success && data2.result.length === 0) {
              API.saveCustomMetafield('global', `ly_switcher_data_global`, JSON.stringify(options), 'json_string', (res) => {
    
              });
            }
          });

          this.processLoadedOptions(options);
          return true;
        } 
      }

      this.setState({
        loaded: true,
        languages: this.convertLanguagesToShopifyFormat(this.props.languages)
      }, () => {
        this.updateDimensions();
      });
    });
  }

  processLoadedOptions = (options) => {
    //console.log(options);
    if(Object.keys(options).length === 0 && options.constructor === Object) {
      this.setState({
        hasOptions: false,
        loaded: true
      });
      return null;
    }
    
    // Cache deep clone of settings
    this.switcherSettings = JSON.parse(JSON.stringify(options));

    let breakpoints = [...options.breakpoints.sort((a, b) => (a.key > b.key) ? 1 : -1)];
    let maxBreakpoint = [...breakpoints].reduce((prev, current) => (prev.key > current.key) ? prev : current)
  
    // Inject version related default values
    const getDefaultValue = (bp, key) => {  
      if(key === 'show_label') if(bp.config.show_custom_name || bp.config.show_name || bp.config.show_iso_code) return true; else return false; 
      if(key === 'show_flags') if(bp.config.rectangle_icons || bp.config.square_icons || bp.config.round_icons) return true; else return false; 
      if(key === 'show_custom_name') if(bp.config.show_name || bp.config.show_iso_code || (!bp.config.show_name && !bp.config.show_custom_name && !bp.config.show_iso_code)) return false; else return true; 
      if(key === 'switcherLabel') { 
        if(bp.config.show_custom_name) return 'show_custom_name'; 
        if(bp.config.show_name) return 'show_name'; 
        if(bp.config.show_iso_code) return 'show_iso_code'; 
        return false; 
      }
      if(key === 'switcherIcons') { 
        if(bp.config.rectangle_icons) return 'rectangle_icons'; 
        if(bp.config.square_icons) return 'square_icons'; 
        if(bp.config.round_icons) return 'round_icons'; 
        return false; 
      }
    }
    breakpoints = breakpoints.map(breakpoint => {
      delete breakpoint.config.customCSS;
      delete breakpoint.config.languageDetection;

      breakpoint.config.show_label = breakpoint.config.show_label ? breakpoint.config.show_label : getDefaultValue(breakpoint, 'show_label');
      breakpoint.config.show_custom_name = breakpoint.config.show_custom_name ? breakpoint.config.show_custom_name : getDefaultValue(breakpoint, 'show_custom_name');
      breakpoint.config.switcherLabel = breakpoint.config.switcherLabel ? breakpoint.config.switcherLabel : getDefaultValue(breakpoint, 'switcherLabel');

      breakpoint.config.show_flags = breakpoint.config.show_flags ? breakpoint.config.show_flags : getDefaultValue(breakpoint, 'show_flags');
      //breakpoint.config.rectangle_icons = breakpoint.config.rectangle_icons ? breakpoint.config.rectangle_icons : ((breakpoint.config.show_flags && !breakpoint.config.rectangle_icons && !breakpoint.config.square_icons && !breakpoint.config.round_icons) ? true : false);
      breakpoint.config.switcherIcons = breakpoint.config.switcherIcons ? breakpoint.config.switcherIcons : getDefaultValue(breakpoint, 'switcherIcons');

      breakpoint.config.fontsize = breakpoint.config.fontsize ? breakpoint.config.fontsize : 14;
      breakpoint.config.text_color = breakpoint.config.text_color ? breakpoint.config.text_color : (breakpoint.config.is_dark ? '#ffffff' : '#000000');
      breakpoint.config.bg_color = breakpoint.config.bg_color ? breakpoint.config.bg_color : (breakpoint.config.is_dark ? '#000000' : '#ffffff');
      breakpoint.config.arrow_size = (breakpoint.config.arrow_size || breakpoint.config.arrow_size  == 0) ? breakpoint.config.arrow_size : 100;
      breakpoint.config.arrow_width = breakpoint.config.arrow_width ? breakpoint.config.arrow_width : 1;
      breakpoint.config.arrow_filled = breakpoint.config.arrow_filled ? breakpoint.config.arrow_filled : false;
      breakpoint.config.show_currency_selector = breakpoint.config.show_currency_selector ? breakpoint.config.show_currency_selector : false;
      breakpoint.config.country_switcher_enabled = breakpoint.config.country_switcher_enabled ? breakpoint.config.country_switcher_enabled : false;
      breakpoint.config.currency_switcher_enabled = breakpoint.config.currency_switcher_enabled ? breakpoint.config.currency_switcher_enabled : false;
      breakpoint.config.recommendation_fontsize = breakpoint.config.recommendation_fontsize ? breakpoint.config.recommendation_fontsize : 14;
      breakpoint.config.recommendation_border_width = breakpoint.config.recommendation_border_width ? breakpoint.config.recommendation_border_width : 0;
      breakpoint.config.recommendation_border_radius = breakpoint.config.recommendation_border_radius ? breakpoint.config.recommendation_border_radius : 0;
      breakpoint.config.recommendation_text_color = breakpoint.config.recommendation_text_color ? breakpoint.config.recommendation_text_color : '#000';
      breakpoint.config.recommendation_link_color = breakpoint.config.recommendation_link_color ? breakpoint.config.recommendation_link_color : '#000';
      breakpoint.config.recommendation_button_bg_color = breakpoint.config.recommendation_button_bg_color ? breakpoint.config.recommendation_button_bg_color : '#999';
      breakpoint.config.recommendation_button_text_color = breakpoint.config.recommendation_button_text_color ? breakpoint.config.recommendation_button_text_color : '#fff';
      breakpoint.config.recommendation_bg_color = breakpoint.config.recommendation_bg_color ? breakpoint.config.recommendation_bg_color : '#fff';
      breakpoint.config.recommendation_border_color = breakpoint.config.recommendation_border_color ? breakpoint.config.recommendation_border_color : '#000';
      breakpoint.config.button_bg_color = breakpoint.config.button_bg_color ? breakpoint.config.button_bg_color : '#999';
      breakpoint.config.button_text_color = breakpoint.config.button_text_color ? breakpoint.config.button_text_color : '#fff';
      return breakpoint;
    });

    // Migrate MarketLanguages Array
    let theMarketLanguages = [];
    if(!options.marketLanguages) {
      if(this.props.crossDomainLinksEnabled && options.languages !== null) {
        theMarketLanguages = options.languages;
      }
    }
    
    // Set configurator states
    this.setState(state => {
      return {
        hasOptions: true,
        loaded: true,
        breakpoints: breakpoints,
        currentBreakpoint: maxBreakpoint.key,
        breakpointFromValue: maxBreakpoint.key,
        labelWidth: this.InputLabelRef ? ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth : 75,
        ...options,
        ...options.recommendation,
        ...maxBreakpoint.config,
        languages: options.languages ? options.languages : this.convertLanguagesToShopifyFormat(this.props.languages),
        marketLanguages: options.marketLanguages ? options.marketLanguages : theMarketLanguages,
        itemsOrder: this.getItemsOrderFromConfig(options.languages),
      };

    }, () => {
      this.updateDimensions();
    });
  }

  onImport = (e) => {
    this.setState({ optionsMenuAnchorEl: null });
    const reader = new FileReader();
    const file = e.target.files[0];
    const fileType = file.name.substr(file.name.lastIndexOf('.') + 1);
    if(fileType != 'json') {
      return;
    }
    reader.onloadend = (evt) => {
      if (evt.target.readyState == FileReader.DONE) {
        const options = JSON.parse(evt.target.result);
        if(options) {
          this.processLoadedOptions(options);
          this.handleSaveConfig();          
        }
      }
    };
    var blob = file.slice(0, file.size);
    reader.readAsText(blob);
  }

  onExport = () => {
    const download = (filename, text) => {
      var element = document.createElement('a');
      element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(text));
      element.setAttribute('download', filename);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
    download(`switcher-config-${this.props.editTheme}.json`, JSON.stringify(this.switcherSettings));
  }
 

  onResaveAll = () => {
    this.handleSaveConfig();
  }
  onRevertConfig = () => {
    this.setState({ showConfirmationDialog: true });
  }
  doRevertConfig = () => {
    this.setState({
      loaded: false,
      showConfirmationDialog: false
    });

    let switcherSettings = this.getCurrentSettings(true);

    const currentTheme = this.props.themes.themes.find(x => x.id == this.props.editTheme);
    API.saveCustomMetafield('global', `ly_switcher_data_${currentTheme.shopify_id}`, JSON.stringify(''), 'json_string', (res) => {
      if(res.success) {
        this.switcherSettings = switcherSettings;
        this.handleCancel();
        this.setState({
          loaded: true,
        })
      }
    });
  }

  getCurrentSettings = (resetSettings = false) => {
    var result = {
      customCSS:                      resetSettings ? '' : this.state.customCSS,
      languageDetection:              resetSettings ? false : this.state.languageDetection,
      languageDetectionDefault:       resetSettings ? this.props.languages.find(x => x.base === true).code : this.state.languageDetectionDefault,  
      enableDefaultLanguage:          resetSettings ? false : this.state.enableDefaultLanguage,
      breakpoints:                    resetSettings ? [{ key: 0, label: '0px', config: Object.assign({}, this.defaultConfig) }, { key: 1000, label: '1000px', config: Object.assign({}, this.defaultConfig) }] : [...this.state.breakpoints],
      languages:                      resetSettings ? this.convertLanguagesToShopifyFormat(this.props.languages) : this.state.languages,
      marketLanguages:                resetSettings ? [] : this.state.marketLanguages,
      recommendation_enabled:         resetSettings ? false : this.state.recommendation_enabled,
      recommendation_type:            resetSettings ? 'popup' : this.state.recommendation_type,
      recommendation_switcher_key:    resetSettings ? -1 : this.state.recommendation_switcher_key,
      recommendation_strings:         resetSettings ? {} : this.state.recommendation_strings,
      recommendation: {
        recommendation_backdrop_show: resetSettings ? true : this.state.recommendation_backdrop_show, 
        recommendation_corner:        resetSettings ? 'center_center' : this.state.recommendation_corner,
        recommendation_banner_corner: resetSettings ? 'top' : this.state.recommendation_banner_corner,
        recommendation_fontsize:      resetSettings ? 14 : this.state.recommendation_fontsize, 
        recommendation_border_width:  resetSettings ? 0 : this.state.recommendation_border_width, 
        recommendation_border_radius: resetSettings ? 0 : this.state.recommendation_border_radius,
        recommendation_text_color:    resetSettings ? '#000' : this.state.recommendation_text_color,
        recommendation_link_color:    resetSettings ? '#000' : this.state.recommendation_link_color,
        recommendation_button_bg_color: resetSettings ? '#999' : this.state.recommendation_button_bg_color,
        recommendation_button_text_color: resetSettings ? '#fff' : this.state.recommendation_button_text_color,
        recommendation_bg_color:      resetSettings ? '#fff' : this.state.recommendation_bg_color,
        recommendation_border_color:  resetSettings ? '#000' : this.state.recommendation_border_color,
      }
    };
    return result;
  }

  checkSettingsDiff = () => {
    /*
    let flatSortedJson = (breakpoints) => {
      let result = '';
      Object.values(breakpoints).forEach(bp => {
        if(bp.config) {
          let sorted = Object.keys(bp.config).sort().reduce((acc, key) => ({...acc, [key]: bp.config[key] }), {})
          
          result = result + Object.values(sorted).join(',') + ',';
        }
      });
      return result;
    }
    let settings1 = JSON.parse(JSON.stringify(this.switcherSettings));
    let settings2 = this.getCurrentSettings();
    settings1.breakpoints = true; //flatSortedJson(settings1.breakpoints);
    settings2.breakpoints = false; //flatSortedJson(settings2.breakpoints);
    if(_.isEqual(settings1, settings2)) {
      this.setState({settingsChanged: false});
    }
    */
  }

  handleCancel = () => {
    this.processLoadedOptions(this.switcherSettings);
    this.setState({settingsChanged: false, configuratorIsOpen: false});
  }
  handleOpened = () => {
    this.setState({configuratorIsOpen: true});
  }
   
  addSnippetToTheme = (callback) => {
    API.addV3Scripts(
      this.props.editTheme,
      response => {
        callback();
      }
    )
  }

  handleChangeTab = (event, newValue) => {
    this.setState({
      tabIndex: newValue,
      openBreakpointSettings: false,
      scrollToTop: !this.state.scrollToTop,
      popup_open: false,
      dropdown_open: false,
    })
  }

  handleSaveConfig = () => {
    this.setState({
      loaded: false,
      hasOptions: true,
    });

    const currentTheme = this.props.themes.themes.find(x => x.id == this.props.editTheme);
    let switcherSettings = this.getCurrentSettings();
    if(this.props.themes.themes.find(x => x.role === 'main').id == this.props.editTheme) {
      API.saveCustomMetafield('global', `ly_switcher_data_global`, JSON.stringify(switcherSettings), 'json_string', (res) => {

      });
    }
    API.saveCustomMetafield('global', `ly_switcher_data_${currentTheme.shopify_id}`, JSON.stringify(switcherSettings), 'json_string', (res) => {
      this.switcherSettings = switcherSettings;
      if(res.success) {
        this.setState({
          loaded: true,
          settingsChanged: false,
        })
      }
    });

    /*
    uploadFlagIcons(this.props.languages, this.props.editTheme);
    this.addSnippetToTheme(() => {
      this.setState({
        loaded: true,
      });
    });
    */
  }
  handleDropdownToggle = () => {
    this.setState({
      dropdown_open: !this.state.dropdown_open
    });
  }
  handleCurrencyToggle = (scope) => {
    this.setState({
      [`${scope}_dropdown_open`]: !this.state[`${scope}_dropdown_open`]
    });
  }
  handlePopupToggle = () => {
    if(window.overPopup === false) {
      this.setState({
        popup_open: !this.state.popup_open
      });    
    }
  }
  handleRecommendationToggle = () => {
    this.setState({
      recommendation_open: !this.state.recommendation_open
    });
  }

  setBreakpointValue = (name, value) => {
    let currentBreakpointIndex = this.state.breakpoints.findIndex(x => x.key == this.state.currentBreakpoint);
    let newData = [...this.state.breakpoints];
    if(typeof newData[currentBreakpointIndex].config === 'undefined') newData[currentBreakpointIndex].config = [];
    newData[currentBreakpointIndex].config[name] = value;

    this.setState({ 
      [name]: value,
      breakpoints: newData,
      settingsChanged: true, 
    }, 
      this.checkSettingsDiff
    );
  }

  setGlobalValue = (name, value) => {
    this.setState({ 
      [name]: value,
      settingsChanged: true,
    }, 
      this.checkSettingsDiff
    );
  }





  /* ================================================================= */
  //
  // Breakpoint Configuration
  //
  /* ================================================================= */
  handleAddBreakpoint = (e) => {
    //console.log(e);
    this.setState({ 
      anchorEl: e ? e.currentTarget : null,
      openBreakpointSettings: true,
      newBreakpointFormVisible: true,
    });
  }

  handleSubmitBreakpoint = () => {
    const keyExists = this.state.breakpoints.find(x => x.key === parseInt(this.state.newBreakpointInput));
    if(this.state.newBreakpointInput >= 450 && this.state.newBreakpointInput <= 1500 && !keyExists) {
      this.setState(state => {
        let currentBreakpointIndex = this.state.breakpoints.findIndex(x => x.key == this.state.currentBreakpoint);
        let breakpoints = [...state.breakpoints];
        breakpoints.push({key: parseInt(this.state.newBreakpointInput), label: `${this.state.newBreakpointInput}px`, config: Object.assign({}, this.defaultConfig)})
        breakpoints.sort((a, b) => a.key - b.key);
        return { 
          openBreakpointSettings: false,
          settingsChanged: true,
          breakpoints: breakpoints,
          currentBreakpoint: parseInt(this.state.newBreakpointInput),
          breakpointFromValue: parseInt(this.state.newBreakpointInput),
          newBreakpointFormVisible: false,
          previewImageLoaded: false,
          wireframe: this.getCurrentWireframe(parseInt(this.state.newBreakpointInput)),
          ...Object.assign({}, this.defaultConfig)
        };
      }, () => {
        this.updateDimensions();
        this.forceUpdate();
      });
    }
  }

  handleRemoveBreakpoint = data => () => {
    this.setState(state => {
      let breakpoints = [...state.breakpoints];
      let chipToDelete = breakpoints.indexOf(data);
      breakpoints.splice(chipToDelete, 1);
      return { 
        openBreakpointSettings: false,
        settingsChanged: true,
        breakpoints,
      };
    }, () => {
      let breakpoints = [...this.state.breakpoints];
      this.handleSelectBreakpoint(breakpoints[breakpoints.length-1], null);
    });
  };

  handleSelectBreakpoint = (data, e) => {
    this.handleShowBreakpointSettings(e);
    if(this.state.currentBreakpoint != data.key) {
      this.setState({
        currentBreakpoint: data.key,
        breakpointFromValue: data.key,
        newBreakpointFormVisible: false,
        previewImageLoaded: false,
        wireframe: this.getCurrentWireframe(data.key),
        ...data.config
      }, () => {
        this.updateDimensions();
        this.forceUpdate();
      }); 
    }
  };

  handleShowBreakpointSettings = (e) => {
    this.setState({
      anchorEl: e ? e.currentTarget : null,
      openBreakpointSettings: true,
      newBreakpointFormVisible: false,
    });
  } 

  handleSaveBreakpointSettings = (e) => {
    this.setState({
      openBreakpointSettings: false,
    });
  }

  handleCloseBreakpointSettings = (e) => {
    this.setState({
      openBreakpointSettings: false,
    });
  }

  handleResetAddBreakpoint = (e) => {
    this.setState({
      openBreakpointSettings: false,
      newBreakpointFormVisible: false,
    });
  }

  getCurrentWireframe = (cbp) => {
    const {breakpoints} = this.state;
    let wireframe = 'mobile';
    //let cbpIndex = breakpoints.findIndex(x => x.key === cbp);
    //if((breakpoints.length === 3 && cbpIndex === 1) || (breakpoints.length === 4 && cbpIndex === 1)) wireframe = 'tablet';
    //if(breakpoints.length === 4 && (cbpIndex === 2)) wireframe = 'tablet_landscape';
    //if(breakpoints.length > 1 && cbpIndex === breakpoints.length-1) wireframe = 'desktop';

    if(cbp >= 480) wireframe = 'tablet';
    if(cbp >= 768) wireframe = 'tablet_landscape';
    if(cbp >= 1024 || breakpoints.length === 1) wireframe = 'desktop';
    return wireframe;
  }

  scaleAmountNeededToFit(wrapper, container, margin) {
    const parentSize = {
      width: wrapper.clientWidth - margin * 2,
      height: wrapper.clientHeight - margin * 2
    };

    return Math.min(parentSize.width / container.clientWidth,
      parentSize.height / container.clientHeight);
  }
  updateDimensions = () => {
    let wrapper = document.getElementById('previewFrameWrapper');
    let container = document.getElementById('previewFrameContainer');
    if(wrapper && wrapper.clientWidth === 0) {
      wrapper = this.previewFrameWrapper.current;
      container = this.previewFrameContainer.current;
    }
    if(wrapper && container) {
      let scaleFactor = this.scaleAmountNeededToFit(wrapper, container, 0);
      this.setState({ 
        previewScale: scaleFactor,
      });
    }
  };

  handleChange = name => event => {
    this.setState({ 
      [name]: event.target.value,
      //settingsChanged: true,
    }, 
      null //this.checkSettingsDiff
    );
  };

  handleSetLabel = event => {
    this.setBreakpointValue('show_custom_name', false);
    this.setBreakpointValue('show_name', false);
    this.setBreakpointValue('show_iso_code', false);
    this.setBreakpointValue(event.target.value, true);
    this.setBreakpointValue('switcherLabel', event.target.value);
  }

  handleSetIcons = event => {
    this.setBreakpointValue('rectangle_icons', false);
    this.setBreakpointValue('square_icons', false);
    this.setBreakpointValue('round_icons', false);
    this.setBreakpointValue(event.target.value, true);
    this.setBreakpointValue('switcherIcons', event.target.value);
  }

  handleArrowRef = node => {
    this.setState({
      arrowRef: node,
    });
  };
  changeBreakpointPositions = () => {
    let currentBreakpointIndex = this.state.breakpoints.findIndex(x => x.key == this.state.currentBreakpoint);
    let newBreakpoints = JSON.parse(JSON.stringify(this.state.breakpoints));
    let newValue = parseInt(this.state.breakpointFromValue);
    let valueDiff = parseInt(this.state.breakpointFromValue - this.state.currentBreakpoint);
    newBreakpoints.map((data, i, a) => {
      let indexDiff = parseInt(i - currentBreakpointIndex);
      if(valueDiff > 0) {
        if(data.key <= newValue && i >= currentBreakpointIndex) {
          newBreakpoints[i].key = parseInt(newValue + indexDiff);
        }
      }
      if(valueDiff < 0) {
        if(data.key >= newValue && i <= currentBreakpointIndex) {
          newBreakpoints[i].key = parseInt(newValue - indexDiff);
        }
      }
    });
    this.setState({
      breakpoints: newBreakpoints.sort((a, b) => (a.key > b.key) ? 1 : -1),
      currentBreakpoint: newValue,
      settingsChanged: true,
      openBreakpointSettings: false,
    })
  }
  renderBreakpoints = () => {
    const { t, classes } = this.props;
    const { arrowRef, newBreakpointFormVisible } = this.state;
    let me = this;

    function getBreakpointToValue() {
      let toValue = t('switcherConfigurator.upToEnd');
      let currentBreakpointIndex = me.state.breakpoints.findIndex(x => x.key == me.state.currentBreakpoint);
      if(currentBreakpointIndex+1 < me.state.breakpoints.length) {
        toValue = t('switcherConfigurator.upToPixels', {position: me.state.breakpoints[currentBreakpointIndex+1].key - 1});
      }
      return toValue;
    }

    return (
      <React.Fragment>
        <div onMouseLeave={!newBreakpointFormVisible ? this.handleCloseBreakpointSettings : null} style={{textAlign: 'center', padding: 12, position: 'absolute', top: 0, left: '50%', width: '100%', transform: 'translateX(-50%)', zIndex: 1}}>

          {this.state.breakpoints.length < 4 &&
            <IconButton
              key={'add-breakpoint-btn'}
              color={'secondary'}
              onClick={this.state.newBreakpointFormVisible ? this.handleResetAddBreakpoint : this.handleAddBreakpoint}
              size="small"
              style={{
                transform: this.state.newBreakpointFormVisible ? 'rotate(45deg)' : '',
                padding: 5,
              }}
            >
              <AddIcon fontSize="medium" />
            </IconButton>
          }
          
          {[...this.state.breakpoints].sort((a, b) => (a.key < b.key) ? 1 : -1).map((data, i, a) => {
            let label = 'Mobile';
            let icon = <PhoneIphoneOutlined />;
            let tooltip = '';

            if(data.key >= 480) {
              label = 'Tablet';
              icon = <TabletMacOutlined />;
            }
            if(data.key >= 768) {
              label = 'Tablet';
              icon = <TabletMacOutlined />;
            }
            if(data.key >= 1024 || data.length === 1) {
              label = 'Desktop';
              icon = <LaptopMacOutlined />;
            }
            if(label !== data.label && 'Base Viewport' !== data.label && data.key === this.state.currentBreakpoint) {
              tooltip = `${label} (${data.label} and more)`;
            }

            return (
              <Chip
                key={`breakpoint-${data.key}`}
                avatar={<Avatar>{icon}</Avatar>}
                label={data.key === this.state.currentBreakpoint ? label : ''}
                variant={'outlined'}
                onDelete={(data.key === this.state.currentBreakpoint && data.key !== 0 && data.key !== -1) ? this.handleRemoveBreakpoint(data) : null}
                className={classNames(classes.chip, data.key === this.state.currentBreakpoint ? '' : 'in')}
                clickable={data.key !== -1}
                onClick={(e) => this.handleSelectBreakpoint(data, e)}
                color={'secondary'}
                onMouseEnter={(data.key === this.state.currentBreakpoint && !newBreakpointFormVisible) ? this.handleShowBreakpointSettings : null}
              />
            );
          })}


          <Popper
            open={this.state.openBreakpointSettings}
            anchorEl={this.state.anchorEl}
            placement={'bottom'}
            disablePortal={true}
            className={classes.breakpointSettingsDialog}
            style={{
              marginTop: this.state.newBreakpointFormVisible ? -20 : -12,
            }}
            modifiers={{
              arrow: {
                enabled: true,
                element: arrowRef,
              },
            }}
          >
            <span className={classes.arrow} ref={this.handleArrowRef} />
            <Paper>
              <DialogContent classes={{root: classes.breakpointSettingsDialogContent}}>
                {this.state.newBreakpointFormVisible ?
                  <React.Fragment>
                    <DialogContentText align="left">{t('switcherConfigurator.breakpointAddNew')}</DialogContentText>
                    <TextField
                      //label="break position"
                      id="standard-bare"
                      className={classNames(classes.breakpointInput, 'from')}
                      placeholder={t('switcherConfigurator.breakpointPlaceholder')}
                      helperText={t('switcherConfigurator.breakpointInfo')}
                      type="number"
                      value={this.state.newBreakpointInput}
                      onChange={this.handleChange('newBreakpointInput')}
                      mask={[/[0-9]/]}
                      InputProps={{
                        endAdornment: <InputAdornment component="span" position="end" style={{width: 24, marginLeft: 0}}>px</InputAdornment>,
                      }}  
                    />     
                    <Button className={classes.breakpointInputSubmit} onClick={this.handleSubmitBreakpoint} color="secondary" size="small" variant="contained">
                      {t('switcherConfigurator.breakpointCreate')}
                    </Button>               
                  </React.Fragment>
                :
                  <React.Fragment>
                    <DialogContentText align="left">{t('switcherConfigurator.breakpointEdit')}</DialogContentText>  
                    <span className={classes.breakpointInputDivider}>
                      {t('switcherConfigurator.breakpointFrom')}
                    </span>
                    <TextField
                      //label="from"
                      id="brakpoint-from-value"
                      className={classNames(classes.breakpointInput)}
                      type="number"
                      value={this.state.breakpointFromValue}
                      disabled={this.state.currentBreakpoint === 0}
                      onChange={this.handleChange('breakpointFromValue')}
                      mask={[/[0-9]/]}
                      InputProps={{
                        endAdornment: <InputAdornment component="span" position="end" style={{width: 24}}>px</InputAdornment>,
                      }}  
                    />
                    <span className={classes.breakpointInputDivider}>
                      {getBreakpointToValue()}
                    </span>
                    <Button onClick={this.changeBreakpointPositions} className={classes.breakpointInputSubmit} disabled={false} variant="contained" size="small" color="secondary">
                      {t('switcherConfigurator.breakpointChange')}
                    </Button>
                  </React.Fragment>
                }
              </DialogContent>
            </Paper>
          </Popper>
        </div>
      </React.Fragment>
    );
  }






  /* ================================================================= */
  //
  // Migrate Configuration
  //
  /* ================================================================= */
  openMigrateConfigDialog = () => {
    this.setState({
      migrateConfigDialogOpen: true,
      migrationSourceTheme: '',
      migrationConfig: null,
    });
  }
  selectMigrationSource = (event) => {
    this.setState({
      migrationConfigLoaded: false,
      migrationConfig: null,
      migrationSourceTheme: event.target.value
    })
    API.getMetafield('global', `ly_switcher_data_${event.target.value}`, (data) => {
      if(data.success && data.result && data.result.value) {
        let options = JSON.parse(data.result.value);
        if(options) {
          this.setState({
            migrationConfigLoaded: true,
            migrationConfig: options,
          });   
        } else {
          this.setState({
            migrationConfigLoaded: true,
            migrationConfig: null,
          });         
        }
      } else {
        this.setState({
          migrationConfigLoaded: true,
          migrationConfig: null,
        });         
      }
    });
  }
  onMigrateConfig = () => {
    this.processLoadedOptions(this.state.migrationConfig);
    this.updateDimensions();
    this.setState({
      settingsChanged: true,
      migrationSourceTheme: '',
      migrateConfigDialogOpen: false,
      migrationConfig: null,
    });
    window.location.hash = '#switcher-configurator';
  } 
  renderMigrateConfigDialog = () => {
    const { t, classes } = this.props;
    const { migrateConfigDialogOpen, migrationSourceTheme } = this.state;

    return (
      <Dialog
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={migrateConfigDialogOpen}
        onClose={() => {this.setState({migrateConfigDialogOpen: false})}}
      >
        <DialogTitle id="confirmation-dialog-title">
          {t('switcherConfigurator.migrateConfiguration')}
          <IconButton autoFocus onClick={() => {this.setState({migrateConfigDialogOpen: false})}} style={{position: 'absolute', top: -8, right: -8}}>
              <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {(this.state.migrationSourceTheme && !this.state.migrationConfig && this.state.migrationConfigLoaded) &&
            <div style={{ padding: 16, margin: '-16px -24px 16px -24px' }} className={!this.state.migrationConfig ? classes.error : classes.success}>
              <Typography>{t('switcherConfigurator.migrateConfigurationError')}</Typography>
            </div>
          }
          <DialogContentText>{t('switcherConfigurator.migrateConfigurationInfo')}</DialogContentText>
          <Typography>{t('switcherConfigurator.migrateConfigurationLabel')}</Typography>
          <Select
            ref={null}
            aria-label="config_srctheme"
            name="config_srctheme"
            value={migrationSourceTheme}
            onChange={this.selectMigrationSource}
            fullWidth
            variant="outlined"
            placeholder="Select source theme"
            displayEmpty
          >
            <MenuItem value="">
              <em>{t('switcherConfigurator.migrateConfigurationPlaceholder')}</em>
            </MenuItem>
            {this.props.themes.themes.map((theme) => {
              if(this.props.themes.themes.find(x => x.id == this.props.editTheme) && theme.shopify_id === this.props.themes.themes.find(x => x.id == this.props.editTheme).shopify_id
                 || (theme.name === 'langify media (do not delete)')
              ) {
                return false;
              } else {
                return <MenuItem value={theme.shopify_id} key={`migrate-theme-${theme.shopify_id}`}>
                  <span style={{marginRight: 10}}>{ theme.name } </span><br />
                  <small>{t('switcherConfigurator.lastSaved')} { theme.updated_at }</small>
                </MenuItem>
              }
            })}
          </Select>
          <div style={{textAlign: 'center', padding: 8}}>
            <MergeTypeIcon fontSize={'large'} style={{transform:'rotate(180deg)'}} />
          </div>
          {/*<Typography>Insert config to:</Typography>*/}
          <Select
            variant="outlined"
            defaultValue={1}
            disabled
            fullWidth
          >
            <MenuItem value={1}>
              {this.props.themes.themes.find(x => x.id == this.props.editTheme).name}<br />
              <small>{t('switcherConfigurator.lastSaved')} { this.props.themes.themes.find(x => x.id == this.props.editTheme).updated_at }</small>
            </MenuItem>
          </Select>

          <Button onClick={this.onMigrateConfig} disabled={!this.state.migrationConfig} variant="contained" color="secondary" size="large" fullWidth style={{marginTop: 16}}>
            <MergeTypeIcon style={{transform:'rotate(180deg)'}} /> {t('switcherConfigurator.migrateConfiguration')}
          </Button>
        </DialogContent>

        {/*(this.state.migrationSourceTheme && this.state.migrationConfigLoaded) &&
          <React.Fragment>
            <div style={{ padding: 16 }} className={!this.state.migrationConfig ? classes.error : classes.success}>
              <Typography>{this.state.migrationConfig ? 'Switcher configuration found:' : 'No switcher configuration found in this theme.'}</Typography>
              {this.state.migrationConfig &&
                <textarea defaultValue={JSON.stringify(this.state.migrationConfig)} style={{width: '100%', height: 100}} />
              }
            </div>
            <Divider style={{margin: 0}} />
          </React.Fragment>
        */}
      </Dialog>
    );
  }



  render() {
    if(!this.props.themes.themes.find(x => x.id == this.props.editTheme)) {
      return null
    }

    const { t, classes, languages } = this.props;
    const { loaded, tabIndex, scrollToTop, advancedOptions } = this.state;

    return (
      <CollapsableSection
        id={'SwitcherConfigurator'}
        deeplink="switcher-configurator"
        className={classes.collapsableSection}
        title={t('switcherConfigurator.title')}
        ctaBtnText={t('switcherConfigurator.save')}
        ctaBtnAction={this.handleSaveConfig}
        ctaBtnHide={!this.state.settingsChanged}
        openBtnHide={false}
        openBtnText={t('switcherConfigurator.configure')}
        cancelBtnHide={false}
        cancelBtnText={this.state.settingsChanged ? t('switcherConfigurator.cancel') : t('switcherConfigurator.close')}
        noticeShow={(!this.state.hasOptions && loaded)}
        noticeType={'info'}
        noticeText={<span>
            <Trans i18nKey={'switcherConfigurator.alertNoSwitcherConfiguration'}></Trans>
            <Button variant="outlined" size="small" color="secondary" onClick={() => {this.setState({migrateConfigDialogOpen:true});}} style={{fontWeight:500, position: 'absolute', top: 4, right: 16}}><MergeTypeIcon style={{transform:'rotate(180deg)'}} /> {t('system.migrateConfiguration')}</Button>
          </span>
        }
        pending={!loaded}
        onClosed={this.handleCancel}
        onOpened={this.handleOpened}
        pinSectionOnOpen={true}
        scrollToTop={scrollToTop}
        content={this.state.languages ? 
        <React.Fragment>

          <Tabs
            value={tabIndex}
            onChange={this.handleChangeTab}
            classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
            //centered
          >
            <Tab
              disableRipple
              classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
              label={t('switcherConfigurator.selectorsTab')}
            />
            <Tab
              disableRipple
              classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
              label={t('switcherConfigurator.recommendationTab')}
              style={{overflow: 'visible'}}
            />
            <Tab
              disableRipple
              classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
              label={t('switcherConfigurator.detectionTab')}
            />
            <Tab
              disableRipple
              classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
              style={{position: 'absolute', right: 45}}
              label={<span style={{fontSize: '.75rem'}}>Custom CSS</span>}
            />
            {
              (REACT_APP_ENABLE_DOMAIN_FEATURE === 'true' && this.props.shopFeatures && this.props.shopFeatures.internationalDomains === true) ? (
                <Tab
                  disableRipple
                  classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                  label="Market Domains"
                />
              ) : null
            }

            {/*<FormControlLabel
              control={<Switch size="small" checked={advancedOptions} onChange={() => this.setState({advancedOptions: !this.state.advancedOptions})} />}
              label={<span style={{fontSize: '.75rem', marginTop: -2, display: 'inherit'}}>Advanced settings</span>}
              labelPlacement="start"
              style={{position: 'absolute', alignSelf: 'center', right: 150, fontSize: '.75rem'}}
            />*/}
            <OptionsMenu
              onImportFile={this.onImport}
              onExport={this.onExport}
              onResaveAll={this.onResaveAll}
              onMigrateConfig={this.openMigrateConfigDialog}
              onRevertConfig={this.onRevertConfig}
              importLabel={t('switcherConfigurator.import')}
              exportLabel={t('switcherConfigurator.export')}
              jsonFormat
              inlined
            />
            <ConfirmationDialog 
              title="Reset Switcher-Configuration?"
              description={"This will clear all switcher configuration to default. This action can't be undone."}
              open={this.state.showConfirmationDialog}
              onConfirm={() => { this.doRevertConfig(); }}
              onClose={() => { this.setState({ showConfirmationDialog: false })}}
            />
            
          </Tabs>

          <Divider />

          <div style={{ display: tabIndex === 0 ? 'block' : 'none' }}>
            {tabIndex === 0 ? this.renderSelectorsTab() : null}
          </div>
          <div style={{ display: tabIndex === 1 ? 'block' : 'none' }}>
            {tabIndex === 1 ? this.renderRecommendationTab() : null}
          </div>          
          <div style={{ display: tabIndex === 2 ? 'block' : 'none' }}>
            {this.renderBehaviorTab()}
          </div>
          <div style={{ display: tabIndex === 4 && REACT_APP_ENABLE_DOMAIN_FEATURE  === 'true' ? 'block' : 'none' }}>
            {REACT_APP_ENABLE_DOMAIN_FEATURE  === 'true' && this.renderDomainsTab()}
          </div>
          {tabIndex === 3 && 
            <div>
              {this.renderCustomCssTab()}
            </div>
          }

          {this.renderMigrateConfigDialog()}
        </React.Fragment>
        : null
        } 
      />
    );
  }

  handleToggleSidebarEntry = (sectionKey) => {
    let openSections = this.state.openSections;
    openSections.map(item => {
      openSections[item] = item == sectionKey ? !openSections[sectionKey] : false;
    });
    this.setState({
      openSections: openSections,
    })
  }

  handleRecommendationStringsChange = (data) => {
    this.setState({
      recommendation_strings: data,
      settingsChanged: true,
    });
  }



  /* ================================================================= */
  //
  // Languages Configuration
  //
  /* ================================================================= */
  handleIconUploaded = (url, key, alt) => {
    // Damit auch mit deaktivierten Cross-Domain-Links custom Icon Bilder angezeigt werden können, werden alle Uploads in einem Objekt mit den Language und Country codes als key abgelegt und als Metafield gespeichert.
    this.props.updateCustomIconsMetafield('add', key, url, alt);
  }
  handleIconRemoved = (key) => {
    // Hier das custom Icon Bild aus dem Objekt & Metafield entfernen.
    this.props.updateCustomIconsMetafield('del', key);
  }
  

  handleItemsOrderChange = (orderArr) => {
    this.setState({
      itemsOrder: orderArr
    });

    let newLanguages = this.state.languages.slice().sort(function(a, b){  
      return orderArr.findIndex(x => x.code === a.iso_code) - orderArr.findIndex(x => x.code === b.iso_code);
    });
    //console.log('-------------', orderArr, newLanguages);
    this.setGlobalValue('languages', newLanguages);
  }
  getItemsOrderFromConfig = (languages) => {
    if(!languages) {
      return this.props.languages;
    }
    let orderArr = [];
    languages.map(language => {
      orderArr.push(this.props.languages.find(x => x.code === language.iso_code));
    });
    return orderArr;
  }

  handleSwitcherTypeChanged = (event) => {
    this.setBreakpointValue('type', event.target.value);

    if(event.target.value !== 'popup') {
      this.setBreakpointValue('show_country_selector_inside_popup', false);
      this.setBreakpointValue('show_currency_selector_inside_popup', false);
    }
    this.setState({
      popup_open: false,
      dropdown_open: false,
    })
  }

  renderSelectorsTab = () => {
    const { t, classes, languages } = this.props;
    const { scaledMode, corner, type, show_label, use_submit_button, show_country_selector_inside_popup, show_currency_selector_inside_popup, popup_show_world_icon, popup_switcher_type, country_switcher_format, show_flags, show_name, show_custom_name, show_iso_code, round_icons, rectangle_icons, square_icons, is_transparent, is_dropup, is_dark, border_width, border_radius, arrow_size, arrow_width, arrow_filled, position, h_space, v_space, h_item_space, v_item_space, h_item_padding, v_item_padding, fontsize, customCSS, languageDetection, currency_switcher_enabled, country_switcher_enabled, recommendation_enabled, recommendation_backdrop_show, recommendation_corner, recommendation_switcher_key, advancedOptions } = this.state;

    const getAppearanceLabel = () => {
      let txt = `${show_flags ? 'Icons, ' : ''}${(show_custom_name || show_name || show_iso_code) ? 'Labels, ' : ''}${is_transparent ? 'Transparent, ' : ''}${is_dark ? 'Dark theme, ' : ''}`;
      if(txt == '') txt = 'Edit appearance';
      else txt = txt.slice(0, -2);
      return txt;
    }

    return <TabContainer padding={0}>

      <Grid container spacing={0} style={{position: 'relative', overflow: 'hidden'}}>
        <Grid item xs={12}>

          <List
            component="nav"
            //subheader={<ListSubheader component="div">Nested List Items</ListSubheader>}
            className={classes.sidebarList}
          >





{/*
            <SwitcherConfiguratorAssistant />
  */}



            <ListItem button onClick={() => this.handleToggleSidebarEntry('alignment')} className={this.state.openSections['alignment'] ? classes.activeSection : null}>
              <TranslateOutlinedIcon className={classes.sidebarIcon} /><ListItemText primary={t('switcherConfigurator.languages')} />
              {this.state.openSections['alignment'] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={this.state.openSections['alignment']} timeout="auto" unmountOnExit className={classes.sectionItem}>
              <List component="div" disablePadding dense>
                <FormControl component="fieldset" className={classes.fieldset}>
                  <FormGroup>
                    <FormLabel className={classes.fieldsetLabel}>{t('switcherConfigurator.switcherType')}</FormLabel>
                    <Select
                      value={this.state.type}
                      label={t('switcherConfigurator.switcherType')}
                      onChange={this.handleSwitcherTypeChanged}
                      input={
                        <OutlinedInput classes={{input: classes.fieldsetSelectInput}} name={t('switcherConfigurator.switcherType')} id="outlined-type-simple" labelWidth={0} margin="dense"/>
                      }
                      fullWidth
                    >
                      <MenuItem value={'custom'} dense>Custom Dropdown</MenuItem>
                      <MenuItem value={'native_select'} dense>Native Dropdown</MenuItem>
                      <MenuItem value={'popup'} dense>Popup</MenuItem>
                      <MenuItem value={'links'} dense>Links</MenuItem>
                      <MenuItem value={'links_list'} dense>Links List</MenuItem>
                      <MenuItem value={'none'} dense>None</MenuItem>
                    </Select>
                  </FormGroup>
                </FormControl>

                {this.state.type === 'custom' &&
                  <List component="div" disablePadding dense>
                    <ListItem style={{alignItems: 'start'}}>
                      <Checkbox checked={is_dropup} onChange={() => this.setBreakpointValue('is_dropup', !is_dropup)} defaultValue={is_dropup} style={{padding: '0 8px 0 0'}} /> {t('switcherConfigurator.openToTop')}
                    </ListItem> 
                  </List>         
                }

                {this.state.type === 'popup' &&
                  <List component="div" disablePadding dense>
                    <FormControl component="fieldset" className={classes.fieldset}>
                      <FormGroup>
                        <FormLabel className={classes.fieldsetLabel}>{t('switcherConfigurator.popupSwitcherType')}</FormLabel>
                        <Select
                          value={popup_switcher_type}
                          label={t('switcherConfigurator.popupSwitcherType')}
                          onChange={(event) => {
                            this.setBreakpointValue('popup_switcher_type', event.target.value)
                          }}
                          input={
                            <OutlinedInput classes={{input: classes.fieldsetSelectInput}} name={t('switcherConfigurator.popupSwitcherType')} id="outlined-type-simple" labelWidth={0} margin="dense"/>
                          }
                          fullWidth
                        >
                          <MenuItem value={'custom'} dense>Dropdown</MenuItem>
                          <MenuItem value={'links_list'} dense>Links List</MenuItem>
                        </Select>
                      </FormGroup>
                    </FormControl>
                    <ListItem style={{alignItems: 'start'}}>
                      <Checkbox checked={popup_show_world_icon} onChange={() => this.setBreakpointValue('popup_show_world_icon', !popup_show_world_icon)} defaultValue={popup_show_world_icon} style={{padding: '0 8px 0 0'}} /> {t('switcherConfigurator.popupShowWorldIcon')}
                    </ListItem> 
                  </List>         
                }

                {(this.state.type === 'custom' || this.state.type === 'native_select' || this.state.type === 'popup') &&
                  <List component="div" disablePadding dense>
                    <ListItem style={{alignItems: 'start'}}>
                      <Checkbox checked={use_submit_button} onChange={() => this.setBreakpointValue('use_submit_button', !use_submit_button)} defaultValue={use_submit_button} style={{padding: '0 8px 0 0'}} /> {t('switcherConfigurator.useSubmitButton')}
                    </ListItem> 
                  </List>
                }

                <Divider className={classes.fieldsetDivider} variant="middle" />

                <FormControl component="fieldset" className={classes.fieldset}>
                  <FormGroup>
                    <FormLabel className={classes.fieldsetLabel}>{t('switcherConfigurator.itemsOrder')} <sup>*</sup></FormLabel>
                    {this.state.languages &&
                    <SortableList 
                      data={this.state.languages.filter(x => {if(this.props.crossDomainLinksEnabled) return x.published; else return (x.published && x.iso_code && x.iso_code.indexOf('__custom__') === -1); })}
                      onChange={this.handleItemsOrderChange}
                      onUploaded={this.handleIconUploaded}
                      onRemoved={this.handleIconRemoved}
                      customIcons={this.props.customIcons}
                    />}
                    {this.props.crossDomainLinksEnabled &&
                      <div className={classes.disabledLayer}>
                        <p>Cross Domain Links are enabled. Please modify in the <span onClick={() => {this.handleChangeTab(null, 4)}}>Market Domains</span> tab.</p>
                      </div>
                    }
                  </FormGroup>
                </FormControl>
              </List>
            </Collapse>
            <Divider />



            <ListItem button onClick={() => this.handleToggleSidebarEntry('country')} className={this.state.openSections['country'] ? classes.activeSection : null}>
              <MonetizationOnOutlinedIcon className={classes.sidebarIcon} /><ListItemText primary={t('switcherConfigurator.countryCurrency')} />
              {this.state.openSections['country'] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={this.state.openSections['country']} timeout="auto" unmountOnExit className={classes.sectionItem}>
              <List component="div" disablePadding dense>
                <FormControl component="fieldset" className={classes.fieldset}>
                  <FormGroup>
                    <FormLabel className={classes.fieldsetLabel}>{t('switcherConfigurator.country')}</FormLabel>
                    <ListItem style={{alignItems: 'start', padding: '4px 0 8px 0'}}>
                      <Checkbox checked={this.state.country_switcher_enabled} onChange={() => {this.setBreakpointValue('country_switcher_enabled', !this.state.country_switcher_enabled)}} style={{padding: '0 8px 0 0'}} />
                      <Typography variant="caption">
                        {t('switcherConfigurator.showCountry')}
                      </Typography>                    
                    </ListItem>
                    <small>{t('switcherConfigurator.countryInfo')}</small>
                  </FormGroup>
                </FormControl>
                <FormControl component="fieldset" className={classes.fieldset}>
                  <FormGroup>
                    <TextField
                      className={classes.textField}
                      classes={{
                        input: classes.textFieldInput
                      }}
                      value={country_switcher_format}
                      onChange={(e) => {this.setBreakpointValue('country_switcher_format', e.target.value)}}
                      label={t('switcherConfigurator.countrySelectorFormat')}
                      placeholder={'[[country_name]] ([[country_currency_code]] [[country_currency_symbol]])'}
                      size="small"
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormGroup>
                  <small><Trans i18nKey={'switcherConfigurator.countrySelectorFormatInfo'} /></small>
                </FormControl>

                {this.state.type === 'popup' &&
                  <FormControl component="fieldset" className={classes.fieldset}>
                    <FormGroup>
                      <ListItem style={{alignItems: 'start', padding: '4px 0 8px 0'}}>
                        <Checkbox checked={show_country_selector_inside_popup} onChange={() => this.setBreakpointValue('show_country_selector_inside_popup', !show_country_selector_inside_popup)} defaultValue={show_country_selector_inside_popup} style={{padding: '0 8px 0 0'}} /> {t('switcherConfigurator.showInsidePopup')}
                      </ListItem> 
                      <small>{t('switcherConfigurator.showInsidePopupInfo')}</small>
                    </FormGroup>
                  </FormControl>         
                }

                <Divider className={classes.fieldsetDivider} variant="middle" />

                <FormControl component="fieldset" className={classes.fieldset}>
                  <FormGroup>
                    <SolutionsArticleLink 
                      url={'https://support.langify-app.com/en/support/solutions/articles/11000122982-currency-switcher'} 
                      breadcrumbs={'Switcher Configurator > Currency Switcher'}
                    />
                    <FormLabel className={classes.fieldsetLabel}>{t('switcherConfigurator.currency')}</FormLabel>
                    <ListItem style={{alignItems: 'start', padding: '4px 0 8px 0'}}>
                      <Checkbox checked={this.state.currency_switcher_enabled} onChange={() => {this.setBreakpointValue('currency_switcher_enabled', !this.state.currency_switcher_enabled)}} style={{padding: '0 8px 0 0'}} />
                      <Typography variant="caption">
                        {t('switcherConfigurator.showCurrency')}
                      </Typography>                    
                    </ListItem> 
                    <small>{t('switcherConfigurator.currencyInfo')}</small>
                  </FormGroup>
                </FormControl>

                {this.state.type === 'popup' &&
                  <FormControl component="fieldset" className={classes.fieldset}>
                    <FormGroup>
                      <ListItem style={{alignItems: 'start', padding: '4px 0 8px 0'}}>
                        <Checkbox checked={show_currency_selector_inside_popup} onChange={() => this.setBreakpointValue('show_currency_selector_inside_popup', !show_currency_selector_inside_popup)} defaultValue={show_currency_selector_inside_popup} style={{padding: '0 8px 0 0'}} /> {t('switcherConfigurator.showInsidePopup')}
                      </ListItem> 
                      <small>{t('switcherConfigurator.showInsidePopupInfo')}</small>
                    </FormGroup>
                  </FormControl>         
                }
              </List>
            </Collapse>
            <Divider />







            <ListItem button onClick={() => this.handleToggleSidebarEntry('positioning')} className={this.state.openSections['positioning'] ? classes.activeSection : null}>
              <FlipToFrontOutlinedIcon className={classes.sidebarIcon} /><ListItemText primary={t('switcherConfigurator.placement')} />
              {this.state.openSections['positioning'] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={this.state.openSections['positioning']} timeout="auto" unmountOnExit className={classes.sectionItem}>
              <React.Fragment>

                <FormControl component="fieldset" className={classes.fieldset}>
                  <FormGroup>
                    <FormLabel className={classes.fieldsetLabel}>{t('switcherConfigurator.position')}</FormLabel>
                    <Select
                      value={this.state.position}
                      onChange={(event) => {
                        this.setBreakpointValue('position', event.target.value)
                      }}
                      input={
                        <OutlinedInput classes={{input: classes.fieldsetSelectInput}} name="Switcher position" id="outlined-position-simple" labelWidth={this.labelRef3 ? this.labelRef3.offsetWidth-16 : 0} margin="dense" />
                      }
                      fullWidth
                    >
                      <MenuItem value={'fixed'} dense>Fixed</MenuItem>
                      <MenuItem value={'absolute'} dense>Absolute</MenuItem>
                      <MenuItem value={'custom'} dense>Custom</MenuItem>
                    </Select>
                  </FormGroup>
                </FormControl>  
                <ListItem style={{alignItems: 'start'}}>
                  {position === 'fixed' && <small>{t('switcherConfigurator.fixedPositionInfo')}</small>}
                  {position === 'absolute' && <small>{t('switcherConfigurator.absolutePositionInfo')}</small>}
                  {position === 'custom' && <small>{t('switcherConfigurator.customPositionInfo')}</small>}
                </ListItem>

                <Divider className={classes.fieldsetDivider} variant="middle" />

                <FormControl component="fieldset" className={classes.fieldset}>
                  <FormGroup>
                    <FormLabel className={classes.fieldsetLabel}>{t('switcherConfigurator.alignment')}</FormLabel>
                    <Select
                      value={this.state.corner}
                      onChange={(event) => {
                        this.setBreakpointValue('corner', event.target.value)
                      }}
                      input={
                        <OutlinedInput classes={{input: classes.fieldsetSelectInput}} name="Switcher alignment" id="outlined-alignment-simple" labelWidth={0} margin="dense"/>
                      }
                      disabled={(position === 'custom' && type !== 'custom')}
                      fullWidth
                    >
                      <MenuItem value={'top_left'} dense>{t('switcherConfigurator.topLeft')}</MenuItem>
                      <MenuItem value={'top_right'} dense>{t('switcherConfigurator.topRight')}</MenuItem>
                      <MenuItem value={'bottom_left'} dense>{t('switcherConfigurator.bottomLeft')}</MenuItem>
                      <MenuItem value={'bottom_right'} dense>{t('switcherConfigurator.bottomRight')}</MenuItem>
                    </Select>
                    {/*<FormHelperText>Some important helper text</FormHelperText>*/}
                  </FormGroup>
                </FormControl> 
              </React.Fragment>
            </Collapse>
            <Divider />

            <ListItem button onClick={() => this.handleToggleSidebarEntry('appearance')} className={this.state.openSections['appearance'] ? classes.activeSection : null}>
              <CompareOutlinedIcon className={classes.sidebarIcon} /><ListItemText primary={t('switcherConfigurator.appearance')} />
              {this.state.openSections['appearance'] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={this.state.openSections['appearance']} timeout="auto" unmountOnExit className={classes.sectionItem}>

              <List component="div" disablePadding dense>
                <FormLabel className={classes.formLabel}>{t('switcherConfigurator.size')}</FormLabel>
                <FormControl component="fieldset" className={classes.fieldset}>
                  <FormGroup style={{outline: 'none'}}>
                    <div className={classes.fieldsetLabel} style={{marginBottom:0}}>
                      <Typography variant="caption" component="div" className={classes.fieldsetLabel} style={{position: 'absolute'}}>{t('switcherConfigurator.fontsize')}</Typography>
                      
                      <InputAdornment component="span" position="end" style={{marginTop: 20, marginLeft: 0, position: 'absolute', right: 75, top: 0}}>px</InputAdornment>
                      <InputBase
                        type="number"
                        value={fontsize}
                        margin="none"
                        mask={[/[0-9]/]}
                        onChange={(e) => {
                          this.setBreakpointValue('fontsize', Math.round(e.target.value))
                        }}
                        className={classes.sliderInput}
                      />   
                    </div>
                    <Slider
                      classes={{ root: classes.slider }}
                      min={10}
                      max={40}
                      value={fontsize}
                      aria-labelledby="label"
                      onChange={(evt, val) => {
                        this.setBreakpointValue('fontsize', Math.round(val))
                      }}
                    />  
                  </FormGroup>
                </FormControl>

                <Divider className={classes.fieldsetDivider} variant="middle" />

                <FormControl component="fieldset" className={classes.fieldset}>
                  <FormGroup>
                    <FormLabel className={classes.fieldsetLabel}>{t('switcherConfigurator.label')}</FormLabel>
                    <Select
                      value={this.state.switcherLabel}
                      onChange={this.handleSetLabel}
                      input={
                        <OutlinedInput classes={{input: classes.fieldsetSelectInput}} name="Switcher Label" id="outlined-alignment-simple" labelWidth={0} margin="dense"/>
                      }
                      //disabled={(position === 'custom' && type !== 'custom')}
                      fullWidth
                    >
                      <MenuItem value={'show_label'} dense>{t('switcherConfigurator.noLabel')}</MenuItem>
                      <MenuItem value={'show_custom_name'} dense>{t('switcherConfigurator.languageName')}</MenuItem>
                      <MenuItem value={'show_name'} dense>{t('switcherConfigurator.shopifyName')}</MenuItem>
                      <MenuItem value={'show_iso_code'} dense>{t('switcherConfigurator.isoCode')}</MenuItem>
                    </Select>
                    {/*<FormHelperText>Some important helper text</FormHelperText>*/}
                  </FormGroup>
                </FormControl> 

                <Divider className={classes.fieldsetDivider} variant="middle" />

                <FormControl component="fieldset" className={classes.fieldset}>
                  <FormGroup>
                    <FormLabel className={classes.fieldsetLabel}>{t('switcherConfigurator.icon')}</FormLabel>
                    <Select
                      value={this.state.switcherIcons}
                      onChange={this.handleSetIcons}
                      input={
                        <OutlinedInput classes={{input: classes.fieldsetSelectInput}} name="Switcher Icons" id="outlined-alignment-simple" labelWidth={0} margin="dense"/>
                      }
                      disabled={(type === 'native_select')}
                      fullWidth
                    >
                      <MenuItem value={'no_icons'} dense>{t('switcherConfigurator.noIcons')}</MenuItem>
                      <MenuItem value={'rectangle_icons'} dense>{t('switcherConfigurator.rectangleIcons')}</MenuItem>
                      <MenuItem value={'square_icons'} dense>{t('switcherConfigurator.squareIcons')}</MenuItem>
                      <MenuItem value={'round_icons'} dense>{t('switcherConfigurator.roundIcons')}</MenuItem>
                    </Select>
                    {/*<FormHelperText>Some important helper text</FormHelperText>*/}
                  </FormGroup>
                </FormControl> 

                <Divider className={classes.fieldsetDivider} variant="middle" />
                <List component="div" disablePadding dense>
                  <FormLabel className={classes.formLabel}>{t('switcherConfigurator.transparency')}</FormLabel>
                  <ListItem style={{alignItems: 'start'}}>
                    <Checkbox checked={is_transparent} onChange={() => this.setBreakpointValue('is_transparent', !is_transparent)} defaultValue={is_transparent} style={{padding: '0 8px 0 0'}} /> {t('switcherConfigurator.transparentBackground')}
                  </ListItem> 
                </List>



                <Divider className={classes.fieldsetDivider} variant="middle" />
                <FormLabel className={classes.formLabel}>{t('switcherConfigurator.border')}</FormLabel>
                <FormControl component="fieldset" className={classes.fieldset} style={{padding: '4px 16px'}}>
                  <FormGroup style={{outline: 'none'}}>
                    <Typography variant="caption" component="div" className={classes.fieldsetLabel} style={{marginBottom:0}}>
                      {t('switcherConfigurator.borderWidth')}
                      <InputAdornment component="span" position="end" style={{marginTop: 12, marginLeft: 0, float: 'right'}}>{border_width}px</InputAdornment>
                    </Typography>
                    <Slider
                      classes={{ root: classes.slider }}
                      min={0}
                      max={5}
                      value={border_width}
                      aria-labelledby="label"
                      onChange={(evt, val) => {
                        this.setBreakpointValue('border_width', Math.round(val))
                      }}
                    />  
                  </FormGroup>
                </FormControl>
                <FormControl component="fieldset" className={classes.fieldset} style={{padding: '4px 16px'}}>
                  <FormGroup style={{outline: 'none'}}>
                    <Typography variant="caption" component="div" className={classes.fieldsetLabel} style={{marginBottom:0}}>
                      {t('switcherConfigurator.borderRadius')}
                      <InputAdornment component="span" position="end" style={{marginTop: 12, marginLeft: 0, float: 'right'}}>{border_radius}px</InputAdornment>
                    </Typography>
                    <Slider
                      classes={{ root: classes.slider }}
                      min={0}
                      max={10}
                      value={border_radius}
                      aria-labelledby="label"
                      onChange={(evt, val) => {
                        this.setBreakpointValue('border_radius', Math.round(val))
                      }}
                    />  
                  </FormGroup>
                </FormControl>

                
                {this.state.type === 'custom' &&
                  <React.Fragment>

                    <Divider className={classes.fieldsetDivider} />
                    <FormLabel className={classes.formLabel}>{t('switcherConfigurator.arrow')}</FormLabel>

                    <FormControl component="fieldset" className={classes.fieldset} style={{padding: '4px 16px'}}>
                      <FormGroup style={{outline: 'none'}}>
                        <Typography variant="caption" component="div" className={classes.fieldsetLabel} style={{marginBottom:0}}>
                          {t('switcherConfigurator.size')}
                          <InputAdornment component="span" position="end" style={{marginTop: 12, marginLeft: 0, float: 'right'}}>{arrow_size}%</InputAdornment>
                        </Typography>
                        <Slider
                          classes={{ root: classes.slider }}
                          min={0}
                          max={100}
                          value={arrow_size}
                          aria-labelledby="label"
                          onChange={(evt, val) => {
                            this.setBreakpointValue('arrow_size', Math.round(val))
                          }}
                        />  
                      </FormGroup>
                    </FormControl>
                    <FormControl component="fieldset" className={classes.fieldset} style={{padding: '4px 16px'}}>
                      <FormGroup style={{outline: 'none'}}>
                        <Typography variant="caption" component="div" className={classes.fieldsetLabel} style={{marginBottom:0}}>
                          {t('switcherConfigurator.strokeWidth')}
                          <InputAdornment component="span" position="end" style={{marginTop: 16, marginLeft: 0, position: 'absolute', right: 75, top: 0}}>px</InputAdornment>
                          <InputBase
                            type="number"
                            value={arrow_width}
                            mask={[/[0-9]/]}
                            onChange={(e) => {
                              this.setBreakpointValue('arrow_width', Math.round(e.target.value))
                            }}
                            className={classes.sliderInput}
                            disabled={arrow_filled}
                          />   
                        </Typography>
                        <Slider
                          classes={{ root: classes.slider }}
                          min={1}
                          max={3}
                          value={arrow_width}
                          aria-labelledby="label"
                          onChange={(evt, val) => {
                            this.setBreakpointValue('arrow_width', Math.round(val))
                          }}
                          disabled={arrow_filled}
                        />  
                      </FormGroup>
                    </FormControl>

                    <ListItem style={{paddingTop: 8}}>
                      <Checkbox checked={arrow_filled} onChange={() => this.setBreakpointValue('arrow_filled', !arrow_filled)} defaultValue={arrow_filled} style={{padding: '0 8px 0 0'}} /> {t('switcherConfigurator.fillArrow')}
                    </ListItem> 
                  </React.Fragment>
                }
              </List>
            </Collapse>
            <Divider />




            <ListItem button onClick={() => this.handleToggleSidebarEntry('spacings')} className={this.state.openSections['spacings'] ? classes.activeSection : null}>
              <FormatLineSpacingOutlinedIcon className={classes.sidebarIcon} /><ListItemText primary={t('switcherConfigurator.spacings')} />
              {this.state.openSections['spacings'] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={this.state.openSections['spacings']} timeout="auto" unmountOnExit className={classes.sectionItem}>
              <List component="div" disablePadding dense>
                {(position !== 'custom') &&
                  <React.Fragment>
                    <FormLabel className={classes.formLabel}>{t('switcherConfigurator.switcherSpacing')}</FormLabel>
                    <FormControl component="fieldset" className={classes.fieldset} style={{padding: '4px 16px'}}>
                      <FormGroup style={{outline: 'none'}}>
                        <Typography variant="caption" component="div" className={classes.fieldsetLabel} style={{marginBottom:0}}>
                          {t('switcherConfigurator.horizontal')}
                          <InputAdornment component="span" position="end" style={{marginTop: 16, marginLeft: 0, position: 'absolute', right: 75, top: 0}}>px</InputAdornment>
                          <InputBase
                            type="number"
                            value={h_space}
                            mask={[/[0-9]/]}
                            onChange={(e) => {
                              this.setBreakpointValue('h_space', Math.round(e.target.value))
                            }}
                            className={classes.sliderInput}
                          />   
                        </Typography>
                        <Slider
                          classes={{ root: classes.slider }}
                          min={0}
                          max={200}
                          value={h_space}
                          aria-labelledby="label"
                          onChange={(evt, val) => {
                            this.setBreakpointValue('h_space', Math.round(val))
                          }}
                        />  
                      </FormGroup>
                    </FormControl>     

                    <FormControl component="fieldset" className={classes.fieldset} style={{padding: '4px 16px'}}>
                      <FormGroup style={{outline: 'none'}}>
                        <Typography variant="caption" component="div" className={classes.fieldsetLabel} style={{marginBottom:0}}>
                          {t('switcherConfigurator.vertical')}
                          <InputAdornment component="span" position="end" style={{marginTop: 16, marginLeft: 0, position: 'absolute', right: 75, top: 0}}>px</InputAdornment>
                          <InputBase
                            type="number"
                            value={v_space}
                            mask={[/[0-9]/]}
                            onChange={(e) => {
                              this.setBreakpointValue('v_space', Math.round(e.target.value))
                            }}
                            className={classes.sliderInput}
                          /> 
                        </Typography>
                        <Slider
                          classes={{ root: classes.slider }}
                          min={0}
                          max={200}
                          value={v_space}
                          aria-labelledby="label"
                          onChange={(evt, val) => {
                            this.setBreakpointValue('v_space', Math.round(val))
                          }}
                        />     
                      </FormGroup>
                    </FormControl>  
                  </React.Fragment>
                }
          
                <React.Fragment>
                  <Divider className={classes.fieldsetDivider} variant="middle" />
                  <FormLabel className={classes.formLabel}>{t('switcherConfigurator.itemSpacing')}</FormLabel>
                  <FormControl component="fieldset" className={classes.fieldset} style={{padding: '4px 16px'}}>
                    <FormGroup style={{outline: 'none'}}>
                      <Typography variant="caption" component="div" className={classes.fieldsetLabel} style={{marginBottom:0}}>
                        {t('switcherConfigurator.horizontal')}
                        <InputAdornment component="span" position="end" style={{marginTop: 16, marginLeft: 0, position: 'absolute', right: 75, top: 0}}>px</InputAdornment>
                        <InputBase
                          type="number"
                          value={h_item_space}
                          mask={[/[0-9]/]}
                          onChange={(e) => {
                            this.setBreakpointValue('h_item_space', Math.round(e.target.value))
                          }}
                          className={classes.sliderInput}
                        />   
                      </Typography>
                      <Slider
                        classes={{ root: classes.slider }}
                        min={0}
                        max={40}
                        value={h_item_space}
                        aria-labelledby="label"
                        onChange={(evt, val) => {
                          this.setBreakpointValue('h_item_space', Math.round(val))
                        }}
                      />  
                    </FormGroup>
                  </FormControl>     
                  <FormControl component="fieldset" className={classes.fieldset} style={{padding: '4px 16px'}}>
                    <FormGroup style={{outline: 'none'}}>
                      <Typography variant="caption" component="div" className={classes.fieldsetLabel} style={{marginBottom:0}}>
                        {t('switcherConfigurator.vertical')}
                        <InputAdornment component="span" position="end" style={{marginTop: 16, marginLeft: 0, position: 'absolute', right: 75, top: 0}}>px</InputAdornment>
                        <InputBase
                          type="number"
                          value={v_item_space}
                          mask={[/[0-9]/]}
                          onChange={(e) => {
                            this.setBreakpointValue('v_item_space', Math.round(e.target.value))
                          }}
                          className={classes.sliderInput}
                        /> 
                      </Typography>
                      <Slider
                        classes={{ root: classes.slider }}
                        min={0}
                        max={40}
                        value={v_item_space}
                        aria-labelledby="label"
                        onChange={(evt, val) => {
                          this.setBreakpointValue('v_item_space', Math.round(val))
                        }}
                      />     
                    </FormGroup>
                  </FormControl>  
                </React.Fragment>
          
                <React.Fragment>
                  <Divider className={classes.fieldsetDivider} variant="middle" />
                  <FormLabel className={classes.formLabel}>{t('switcherConfigurator.itemPadding')}</FormLabel>
                  <FormControl component="fieldset" className={classes.fieldset} style={{padding: '4px 16px'}}>
                    <FormGroup style={{outline: 'none'}}>
                      <Typography variant="caption" component="div" className={classes.fieldsetLabel} style={{marginBottom:0}}>
                        {t('switcherConfigurator.horizontal')}
                        <InputAdornment component="span" position="end" style={{marginTop: 16, marginLeft: 0, position: 'absolute', right: 75, top: 0}}>px</InputAdornment>
                        <InputBase
                          type="number"
                          value={h_item_padding}
                          mask={[/[0-9]/]}
                          onChange={(e) => {
                            this.setBreakpointValue('h_item_padding', Math.round(e.target.value))
                          }}
                          className={classes.sliderInput}
                        />   
                      </Typography>
                      <Slider
                        classes={{ root: classes.slider }}
                        min={0}
                        max={40}
                        value={h_item_padding}
                        aria-labelledby="label"
                        onChange={(evt, val) => {
                          this.setBreakpointValue('h_item_padding', Math.round(val))
                        }}
                      />  
                    </FormGroup>
                  </FormControl>     
                  <FormControl component="fieldset" className={classes.fieldset} style={{padding: '4px 16px'}}>
                    <FormGroup style={{outline: 'none'}}>
                      <Typography variant="caption" component="div" className={classes.fieldsetLabel} style={{marginBottom:0}}>
                        {t('switcherConfigurator.vertical')}
                        <InputAdornment component="span" position="end" style={{marginTop: 16, marginLeft: 0, position: 'absolute', right: 75, top: 0}}>px</InputAdornment>
                        <InputBase
                          type="number"
                          value={v_item_padding}
                          mask={[/[0-9]/]}
                          onChange={(e) => {
                            this.setBreakpointValue('v_item_padding', Math.round(e.target.value))
                          }}
                          className={classes.sliderInput}
                        /> 
                      </Typography>
                      <Slider
                        classes={{ root: classes.slider }}
                        min={0}
                        max={40}
                        value={v_item_padding}
                        aria-labelledby="label"
                        onChange={(evt, val) => {
                          this.setBreakpointValue('v_item_padding', Math.round(val))
                        }}
                      />     
                    </FormGroup>
                  </FormControl>  
                </React.Fragment>
                  
              </List>
            </Collapse>
            <Divider />




            <ListItem button onClick={() => this.handleToggleSidebarEntry('type')} className={this.state.openSections['type'] ? classes.activeSection : null}>
              <ColorLensOutlinedIcon className={classes.sidebarIcon} /><ListItemText primary={t('switcherConfigurator.colors')} />
              {this.state.openSections['type'] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={this.state.openSections['type']} timeout="auto" unmountOnExit className={classes.sectionItem}>
              <React.Fragment>
                <FormControl component="fieldset" className={classes.fieldset}>
                  <FormGroup>
                    <ColorPicker
                      name="bg_color"
                      defaultValue={this.state.bg_color}
                      value={this.state.bg_color}
                      onChange={color => this.setBreakpointValue('bg_color', color)}
                      label={t('switcherConfigurator.background')}
                    />  
                  </FormGroup>
                </FormControl>
                <FormControl component="fieldset" className={classes.fieldset}>
                  <FormGroup>
                    <ColorPicker
                      name="text_color"
                      defaultValue={this.state.text_color}
                      value={this.state.text_color}
                      onChange={color => this.setBreakpointValue('text_color', color)}
                      label={t('switcherConfigurator.text')}
                      style={{display: 'inline-block'}}
                    />     
                  </FormGroup>
                </FormControl>
                <FormControl component="fieldset" className={classes.fieldset}>
                  <FormGroup>
                    <ColorPicker
                      name="links_color"
                      defaultValue={this.state.links_color}
                      value={this.state.links_color}
                      onChange={color => this.setBreakpointValue('links_color', color)}
                      label={t('switcherConfigurator.link')}
                    />  
                  </FormGroup>
                </FormControl>
                <FormControl component="fieldset" className={classes.fieldset}>
                  <FormGroup>
                    <ColorPicker
                      name="border_color"
                      defaultValue={this.state.border_color}
                      value={this.state.border_color}
                      onChange={color => this.setBreakpointValue('border_color', color)}
                      label={t('switcherConfigurator.border')}
                    />  
                  </FormGroup>
                </FormControl>


                {this.state.use_submit_button && (this.state.type === 'custom' || this.state.type === "native_select") &&
                  <>
                    <FormControl component="fieldset" className={classes.fieldset}>
                      <FormGroup>
                        <ColorPicker
                          name="button_bg_color"
                          defaultValue={this.state.button_bg_color}
                          value={this.state.button_bg_color}
                          onChange={color => this.setBreakpointValue('button_bg_color', color)}
                          label={t('switcherConfigurator.buttonBackground')}
                        />  
                      </FormGroup>
                    </FormControl>
                    <FormControl component="fieldset" className={classes.fieldset}>
                      <FormGroup>
                        <ColorPicker
                          name="button_text_color"
                          defaultValue={this.state.button_text_color}
                          value={this.state.button_text_color}
                          onChange={color => this.setBreakpointValue('button_text_color', color)}
                          label={t('switcherConfigurator.buttonText')}
                        />  
                      </FormGroup>
                    </FormControl>                
                  </>
                }
              </React.Fragment>
            </Collapse>
            <Divider />
            
            <small style={{display: 'block', padding: 16}}>
              {t('switcherConfigurator.globalInfo')}
            </small>
            
          </List>

          {this.renderPreviewFrame()}
          {!this.state.previewImageLoaded &&
            <span className={classes.screenshotInfo} style={{ marginTop: position === 'custom' ? -125 : 0, marginLeft: 40}}>
              generating preview...
            </span>
          }  
          {position === 'custom' && 
            <div className={classes.customWrapper}>
              {this.renderSwitcherWrapper(null)}
              <div>
                <CopyToClipboard text={`<span class="ly-custom-${this.state.currentBreakpoint}"></span>`} onCopy={() => {this.setState({copied: true}); setTimeout(() => { this.setState({copied: false}); }, 3000)}}>
                  <Paper style={{ position: 'relative', display: 'inline-block', cursor: 'pointer', padding: '8px 16px', marginTop: 24}}>
                    <Typography variant="caption">Please copy the following code snippet and paste it inside the template:</Typography>
                    <Divider style={{margin: '8px -16px'}} />
                    <Typography align="center">{`<span class="ly-custom-${this.state.currentBreakpoint}"></span>`}</Typography>
                    <Fade in={ this.state.copied }>
                      <span style={{position: 'absolute', borderRadius: 6, top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(255,255,255,.75)', alignItems: 'center', display: 'flex', justifyContent: 'center', fontSize: 16, fontWeight: 'bold'}}>copied to clipboard</span>
                    </Fade>
                  </Paper>
                </CopyToClipboard>
              </div>
            </div>
          }
        </Grid>
      </Grid>
    </TabContainer>
  }

  renderRecommendationTab = () => {
    const { t, classes, languages } = this.props;
    const { scaledMode, advancedOptions, corner, type, show_label, show_flags, show_name, show_custom_name, show_iso_code, round_icons, rectangle_icons, square_icons, is_transparent, is_dark, border_width, border_radius, arrow_size, arrow_width, arrow_filled, position, h_space, v_space, fontsize, customCSS, languageDetection, currency_switcher_enabled, recommendation_enabled, recommendation_backdrop_show, recommendation_corner, recommendation_banner_corner, recommendation_switcher_key, recommendation_type, recommendation_fontsize, recommendation_border_width, recommendation_border_radius } = this.state;

    const getAppearanceLabel = () => {
      let txt = `${show_flags ? 'Icons, ' : ''}${(show_custom_name || show_name || show_iso_code) ? 'Labels, ' : ''}${is_transparent ? 'Transparent, ' : ''}${is_dark ? 'Dark theme, ' : ''}`;
      if(txt == '') txt = 'Edit appearance';
      else txt = txt.slice(0, -2);
      return txt;
    }

    return <TabContainer padding={0}>

      {/*
      <Paper className={classes.infoBox} style={{}} elevation={0}>
        <Typography variant="h6" className={classes.infoText} style={{ margin: '2px 0', fontSize: 16}}>
          Configure and position the switcher here. <a href="http://support.langify-app.com/support/solutions/articles/11000084362-switcher-configurator" target="blank" className={classes.infoText} style={{fontWeight: 'bold', textDecoration: 'none'}}>Read more...</a>
        </Typography>
      </Paper>
      */}

      <Grid container spacing={0} style={{position: 'relative', overflow: 'hidden'}}>
        <Grid item xs={12}>

          <List
            component="nav"
            //subheader={<ListSubheader component="div">Nested List Items</ListSubheader>}
            className={classes.sidebarList}
          >
            <ListItem button onClick={() => this.handleToggleSidebarEntry('alignment')} className={this.state.openSections['alignment'] ? classes.activeSection : null}>
              <ListItemText primary={t('switcherConfigurator.general')} />
              {this.state.openSections['alignment'] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={this.state.openSections['alignment']} timeout="auto" unmountOnExit className={classes.sectionItem}>
              <List component="div" disablePadding dense>
                <FormLabel className={classes.formLabel}>{t('switcherConfigurator.general')} <sup>*</sup></FormLabel>

                <ListItem style={{alignItems: 'start'}}>
                  <Checkbox checked={recommendation_enabled} onChange={() => this.setGlobalValue('recommendation_enabled', !recommendation_enabled)} defaultValue={recommendation_enabled} style={{padding: '0 8px 0 0'}} /> 
                  <Typography variant="caption">{t('switcherConfigurator.enableRecommendation')}</Typography>
                </ListItem> 

                <Divider className={classes.fieldsetDivider} />
                
                <FormControl component="fieldset" className={classes.fieldset}>
                  <FormGroup>
                    <FormLabel className={classes.fieldsetLabel}>{t('switcherConfigurator.recommendationType')} <sup>*</sup></FormLabel>
                    <Select
                      value={recommendation_type}
                      label={t('switcherConfigurator.general')}
                      onChange={(event) => {
                        this.setGlobalValue('recommendation_type', event.target.value)
                      }}
                      input={
                        <OutlinedInput classes={{input: classes.fieldsetSelectInput}} name={t('switcherConfigurator.recommendationType')} id="outlined-type-simple" labelWidth={0} />
                      }
                      fullWidth
                    >
                      <MenuItem value={'popup'} dense>Popup</MenuItem>
                      <MenuItem value={'banner'} dense>Banner</MenuItem>
                    </Select>
                  </FormGroup>
                </FormControl>  

                <Divider className={classes.fieldsetDivider} />
                <FormLabel className={classes.formLabel}>{t('switcherConfigurator.selectorConfiguration')} <sup>*</sup></FormLabel>
                <ListItem style={{alignItems: 'start'}}>
                  <Select
                    value={recommendation_switcher_key}
                    label={t('switcherConfigurator.selectorConfiguration')}
                    onChange={(event) => {
                      this.setGlobalValue('recommendation_switcher_key', event.target.value)
                    }}
                    input={
                      <OutlinedInput classes={{input: classes.fieldsetSelectInput}} name={t('switcherConfigurator.selectorConfiguration')} id="outlined-type-simple" labelWidth={0} />
                    }
                    fullWidth
                  >
                    <MenuItem value={-1} dense>No selectors</MenuItem>
                    {this.state.breakpoints.map(breakpoint => {
                      if(breakpoint.config.type === 'links_list') return false;
                      return <MenuItem key={`bp-${breakpoint.key}`} value={breakpoint.key} dense>{`${breakpoint.config.type.charAt(0).toUpperCase() + breakpoint.config.type.slice(1)}${breakpoint.config.currency_switcher_enabled == true ? ' + Currency' : ''} (${breakpoint.label})`}</MenuItem>
                    })}
                  </Select>
                </ListItem>
                <ListItem style={{alignItems: 'start'}}>
                  <small>{t('switcherConfigurator.selectorConfigurationInfo')}</small>
                </ListItem>

                <Divider className={classes.fieldsetDivider} />
                <FormControl component="fieldset" className={classes.fieldset}>
                  <FormGroup>
                    <FormLabel className={classes.fieldsetLabel}>{t('switcherConfigurator.orientation')}<sup>*</sup></FormLabel>
                    {recommendation_type === 'popup' &&
                      <Select
                        value={recommendation_corner}
                        label={t('switcherConfigurator.orientation')}
                        onChange={(event) => { this.setGlobalValue('recommendation_corner', event.target.value); }}
                        input={
                          <OutlinedInput classes={{input: classes.fieldsetSelectInput}} name={t('switcherConfigurator.orientation')} id="outlined-type-simple" labelWidth={0} />
                        }
                        fullWidth
                      >
                        <MenuItem value={'top_left'} dense>{t('switcherConfigurator.topLeft')}</MenuItem>
                        <MenuItem value={'top_center'} dense>{t('switcherConfigurator.topCenter')}</MenuItem>
                        <MenuItem value={'top_right'} dense>{t('switcherConfigurator.topRight')}</MenuItem>
                        <MenuItem value={'center_center'} dense>{t('switcherConfigurator.centerCenter')}</MenuItem>
                        <MenuItem value={'bottom_left'} dense>{t('switcherConfigurator.bottomLeft')}</MenuItem>
                        <MenuItem value={'bottom_center'} dense>{t('switcherConfigurator.bottomCenter')}</MenuItem>
                        <MenuItem value={'bottom_right'} dense>{t('switcherConfigurator.bottomRight')}</MenuItem>
                      </Select>
                    }
                    {recommendation_type === 'banner' &&
                      <Select
                        value={recommendation_banner_corner}
                        label={t('switcherConfigurator.orientation')}
                        onChange={(event) => { this.setBreakpointValue('recommendation_banner_corner', event.target.value); }}
                        input={
                          <OutlinedInput classes={{input: classes.fieldsetSelectInput}} name={t('switcherConfigurator.orientation')} id="outlined-type-simple" labelWidth={0} />
                        }
                        fullWidth
                      >
                        <MenuItem value={'top'} dense>{t('switcherConfigurator.top')}</MenuItem>
                        <MenuItem value={'bottom'} dense>{t('switcherConfigurator.bottom')}</MenuItem>
                      </Select>
                    }
                  </FormGroup>
                </FormControl>
              </List>
            </Collapse>
            <Divider />

            <ListItem button onClick={() => this.handleToggleSidebarEntry('appearance')} className={this.state.openSections['appearance'] ? classes.activeSection : null}>
              <ListItemText primary={t('switcherConfigurator.appearance')} />
              {this.state.openSections['appearance'] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={this.state.openSections['appearance']} timeout="auto" unmountOnExit className={classes.sectionItem}>
              <FormLabel className={classes.formLabel}>{t('switcherConfigurator.size')}</FormLabel>
              <FormControl component="fieldset" className={classes.fieldset}>
                <FormGroup style={{outline: 'none'}}>
                  <div className={classes.fieldsetLabel} style={{marginBottom:0}}>
                    <Typography variant="caption" component="div" className={classes.fieldsetLabel} style={{position: 'absolute'}}>{t('switcherConfigurator.fontsize')} </Typography>
                    
                    <InputAdornment component="span" position="end" style={{marginTop: 20, marginLeft: 0, position: 'absolute', right: 75, top: 0}}>px</InputAdornment>
                    <InputBase
                      type="number"
                      value={recommendation_fontsize}
                      margin="none"
                      mask={[/[0-9]/]}
                      onChange={(e) => {
                        this.setBreakpointValue('recommendation_fontsize', Math.round(e.target.value))
                      }}
                      className={classes.sliderInput}
                    />   
                  </div>
                  <Slider
                    classes={{ root: classes.slider }}
                    min={10}
                    max={40}
                    value={recommendation_fontsize}
                    aria-labelledby="label"
                    onChange={(evt, val) => {
                      this.setBreakpointValue('recommendation_fontsize', Math.round(val))
                    }}
                  />  
                </FormGroup>
              </FormControl>


              {recommendation_type === 'popup' &&
                <React.Fragment>
                  <Divider className={classes.fieldsetDivider} variant="middle" />
                  <List component="div" disablePadding dense>
                    <FormLabel className={classes.formLabel}>Backdrop<sup>*</sup></FormLabel>
                    <ListItem style={{alignItems: 'start'}}>
                      <Checkbox checked={recommendation_backdrop_show} onChange={() => this.setGlobalValue('recommendation_backdrop_show', !recommendation_backdrop_show)} defaultValue={recommendation_backdrop_show} style={{padding: '0 8px 0 0'}} /> 
                      <Typography variant="caption">Show backdrop layer</Typography>
                    </ListItem>
                  </List>                  
                </React.Fragment>
              }

              <Divider className={classes.fieldsetDivider} variant="middle" />
              <FormLabel className={classes.formLabel}>{t('switcherConfigurator.border')}</FormLabel>
              <FormControl component="fieldset" className={classes.fieldset} style={{padding: '4px 16px'}}>
                <FormGroup style={{outline: 'none'}}>
                  <Typography variant="caption" component="div" className={classes.fieldsetLabel} style={{marginBottom:0}}>
                    {t('switcherConfigurator.borderWidth')}
                    <InputAdornment component="span" position="end" style={{marginTop: 12, marginLeft: 0, float: 'right'}}>{recommendation_border_width}px</InputAdornment>
                  </Typography>
                  <Slider
                    classes={{ root: classes.slider }}
                    min={0}
                    max={5}
                    value={recommendation_border_width}
                    aria-labelledby="label"
                    onChange={(evt, val) => {
                      this.setBreakpointValue('recommendation_border_width', Math.round(val))
                    }}
                  />  
                </FormGroup>
              </FormControl>
              <FormControl component="fieldset" className={classes.fieldset} style={{padding: '4px 16px'}}>
                <FormGroup style={{outline: 'none'}}>
                  <Typography variant="caption" component="div" className={classes.fieldsetLabel} style={{marginBottom:0}}>
                    {t('switcherConfigurator.borderRadius')}
                    <InputAdornment component="span" position="end" style={{marginTop: 12, marginLeft: 0, float: 'right'}}>{recommendation_border_radius}px</InputAdornment>
                  </Typography>
                  <Slider
                    classes={{ root: classes.slider }}
                    min={0}
                    max={10}
                    value={recommendation_border_radius}
                    aria-labelledby="label"
                    onChange={(evt, val) => {
                      this.setBreakpointValue('recommendation_border_radius', Math.round(val))
                    }}
                  />  
                </FormGroup>
              </FormControl>

            </Collapse>
            <Divider />


            <ListItem button onClick={() => this.handleToggleSidebarEntry('text')} className={this.state.openSections['text'] ? classes.activeSection : null}>
              <ListItemText primary={t('switcherConfigurator.texts')} />
              {this.state.openSections['text'] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={this.state.openSections['text']} timeout="auto" unmountOnExit className={classes.sectionItem} style={{padding: '0 0 10px 0', margin: 0}}>
              <TextObjectTranslator 
                languages={this.props.languages}
                fields={['recommendation', 'button']}
                data={this.state.recommendation_strings}
                onChange={this.handleRecommendationStringsChange}
              />
            </Collapse>
            <Divider />


            <ListItem button onClick={() => this.handleToggleSidebarEntry('type')} className={this.state.openSections['type'] ? classes.activeSection : null}>
              <ListItemText primary={t('switcherConfigurator.colors')} />
              {this.state.openSections['type'] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={this.state.openSections['type']} timeout="auto" unmountOnExit className={classes.sectionItem}>
              <React.Fragment>
                <FormControl component="fieldset" className={classes.fieldset}>
                  <FormGroup>
                    <ColorPicker
                      name="recommendation_bg_color"
                      defaultValue={this.state.recommendation_bg_color}
                      value={this.state.recommendation_bg_color}
                      onChange={color => this.setBreakpointValue('recommendation_bg_color', color)}
                      label={t('switcherConfigurator.background')}
                    />  
                  </FormGroup>
                </FormControl>
                <FormControl component="fieldset" className={classes.fieldset}>
                  <FormGroup>
                    <ColorPicker
                      name="recommendation_text_color"
                      defaultValue={this.state.recommendation_text_color}
                      value={this.state.recommendation_text_color}
                      onChange={color => this.setBreakpointValue('recommendation_text_color', color)}
                      label={t('switcherConfigurator.text')}
                      style={{display: 'inline-block'}}
                    />     
                  </FormGroup>
                </FormControl>
                {/*
                <FormControl component="fieldset" className={classes.fieldset}>
                  <FormGroup>
                    <ColorPicker
                      name="recommendation_link_color"
                      defaultValue={this.state.recommendation_link_color}
                      value={this.state.recommendation_link_color}
                      onChange={color => this.setBreakpointValue('recommendation_link_color', color)}
                      label={'Link'}
                    />  
                  </FormGroup>
                </FormControl>
                */}
                <FormControl component="fieldset" className={classes.fieldset}>
                  <FormGroup>
                    <ColorPicker
                      name="recommendation_button_bg_color"
                      defaultValue={this.state.recommendation_button_bg_color}
                      value={this.state.recommendation_button_bg_color}
                      onChange={color => this.setBreakpointValue('recommendation_button_bg_color', color)}
                      label={t('switcherConfigurator.buttonBackground')}
                    />  
                  </FormGroup>
                </FormControl>
                <FormControl component="fieldset" className={classes.fieldset}>
                  <FormGroup>
                    <ColorPicker
                      name="recommendation_button_text_color"
                      defaultValue={this.state.recommendation_button_text_color}
                      value={this.state.recommendation_button_text_color}
                      onChange={color => this.setBreakpointValue('recommendation_button_text_color', color)}
                      label={t('switcherConfigurator.buttonText')}
                    />  
                  </FormGroup>
                </FormControl>
                <FormControl component="fieldset" className={classes.fieldset}>
                  <FormGroup>
                    <ColorPicker
                      name="recommendation_border_color"
                      defaultValue={this.state.recommendation_border_color}
                      value={this.state.recommendation_border_color}
                      onChange={color => this.setBreakpointValue('recommendation_border_color', color)}
                      label={t('switcherConfigurator.border')}
                    />  
                  </FormGroup>
                </FormControl>
              </React.Fragment>
            </Collapse>
            <Divider />
            
            <small style={{display: 'block', padding: 16}}>
              {t('switcherConfigurator.globalInfo')}
            </small>
          </List>

          {this.renderPreviewFrame()}
          {!this.state.previewImageLoaded &&
            <span className={classes.screenshotInfo} style={{ marginTop: position === 'custom' ? -125 : 0, marginLeft: 40}}>
              generating preview...
            </span>
          }
        </Grid>
      </Grid>
    </TabContainer>
  }

  renderBehaviorTab = () => {
    const { t, classes, languages } = this.props;
    const { enableDefaultLanguage, languageDetectionDefault, languageDetection } = this.state;

    return <TabContainer>
      {this.state.recommendation_enabled &&
        <Alert severity="warning" style={{margin: '-24px -24px 16px'}}>{t('switcherConfigurator.languageDetectionAlert')}</Alert>
      }
      <Grid container spacing={4}>
        <Grid item xs={12} style={{paddingBottom: 0}}>
          <Typography variant="h6" component="h3">{t('switcherConfigurator.automaticLanguageDetection')}</Typography>
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">
              {t('switcherConfigurator.languageDetection')}
              <FormControlLabel
                className={classes.switchContainer}
                control={
                  <Switch disabled={this.state.recommendation_enabled} checked={languageDetection} onChange={() => this.setGlobalValue('languageDetection', !languageDetection)} defaultValue={languageDetection} />
                }
                label={<Typography variant="caption">{languageDetection ? t('system.enabled') : t('system.disabled')}</Typography>}
                labelPlacement="start"
              />
            </FormLabel>
            <FormGroup>
              <Divider style={{marginTop: 16, marginBottom: 0}} />
              <Typography variant="body2" style={{marginTop: 16}}>
                {t('switcherConfigurator.languageDetectionInfo1')}            
              </Typography>
              <ul style={{padding: '0 16px', margin: '12px 0 0 0'}}>
                <Trans i18nKey={'switcherConfigurator.languageDetectionInfo2'}>
                  <li></li>
                  <li></li>
                </Trans>
              </ul>
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">{t('switcherConfigurator.defaultLanguage')}</FormLabel>
            <FormGroup>
              <Divider style={{marginTop: 16, marginBottom: 0}} />
              <Typography variant="body2" style={{marginTop: 16, marginBottom: 16}}>
                {t('switcherConfigurator.defaultLanguageInfo')}
              </Typography> 
              <Select
                value={languageDetectionDefault}
                disabled={!languageDetection}
                onChange={(event) => this.setGlobalValue('languageDetectionDefault', event.target.value)}
                style={{maxWidth: 225}}
                labelWidth={0}
                input={
                  <OutlinedInput
                    labelWidth={0}
                    id="outlined-defaultlang-simple"
                  />
                }
              >
                {languages.map((lang) => {
                  return <MenuItem key={`default-lang-${lang.code}`} value={lang.code}>{lang.name}</MenuItem>
                })}
              </Select>
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
    </TabContainer>
  }


  onChangeCrossDomain = (data) => {
    this.setState({
      marketLanguages: data,
      settingsChanged: true,
    });
  }
  renderDomainsTab = () => {
    const { classes } = this.props;
    const { languageDetectionDefault, languageDetection, languages } = this.state;

    return <TabContainer rootClass={classes.domainsWrapper}>
      <DomainLocationMapper
        crossDomainLinksEnabled={this.props.crossDomainLinksEnabled}
        languages={this.state.marketLanguages}
        onEnableCrossDomainLinks={this.props.onEnableCrossDomainLinks}
        onDisableCrossDomainLinks={this.props.onDisableCrossDomainLinks}
        onChange={this.onChangeCrossDomain}
        rawLanguages={this.props.languages}
        onUploaded={this.handleIconUploaded}
        onRemoved={this.handleIconRemoved}
        customIcons={this.props.customIcons}
      />
      <LoadingIndicator
        pending={this.props.enableCrossDomainLinksPending || this.props.disableCrossDomainLinksPending}
        withLogo
      />
    </TabContainer>
  }

  renderCustomCssTab = () => {
    const { t, classes } = this.props;
    const { customCSS } = this.state;

    return <TabContainer>
      <Grid container spacing={6}>
        <Grid item xs={12} style={{paddingBottom: 0}}>
          <Paper elevation={0} style={{ overflow: 'hidden' }}>   
            <Resizable
              defaultSize={{
                width: '100%',
                height: 400
              }}
            >
              <CodeMirror
                className={classes.codemirror}
                value={customCSS}
                options={{
                  mode: 'css',
                  theme: 'material',
                  lineNumbers: true,
                  tabSize: 2,
                  
                }}
                onBeforeChange={(editor, data, value) => {
                  this.setGlobalValue('customCSS', value)
                }}
                onChange={(editor, data, value) => {
                }}
              />   
            </Resizable>        
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.infoBox} elevation={0}>
            <Typography variant="body2" className={classes.infoText} style={{ margin: '8px 0' }}>
              {t('switcherConfigurator.customCssInfo')}
            </Typography>
          </Paper>
        </Grid>
      </Grid>                
    </TabContainer>
  }

  handleZoomChange = (e) => {
    //console.log(e);

  }

  renderSwitcherWrapper = (recommendation) => {
    const { classes, languages } = this.props;
    const { breakpoints, corner, popup_show_world_icon, type, text, position, v_space, h_space, fontsize, text_color, recommendation_type, recommendation_banner_corner, recommendation_corner, recommendation_button_bg_color, recommendation_button_text_color, recommendation_fontsize, recommendation_switcher_key } = this.state;

    let usedCorner = position !== 'custom' ? corner : '';
    let switcherState = this.state;
    if(recommendation === true && this.state.recommendation_enabled && languages.length > 1 && recommendation_switcher_key >= 0) {
      let currentBreakpoint = breakpoints.find(x => x.key === recommendation_switcher_key) ? breakpoints.find(x => x.key === recommendation_switcher_key).config : [];
      switcherState = {...this.state,  ...currentBreakpoint}
      usedCorner = recommendation_type === 'popup' ? recommendation_corner : recommendation_banner_corner;
      switcherState.corner = usedCorner;
      switcherState.fontsize = recommendation ? recommendation_fontsize : fontsize;
    }

    const currencySwitcher = CurrencySwitcher('currency', this.props, switcherState, () => this.handleCurrencyToggle('currency'));
    const countrySwitcher = CurrencySwitcher('country', this.props, switcherState, () => this.handleCurrencyToggle('country'));

    return (
      <div className={classNames('ly-switcher-wrapper', position, usedCorner)}
        style={{ 
          margin: `${v_space}px ${h_space}px`,
          fontSize: recommendation ? recommendation_fontsize : fontsize,
          color: text_color,
        }}
      >
        <React.Fragment>
          {this.state.country_switcher_enabled && !this.state.show_country_selector_inside_popup && countrySwitcher}
          {(switcherState.type === 'custom' && languages.length > 1) && CustomSwitcher(this.props, switcherState, this.handleDropdownToggle)}
          {(switcherState.type === 'native_select' && languages.length > 1) && NativeSwitcher(this.props, switcherState)}
          {(switcherState.type === 'links' && languages.length > 1) && LinkSwitcher(this.props, switcherState)}
          {(switcherState.type === 'links_list' && languages.length > 1) && ListSwitcher(this.props, switcherState)}
          {(switcherState.type === 'popup' && languages.length > 1) && PopupSwitcher(this.props, switcherState, this.handlePopupToggle)}
          {this.state.currency_switcher_enabled && !this.state.show_currency_selector_inside_popup && currencySwitcher}

          {this.state.use_submit_button && (this.state.type === 'custom' || this.state.type === "native_select") &&
            <button 
              className={classNames('ly-submit-btn')}
              style={{
                color: this.state.button_text_color,
                backgroundColor: !this.state.is_transparent ? this.state.button_bg_color : 'inherit',
                padding: `${this.state.v_item_padding}px ${this.state.h_item_padding}px`,
                borderStyle: 'solid', 
                borderColor: this.state.border_color, 
                borderRadius: this.state.border_radius, 
                borderWidth: this.state.border_width
              }}
            >
              Submit
            </button>
          }
        </React.Fragment>
      </div>
    )
  }

  renderPreviewFrame = () => {
    const { classes, languages } = this.props;
    const { tabIndex, corner, recommendation_corner, recommendation_banner_corner, recommendation_type, position, v_space, h_space, fontsize, text_color, is_transparent, bg_color } = this.state;

    const getPreviewWidth = () => {
      let imgWidth = this.state.currentBreakpoint != 1200 ? this.state.currentBreakpoint : 1200;
      if(imgWidth === 0 && this.state.breakpoints.length > 1) imgWidth = 450; //this.state.breakpoints[1].key;
      else if(imgWidth === 0 && this.state.breakpoints.length === 1) imgWidth = 1200;
      return imgWidth;
    }
    const imgWidth = getPreviewWidth();
    
    return <div ref={this.previewFrameWrapper} id="previewFrameWrapper" className={classes.previewFrameWrapper}>
      <TransformWrapper
        key={`preview-transform-${this.state.currentBreakpoint}`}
        //doubleClick={{ mode: `${scale > 2 ? "reset" : "zoomIn"}`}}
        onZoomChange={this.handleZoomChange}
        options={{
          maxScale: 2,
          minScale: 1,
          limitToBounds: true, //this.state.limitToBounds,
          limitToWrapper: true,
        }}
        wheel={{
          disabled: true, //this.state.disablePreviewZoomScroll,
        }}
        scalePadding={{
          size: 0,
        }}
        pan={{
          //disabled: true,
          padding: true,
          paddingSize: 0,
          lockAxisX: false,
          lockAxisY: false,
        }}
        zoomIn={{
          step: 10,
        }}
        zoomOut={{
          step: 10,
        }}
      >
        {({ zoomIn, zoomOut, setPositionX, setPositionY, setTransform, resetTransform, setScale, ...rest }) => {

          return <React.Fragment>
            <div className="tools" style={{position: 'absolute', right: 16, bottom: 16, zIndex: 1}}>
              <ButtonGroup variant="contained" color="default" aria-label="contained primary button group">
                <Button onClick={zoomIn}><AddIcon /></Button>
                <Button onClick={zoomOut}><RemoveIcon /></Button>
              </ButtonGroup>
            </div>
            <Fade in={this.state.showScrollZoomHint}>
              <div style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1, background: 'rgba(0,0,0,.4)'}}>
                <span style={{fontSize: '2rem', fontWeight: 'bold', color: '#FFFFFF', textShadow: '0 0 50px #000'}}>Press ⇧ Shift and scroll in order to zoom.</span>
              </div>
            </Fade>
            <TransformComponent>
              <div style={{
                transform: `scale(${this.state.previewScale})`,
                //transformOrigin: '50% 100%',
                transformOrigin: 'top',
                opacity: this.state.previewImageLoaded ? 1 : 0,
                height: '1000px !important',
                alignItems: 'center',
                display: 'flex',
                alignSelf: 'stretch',
              }}>
                <div id="previewFrameContainer" className={classes.previewFrameContainer}
                  ref={this.previewFrameContainer}
                  style={{
                    //transform: `scale(-${1/this.state.previewScale} 0)`,
                    backgroundImage: `url("/files/images/${this.wireframes[this.state.wireframe].img}")`,
                    backgroundOrigin: 'content-box',
                  }}
                > 
                  <div id="previewFrame" ref={this.previewFrameRef} className={classNames(classes.previewFrame, 'stage-bg')}
                    style={{
                      width: this.wireframes[this.state.wireframe].viewport.width,
                      height: this.wireframes[this.state.wireframe].viewport.height,
                      margin: this.wireframes[this.state.wireframe].padding,
                    }}
                    onMouseEnter={() => {this.setState({disablePreviewZoomScroll: true})}}
                    onMouseLeave={() => {this.setState({disablePreviewZoomScroll: false})}}
                  >

                    <div id="previewFrame__storeFrontWrapper" className={classes.storeFrontWrapper}>
                      <div className={classes.storeFrontImage} 
                        key={`storeFrontImage`}
                        style={{
                          //display: breakpoint.key === this.state.currentBreakpoint ? 'inline-block' : 'none', 
                          opacity: position === 'custom' ? .5 : 1, 
                        }} 
                      >
                        {this.state.configuratorIsOpen && 
                          <img
                            src={`https://service.langify-app.com/image/${this.props.shopUrl.replace('.myshopify.com', '')}/preview.png?width=${imgWidth}&height=${(imgWidth*2 > 1000) ? imgWidth*2 : 1000}&preview_theme_id=${this.state.themeId}&updated_at=${this.props.themes.themes.find(x => x.id == this.props.editTheme).updated_at}&lyhide=true`}
                            onLoad={this.previewImageLoaded}
                            onError={(e) => this.previewImageGenerate(imgWidth, e)}
                          />
                        }
                      </div>
                      {(this.state.tabIndex === 0 && position === 'absolute') && 
                        this.renderSwitcherWrapper(null)
                      }    
                    </div>
                  </div>

                  {((this.state.tabIndex === 1 && this.state.recommendation_enabled && this.state.recommendation_backdrop_show && this.state.recommendation_type === 'popup') || this.state.popup_open) && 
                    <div className={'ly-popup-modal-backdrop'} onClick={tabIndex !== 1 ? this.handlePopupToggle : null} style={{ margin: this.wireframes[this.state.wireframe].padding }}></div>
                  }
                  
                  {(position === 'fixed' || (this.state.recommendation_enabled && tabIndex === 1)) && 
                    <div className={
                      classNames(
                        classes.fixedWrapper, 
                        tabIndex === 1 && 'recommendation', 
                        tabIndex === 0 && corner, 
                        tabIndex === 1 && recommendation_type === 'popup' && recommendation_corner, 
                        tabIndex === 1 && recommendation_type === 'banner' && recommendation_banner_corner)
                      } 
                      style={{
                        padding: this.wireframes[this.state.wireframe].padding,
                        margin: 50,
                      }}
                    >
                      <div className={classNames(classes.fixedContainer)}>
                        {this.state.tabIndex === 0 && position === 'fixed' &&
                          this.renderSwitcherWrapper(null)
                        }          
                        {(this.state.tabIndex === 1 && this.state.recommendation_enabled) && 
                          Recommendation(this.props, this.state, this.renderSwitcherWrapper(true), this.handleRecommendationToggle)
                        }
                      </div>                          
                    </div>
                  }

                  {this.state.popup_open &&
                    <div className={classNames('ly-popup-switcher-wrapper', 'ly-popup-switcher')} style={{padding: this.wireframes[this.state.wireframe].padding}}>
                      {PopupSwitcherModal(this.props, this.state, this.handleDropdownToggle, () => this.handleCurrencyToggle('country'), () => this.handleCurrencyToggle('currency'), this.handlePopupToggle)}
                    </div>
                  }
                </div>               
              </div>

            </TransformComponent>
          </React.Fragment>
        }}
      </TransformWrapper>

      <div style={{position: 'absolute', bottom: 0, width: '100%', zIndex: 1, display: 'none'}}>
        <Paper className={classes.infoBox} style={{}} elevation={0}>
          <Typography variant="h6" className={classes.infoText} style={{ fontSize: 15}}>
            Configure and position the switcher here. <a href="http://support.langify-app.com/support/solutions/articles/11000084362-switcher-configurator" target="blank" className={classes.infoText} style={{fontWeight: 'bold', textDecoration: 'none'}}>Read more...</a>
          </Typography>
        </Paper>
      </div>
      <div style={{position: 'absolute', top: 0, left: 0, right: 0, zIndex: 10000}}>
        {this.state.showVersionDiffMsg &&
          <div className={classes.error}>
            <h2>Update Notice</h2> 
            We have updated the switcher configurator. New <span style={{fontWeight: 500}}>Layout</span>, <span style={{fontWeight: 500}}>Currency</span> & <span style={{fontWeight: 500}}>Recommendation</span> feature. <br />
            You can apply it by saving the switcher <i>(Should your custom CSS not be applied correctly, please reach out to our support.)</i>
            <br />
            <Button variant="outlined" size="small" onClick={() => { this.setState({showVersionDiffMsg: false}) }} style={{marginTop: 12, color: '#f44336', borderColor: '#f44336'}}>I've understood</Button>
          </div>
        }
      </div>

      {this.renderBreakpoints()}
    </div>
  }

  previewImageLoaded = (event) => {
    setTimeout(() => { 
      this.setState({ previewImageLoaded: true });
    }, 100);
  }
    
  previewImageGenerate = (imgWidth, event) => {
    event.persist();
    if(!this.state.configuratorIsOpen) return false;

    // Generate preview image
    if(this.previewImageGenerationInterval) {
      clearInterval(this.previewImageGenerationInterval);
    }
    this.setState({ 
      previewImageLoaded: false
    });
    this.previewImageGenerationInterval = setInterval(() => {
      fetch(`https://service.langify-app.com/image/${this.props.shopUrl.replace('.myshopify.com', '')}/generate.png?width=${imgWidth}&height=${(imgWidth*2 > 1000) ? imgWidth*2 : 1000}&preview_theme_id=${this.state.themeId}&updated_at=${this.props.themes.themes.find(x => x.id == this.props.editTheme).updated_at}&lyhide=true`)
        .then(response => response.json())
        .then(data => {
          if(data.image) {
            event.target.src = 'https://service.langify-app.com' + data.image;
            this.setState({ previewImageLoaded: true });
            clearInterval(this.previewImageGenerationInterval);
          }
          if(!this.state.configuratorIsOpen) {
            clearInterval(this.previewImageGenerationInterval);
          }
        });
    }, 1000);
  }



  _compareVersions (a, b) {
    var i, diff;
    var regExStrip0 = /(\.0+)+$/;
    var segmentsA = a.replace(regExStrip0, '').split('.');
    var segmentsB = b.replace(regExStrip0, '').split('.');
    var l = Math.min(segmentsA.length, segmentsB.length);

    for (i = 0; i < l; i++) {
        diff = parseInt(segmentsA[i], 10) - parseInt(segmentsB[i], 10);
        if (diff) {
            return diff;
        }
    }
    return segmentsA.length - segmentsB.length;
  }
}

const styles = theme => ({
  collapseToggler: {
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, 0.87) !important',
    fontWeight: '400 !important',
  },
  contentContainer: {
    marginBottom: theme.spacing(3),
  },
  button: {
    marginLeft: 10,
  },
  icon: {
    marginRight: theme.spacing(2),
    opacity: .5,
  },

  formControl: {
    width:'100%',
  },
  
  previewFrameWrapper: {
    position: 'relative',
    display: 'block',
    width: 'calc(100% - 280px)',
    height: 'calc(100vh - 225px)',
    overflow: 'hidden',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    padding: 0,
  },
  previewFrameContainer: {
    padding: 50,
    display: 'block',
    position: 'relative',
    '& > img': {
      position: 'absolute',
    }
  },
  previewFrame: {
    overflow: 'auto',
    overflowX: 'hidden',
    position: 'relative',
  },

  storeFront: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    '& iframe': {
      width: '100%',
      height: '100%',
      opacity: .75,
    }
  },
  storeFrontWrapper: {
    display: 'inline-block',
    position: 'relative',
    height: 'auto',
    width: '100%',
  },
  storeFrontImage: {
    backgroundColor: '#222',
    width: '100%',
    '& img': {
      width: '100%',
      //opacity: .5,
    }
  },

  previewScaleInfo: {
    position: 'absolute',
    zIndex: 2,
    backgroundColor: '#eee',
    color: '#555',
    bottom: 0,
    left: 'calc(50% + 100px)',
    transform: 'translate(-50%, 0)',
    padding: '2px 8px',
    fontSize: '.75rem',
  },
  screenshotInfo: {
    position: 'absolute',
    color: '#555',
    top: '50%',
    left: 'calc(50% + 100px)',
    padding: '2px 8px',
    transform: 'translateX(-50%) translateY(-50%)'
  },
  slider: {
    width: '100%',
    padding: '8px 0',
  },
  chip: {
    margin: '0 4px',
    position: 'relative',
    backgroundColor: '#fff !important',
    '&>div': {
      width: '32px !important',
      height: '32px !important',
      margin: '0px !important',
    },
    '&.in': {
      width: 32,
    },
    '&.in>div': {
      padding: '0 !important',
      color: '#404a86',
      backgroundColor: 'transparent',
      marginRight: -3,
    },
    '&.in span': {
      padding: '0 !important',
    }
  },
  breakpointInput: {
    float: 'left',
    margin: '8px 8px 0 8px',
    color: '#fff',
    fontSize: '.8rem',
    backgroundColor: '#eee',
    width: 100,
    '& input': {
      fontSize: '.8rem',
      padding: '6px 6px',
      backgroundColor: '#eee',
    },
    '&.from': {
      width: '65%',
    },
    '&.to': {
      width: '45%',
    },
    '& .MuiFormHelperText-root': {
      marginTop: 0,
      backgroundColor: '#fff',
    }
  },
  breakpointInputSubmit: {
    marginTop: 8,
    float: 'right',
  },
  breakpointInputDivider: {
    //width: '10%',
    textAlign: 'center',
    marginTop: 14,
    float: 'left',
  },
  selectLabel: {
    position: 'absolute',
    top: -7,
    left: 8,
    padding: '0 16px',
    zIndex: 1,
    //backgroundColor: '#fff',
  },
  fixedWrapper: {
    position: 'absolute', 
    /*
    left: 0,
    right: 0,
    display: 'inline-block',
    textAlign: 'center',
    */
    '&.top_left': { top: 0, left: 0 },
    '&.top_center': { top: 0, left: '50%', transform: 'translateX(-50%)' },
    '&.top_right': { top: 0, right: 0},
    '&.center_center': { top: '50%', left: '50%', transform: 'translateX(-50%) translateY(-50%)' },
    '&.bottom_left': { bottom: 0, left: 0 },
    '&.bottom_center': { bottom: 0, left: '50%', transform: 'translateX(-50%)' },
    '&.bottom_right': { bottom: 0, right: 0},
  },
  fixedContainer: {
    /*
    display: 'inline-block', 
    */
    position: 'relative', 
    '& .ly-languages-switcher': {
      position: 'relative !important'
    },
  },
  customWrapper: {
    position: 'absolute', 
    top: 0,
    left: 280,
    right: 0,
    bottom: 0,
    zIndex: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& .ly-languages-switcher': {
      transform: 'scale(1) !important'
    }
  },
  infoBox: {
    backgroundColor: 'rgb(241, 243, 249)', 
    padding: '12px 24px', 
    border: 'none', 
    borderBottom: 'solid 1px rgba(0,0,0,.05)', 
    borderRadius: 0
  },
  infoText: {
    color: '#5c6bc0',
  },

  geolizrBanner: {
    position: 'relative',
    padding: '12px 24px', 
    backgroundColor: '#e8f5e9', 
    backgroundImage: 'url("/geolizr-globe.png")', 
    backgroundPosition: 'calc(100% + 10px) center', 
    backgroundSize: '300px', 
    border: 'solid 1px rgba(0,0,0,.05)',
    marginTop: 8,
  },
  geolizrText: {
    color: '#1b5e20',
    paddingRight: 300,
    [theme.breakpoints.down('md')]: {
      paddingRight: 150,
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    }
  },
  geolizrCTA: {
    backgroundColor: '#1b5e20',
    color: '#fff',
    marginTop: 12,
    '& :hover': {
      color: '#1b5e20',
    },
  },
  geolizrLogo: {
    position: 'absolute',
    right: 24,
    bottom: 12,
    height: 40,
    width: 145,
    backgroundImage: 'url("/geolizr-logo.png")', 
    backgroundSize: '145px', 
  },
  tabsRoot: {
    //borderBottom: '1px solid #e8e8e8',
  },
  tabsIndicator: {
    backgroundColor: '#1a237e',
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 'auto !important',
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover': {
      color: '#1a237e',
      opacity: 1,
    },
    '&$tabSelected': {
      color: '#1a237e',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#1a237e',
    },
  },
  tabSelected: {

  },

  gridContainer: {
    flexGrow: 1,
  },

  codemirror: {
    fontSize: 14,
    height: '100% !important',
  },

  sidebarList: {
    width: 280,
    height: 'calc(100vh - 225px)',
    overflowX: 'hidden',
    overflowY: 'auto',
    float: 'left',
    padding: 0,
    borderRight: 'solid 1px rgba(0, 0, 0, 0.12)',
    '& .MuiTypography-caption': {
      fontSize: '0.925rem',
    }
  },

  sidebarIcon: {
    marginRight: 24
  },

  fieldset: {
    padding: '8px 16px',
    margin: '0',
    width: '100%',
    '& legend': {
      fontSize: '.925rem',
      margin: '0 0 8px 0',
    }
  },
  fieldsetDivider: {
    margin: '8px 0',
  },
  fieldsetLabel: {
    marginBottom: 8,
  },
  fieldsetSelectInput: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 32,
    paddingLeft: 16,    
    fontSize: '0.925rem',
  },
  radioGroup: {
    padding: '0 0 8px 16px',
    outline: 'none',
    '& label': {
      marginLeft: '0 !important',
    },
    '& label > span': {
      color: 'rgba(0, 0, 0, .6)',
    },
    '& label>span': {
      padding: '4px 10px 4px 0',
      fontSize: '.925rem',
      '& svg': {
        //color: 'rgba(0, 0, 0, .6)',
        fontSize: '1.35rem !important',
      },
    },
  },
  nested: {
    paddingLeft: theme.spacing(3),
    '& span': {
      //color: 'rgba(0, 0, 0, .6)',
    },
    '&>div': {
      fontSize: '0.925rem !important',
    },
    '&.hasCheckbox': {
      paddingLeft: 12,
      //color: 'rgba(0, 0, 0, .6)',
      '& svg': {
        fontSize: '1.35rem !important',
      },
      '& span': {
        //color: '#5c6bc0',
      },
    },
  },
  nestedSelected: {
    backgroundColor: 'rgba(0, 0, 0, 0.08) !important',
    '& span:nth-child(2)': {
      color: '#1a237e',
      color: 'rgba(0, 0, 0, 1)',
      //fontWeight: 'bold',
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.1) !important',
    },
  },
  sectionItem: {
    backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
    padding: '8px 0',
  },
  activeSection: {
    backgroundColor: 'rgba(92, 107, 192, 0.7) !important',
    color: '#fff',
    '& span': {
      color: '#fff',
    }
  },

  breakpointSettingsDialog: {
    width: 400,
    right: 0,
    padding: '20px 30px 0 0',
    '& $arrow': {
      top: 20,
      right: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.common.white} transparent`,
      },
    },
  },
  breakpointSettingsDialogContent: {
    padding: '16px !important'
  },

  arrow: {
    position: 'absolute',
    fontSize: 16,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  switchContainer: {
    position: 'absolute',
    right: 8,
    top: 'unset',
    height: 16,
  },

  sliderInput: {
    margin: '2px 0 0 0', 
    borderRadius: 3,
    width: 80, 
    float: 'right', 
    '& input': {
      textAlign: 'right !important', 
      padding: 2,
      color: 'rgba(0, 0, 0, 0.54)',
      background: 'rgba(0, 0, 0, .05)',
      fontSize: '.925rem',
    }
  },

  radioLabel: {
    fontSize: '14px !important',
    '& .MuiRadio-root': {
      padding: '4px !important',
    }
  },

  colorLabel: {
    width: 32, 
    height: 32, 
    marginRight: 8,
    display: 'inline-flex'
  },
  colorInput: {
    padding: '3px 0 !important'
  },

  formLabel: {
    marginLeft: 16,
    marginTop: 8,
    display: 'inline-block',
  },

  newBadge: {
    transform: 'none',
    right: -50,
  },

  error: {
    backgroundColor: '#ffebee',
    color: '#f44336',
    padding: '20px 40px', 
    border: 'none', 
    '& h2': {
      color: '#f44336',
      marginTop: 0,
      marginBottom: '0.5em',
    }
  },

  notice: {
    marginBottom: 0,
    borderRadius: '4px 4px 0 0',
    overflow: 'hidden',
    border: 'solid 1px rgba(0,0,0,.05)',
    '& .toolbar': {
      minHeight: 'auto !important',
      padding: '2px 16px',
    },
    '& p': {
      margin: '8px !important',
    },
    '& svg': { 
      fontSize: 24, 
      marginTop: -2, 
      marginRight: 8, 
    },
    '&.is-fixed': {
      position: 'fixed',
      top: 60,
      left: 240,
      width: '100%',
      padding: 4,
    },
  },
  disabledLayer: {
    position: 'absolute',
    left: 0,
    width: '100%',
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'end',
    padding: 12,
    background: 'linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,.7) 50%, rgba(255,255,255,.9) 100%)',
    fontWeight: 300,
    //color: '#1a237e',
    '& span': {
      textDecoration: 'underline',
      cursor: 'pointer',
      display: 'inline-block',
    }
  },
  domainsWrapper: {
    height: 'calc(100vh - 225px)',
    overflow: 'hidden',
    overflowY: 'auto',
  },
  
  textField: {
    margin: '8px 0',
    '& .MuiOutlinedInput-inputMarginDense': {
      padding: '.5rem !important',
      fontSize: '.9rem'
    }
  },
  textFieldInput: {
    padding: '.5rem !important'
  }
});

SwitcherConfigurator.propTypes = {
  classes: PropTypes.object,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getSwitcherConfig,
      getCustomIcons,
      updateCustomIconsMetafield,
      syncMarkets,
    },
    dispatch
  )
const mapStateToProps = ({ shop, languages, themes, switcherConfigurator, markets }) => ({
  languages: languages.languages,
  available_language_codes: languages.available_language_codes,
  editLanguage: shop.shop.edit_language,
  editTheme: themes.edit_theme,
  shopUrl: shop.shop.url,
  shopFeatures: shop.shop.features,
  crossDomainLinksEnabled: shop.shop.cross_domain_links_enabled,
  enableCrossDomainLinksPending: shop.enable_cross_domain_links_pending,
  disableCrossDomainLinksPending: shop.disable_cross_domain_links_pending,
  themes: themes,
  primary_domain: shop.shop.primary_domain,
  updateSwitcherConfig: switcherConfigurator.updateSwitcherConfig,
  customIcons: switcherConfigurator.customIcons,
  markets: markets,
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(SwitcherConfigurator))));