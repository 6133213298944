import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import List from '@material-ui/core/List';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import StarIcon from '@material-ui/icons/StarBorder';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import KeyboardArrowLeftOutlinedIcon from '@material-ui/icons/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@material-ui/icons/KeyboardArrowRightOutlined';

import { Trans, withTranslation, useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { NumericFormat } from 'react-number-format';

import 'swiper/swiper.min.css'

import API from '../api/api';
import { TextField } from '@material-ui/core';

const marks = [
  {
    value: 10000,
    scaledValue: 10000,
    label: '10K',
  },
  {
    value: 50000,
    scaledValue: 50000,
    label: '50K',
  },
  {
    value: 200000,
    scaledValue: 200000,
    label: '200K',
  },
  {
    value: 500000,
    scaledValue: 500000,
    label: '500K',
  },
  {
    value: 1000000,
    scaledValue: 1000000,
    label: '1M',
  },
  {
    value: 2000000,
    scaledValue: 2000000,
    label: '2M',
  },
  {
    value: 5000000,
    scaledValue: 5000000,
    label: '5M',
  },
  {
    value: 10000000,
    scaledValue: 10000000,
    label: '10M',
  },
];
const discountMarks = [
  {
    value: 20000,
    label: 'DISCOUNT 10%',
  },
  {
    value: 50000,
    label: 'DISCOUNT 20%',
  },
  {
    value: 200000,
    label: 'DISCOUNT 30%',
  },
  {
    value: 500000,
    label: 'DISCOUNT 40%',
  },
  {
    value: 1000000,
    label: 'DISCOUNT 42%',
  },
];

class RechargePlans extends React.Component {

  swiperInstance = null;
  tempValues = null;

  constructor(props) {
    super(props);

    this.state = {
      wordpacks: [],
      activeWordpack: null,
      sliderValue: 50000,
      open: false,
      wordsNumError: false,
      dynamicInputKey: 0,
      isSm: window.innerWidth < this.props.theme.breakpoints.values.md,
      isXs: window.innerWidth < this.props.theme.breakpoints.values.sm,
      pending: false,
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentWillReceiveProps(newProps) {    

    if(this.props.visible !== newProps.visible) {
      if(newProps.visible === true) {
        if(this.state.wordpacks.length === 0) {
          API.bulkTranslationGetWordpacks(response => {
            //console.log(response);
            if(response.success === true) {
              this.setState({
                wordpacks: response.wordpacks,
                activeWordpack: response.wordpacks[2],
                open: true
              })
            }
          })        
        } else {
          this.setState({
            open: true
          });
        }
      } else {
        this.setState({
          open: false
        })
      }
    }
  }

  handleResize() {
    const isSm = window.innerWidth < this.props.theme.breakpoints.values.md;
    if (isSm !== this.state.isSm) {
      this.setState({ isSm });
    }
    const isXs = window.innerWidth < this.props.theme.breakpoints.values.sm;
    if (isXs !== this.state.isXs) {
      this.setState({ isXs });
    }
  }

  valueLabelFormat(value) {
    return `${value}`;
  }


  scaleMarks = (marks) => {
    let newMarks = [];
    let sliderPositionPercent = Math.round((this.state.sliderValue/10000000)*100);
    
    marks.forEach((mark, index) => {
      let newMark = {...mark};
      newMark.value = newMark.value * (3 * (100 - sliderPositionPercent));
      newMarks.push(newMark);
    })

    return newMarks;
  }
  scaleMax = () => {
    let sliderPositionPercent = this.state.sliderValue/10000000;
    let newMax = Math.round(9500000*sliderPositionPercent);
    return newMax+500000;
  }
  roundSliderValue = () => {
    const roundedWordsNum = Math.ceil(this.state.sliderValue/10000)*10000;
    this.setState({sliderValue: roundedWordsNum});
  }


  numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return Math.floor(num / 1000).toFixed(0) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num >= 1000000) {
      return Math.floor(num / 1000000).toFixed(0) + "M"; // convert to M for number from > 1 million
    } else if (num < 900) {
      return num; // if value < 1000, nothing to do
    }
  }

  handleWordsNumChange = (event, value) => {
    let error = false;
    let newValue = value;
    if(!value) {
      newValue = parseInt(event.target.value);
    }

    newValue = Math.ceil(newValue/10000)*10000;
    if(newValue > 10000000) {
      newValue = 10000000;
    }
    if(newValue < 10000) {
      newValue = 10000;
    }
    this.setState({
      sliderValue: newValue
    })
    this.tempValues = {
      value: newValue
    }

    // Sync swiper
    //if(event !== null) {
      if(this.currentPassedMark !== this._getLastPassedMark(newValue)) {
        this.currentPassedMark = this._getLastPassedMark(newValue);
        this.swiperInstance.slideTo(this.currentPassedMark, 300, true);

        this.setState({
          activeWordpack: Object.assign(this.state.wordpacks[this.currentPassedMark]),
          sliderValue: newValue
        });        
      }
    //}
  }


  handleWordsNumFieldChange = (values, sourceInfo) => {
    const { formattedValue, value } = values;
    const { event, source } = sourceInfo;
    //this.handleWordsNumChange(event, value)
    this.tempValues = values;

    let error = false;
    let newValue = Math.ceil(value/10000)*10000;
    if(value%10000) {
      error = newValue;
    }
    if(newValue > 10000000) {
      newValue = 10000000;
    }
    if(newValue < 10000) {
      newValue = 10000;
    }
    
    this.setState({
      sliderValue: newValue
      //wordsNumError: error
    });
    //this.handleWordsNumChange(null, newValue);
  }

  handleWordsNumFieldBlur = (event) => {
    let newValue = this.state.sliderValue;
    if(this.tempValues) {
      newValue = this.tempValues.value;
    }
    newValue = Math.ceil(newValue/10000)*10000;
    if(newValue > 10000000) {
      newValue = 10000000;
    }
    if(newValue < 10000) {
      newValue = 10000;
    }
    this.setState({
      sliderValue: newValue,
      wordsNumError: false,
      dynamicInputKey: this.state.dynamicInputKey + 1
    });
    this.handleWordsNumChange(null, newValue);
  }

  _getLastPassedMark = (val) => {
    let currentMark = 0;
    this.state.wordpacks.map((wordpack, index) => {
      if(val >= wordpack.count) {
        currentMark = index;
      }
    })
    return currentMark;
  }

  selectWordpack = (wordpack) => {
    //alert(wordpack.hash);
    this.setState({sliderValue: wordpack.count});
  }

  handleSlideClick = (swiper) => {
    this.swiperInstance.slideTo(this.swiperInstance.clickedIndex, 300, true);

    // Sync slider
    this.setState({
      //sliderValue: () => { this.unscale(this.state.wordpacks[this.swiperInstance.clickedIndex].count) },
      sliderValue: this.state.wordpacks[this.swiperInstance.clickedIndex].count,
      activeWordpack: Object.assign(this.state.wordpacks[this.swiperInstance.clickedIndex]),
    });
  }

  handleSlideChange = (swiper) => {
    // Sync slider
    if(this.swiperInstance && !this.swiperInstance.destroyed) {
      this.setState({
        //sliderValue: () => { this.unscale(this.state.wordpacks[this.swiperInstance.clickedIndex].count) },
        sliderValue: this.state.wordpacks[this.swiperInstance.activeIndex].count,
        activeWordpack: Object.assign(this.state.wordpacks[this.swiperInstance.activeIndex]),
      });
    }
  }

  handlePrevNextPriceZone = (direction) => {
    if(direction === 'prev') {
      this.swiperInstance.slidePrev(300, true);
    } else {
      this.swiperInstance.slideNext(300, true);      
    }

    // Sync slider
    this.setState({
      sliderValue: this.state.wordpacks[this.swiperInstance.activeIndex].count,
      activeWordpack: Object.assign(this.state.wordpacks[this.swiperInstance.activeIndex]),
    });
  }

  buyWordpack = () => {
    const { wordpacks, activeWordpack, sliderValue } = this.state;
    const wordsNum = sliderValue;

    this.setState({
      pending: true
    });

    // Round up to next 10.000
    const roundedWordsNum = Math.ceil(wordsNum/10000)*10000;
    // Create hash
    const hash = window.btoa(roundedWordsNum);

    if(hash) {
      API.bulkTranslationBuyWordpack(hash, (res) => {
        if(res.success === true) {
          window.location.href = res.response.confirmationUrl;
        } else {
          // Error
          //setError(res.error);
          this.setState({
            pending: false
          });
        }
      })
    }
  }

  onBuy = plan => {
    this.props.onBuy(plan)
  }


  render() {
    const { t, classes, rows, contained } = this.props;
    const { wordpacks, activeWordpack, sliderValue, wordsNumError, dynamicInputKey, isSm, isXs } = this.state;

    const slidesPerView = () => {
      if(isXs) return 1;
      if(isSm) return 2;
      return 3;
    }
    return (
      <Dialog
        className={classes.dialog}
        onClose={this.props.onClose}
        aria-labelledby="customized-dialog-title"
        open={this.state.open}
        maxWidth={'lg'}
        fullWidth={true}
        classes={{
          scrollPaper: classes.scrollPaper
        }}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            padding: '10px',
            margin: 0,
            minHeight: '100%',
            position: 'unset',
            overflow: 'visible',
          }
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(255, 255, 255, 1)'
          }
        }}
      >
        <Grid id={'boxes'} container alignContent="flex-start" justifyContent="center" style={{paddingBottom: '1rem'}}>
          <Grid item xs={12} md={12} lg={10}>

            <Typography component="h4" variant="h4" align="center" color="textPrimary" gutterBottom className={classes.title}>
              {t('buyAutoTranslationWordsDialog.title')}
            </Typography>
            <Typography variant="h5" align="center" color="textSecondary" component="p">
              {t('buyAutoTranslationWordsDialog.subtitle')}
            </Typography>

            <Divider className={classes.divider0} />

            <div className={classes.sliderContainer}>
              <Slider
                value={this.state.sliderValue}
                min={10000}
                max={this.scaleMax()}
                getAriaValueText={this.numFormatter}
                valueLabelFormat={this.numFormatter}
                onChange={this.handleWordsNumChange}
                onChangeCommitted={this.roundSliderValue}
                valueLabelDisplay="on"
                aria-labelledby="non-linear-slider"
                marks={marks}
                classes={{
                  root: classes.sliderRoot,
                  thumb: classes.thumb,
                  active: classes.active,
                  valueLabel: classes.valueLabel,
                  track: classes.track,
                  rail: classes.rail,
                  mark: classes.mark,
                  markLabel: classes.markLabel,
                }}
              />              
              <Slider
                value={this.state.sliderValue}
                min={10000}
                max={this.scaleMax()}
                getAriaValueText={this.numFormatter}
                valueLabelFormat={this.numFormatter}
                valueLabelDisplay="off"
                aria-labelledby="non-linear-slider"
                marks={discountMarks}
                classes={{
                  root: classes.discountSliderRoot,
                  thumb: classes.discountSliderThumb,
                  active: classes.discountSliderActive,
                  valueLabel: classes.discountSliderValueLabel,
                  track: classes.discountSliderTrack,
                  rail: classes.discountSliderRail,
                  mark: classes.discountSliderMark,
                  markActive: classes.discountMarkActive,
                  markLabel: classes.discountSliderMarkLabel,
                }}
              />   
            </div>

            <div style={{position: 'relative'}}>
              <div /*style={{position: 'absolute', width: '100%', left: 0, marginTop: 20}}*/ >
                <Swiper
                  spaceBetween={0}
                  slidesPerView={slidesPerView()}
                  centeredSlides={true}
                  initialSlide={2}
                  onSlideChange={this.handleSlideChange}
                  onSwiper={(swiper) => {
                    this.swiperInstance = swiper;
                  }}
                >
                  {this.state.wordpacks.map(wordpack => (
                    <SwiperSlide 
                      key={`wordpack-${wordpack.title}`} 
                      className={classes.swiperSlide}
                    >
                      {({ isActive }) => (
                      <div
                        onClick={isActive ? null : this.handleSlideClick} className={classNames(isActive ? 'active-slide' : classes.cardPricing, classes.cardPricing)} style={{filter: wordsNumError ? 'blur(.2rem)' : 'none'}}>
                        <Typography align="center" component="p">
                          <span className={'packTitle'} style={{fontSize: '1.5rem', color: '#1a237e'}}>{wordpack.title}</span>
                          <br />
                          {!isActive ?
                          <strong style={{fontSize: '1.75rem', fontWeight: 400}}>{wordpack.count_formatted}</strong>
                          :
                          <NumericFormat 
                            key={`WordsNumInput-${dynamicInputKey}`}
                            value={sliderValue} 
                            onValueChange={this.handleWordsNumFieldChange}
                            onBlur={this.handleWordsNumFieldBlur}
                            customInput={TextField}
                            allowNegative={false}
                            thousandSeparator="," 
                            displayType="input"
                            isAllowed={(values) => {
                              const { floatValue } = values;
                              return floatValue <= 10000000;
                            }}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">{t('buyAutoTranslationWordsDialog.words')}</InputAdornment>,
                              style: {
                                textAlign: 'right'
                              }
                            }}
                            classes={{
                              root: classes.wordNumInputRoot
                            }}
                            error={!this.state.wordsNumError ? false : true}
                          />
                          }
                        </Typography>    
                        <>
                          {wordpack.discount_percent > 0 &&
                            <Typography variant="h6" color="textSecondary" align="center">
                              {t('buyAutoTranslationWordsDialog.save')} {wordpack.discount_percent}%
                            </Typography>
                          }
                          <div className={classNames('blur2')} style={{justifyContent: 'center'}}>
                            {wordpack.discount_percent > 0 &&
                              <>
                                {!isActive ?
                                  <Typography variant="h4" color="textSecondary" align="center" className={classes.oldPrice}>
                                    ${(wordpacks[0].price * wordpack.count - 0.01).toFixed(2)}
                                  </Typography>
                                :
                                  <Typography variant="h4" color="textSecondary" align="center" className={classes.oldPrice}>
                                  ${(wordpacks[0].price * sliderValue - 0.01).toFixed(2)}
                                  </Typography>
                                }                              
                              </>

                          }
                            {!isActive ?
                            <>
                              <Typography component="h4" variant="h4" color="textPrimary">
                                {wordpack.price_formatted}
                              </Typography>
                              <Typography variant="h6" color="textSecondary">
                                {t('buyAutoTranslationWordsDialog.once')}
                              </Typography> 
                            </>
                            :
                            <>
                              <Typography component="h4" variant="h4" color="textPrimary">
                                {sliderValue === activeWordpack.count ?
                                  activeWordpack.price_formatted
                                :
                                  `$${(activeWordpack.price * sliderValue - 0.01).toFixed(2)}`
                                }
                              </Typography>
                              <Typography variant="h6" color="textSecondary">
                                {t('buyAutoTranslationWordsDialog.once')}
                              </Typography>
                            </>
                            }                       
                          </div>    
                          <Typography component="p" variant="subtitle1" align="center">
                            ${wordpack.price} / {t('buyAutoTranslationWordsDialog.word')}
                          </Typography>
                        </>
                         
                        {isActive &&
                          <Button onClick={this.buyWordpack} disabled={this.state.pending} variant="contained" color="primary" size="large" className={classes.buyButton}>
                            {t('buyAutoTranslationWordsDialog.buyNow')}
                            {this.state.pending && 
                              <span className={classes.buttonLoadingWrapper}>
                                <CircularProgress />
                              </span>
                            }
                          </Button>  
                        }  
                      </div>       
                      )}
                    </SwiperSlide>

                  ))}
                </Swiper>
              </div>

              <span className={classNames(classes.priceZoneBtn, 'prev', this._getLastPassedMark(sliderValue) === 0 && 'disabled' )} onClick={() => this.handlePrevNextPriceZone('prev')}>
                {/*<KeyboardArrowLeftOutlinedIcon />*/}
                <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                  <path d="M75,95 L25,50 L75,5" stroke="black" stroke-width="2" fill="none"/>
                </svg>
              </span>
              <span className={classNames(classes.priceZoneBtn, 'next', this._getLastPassedMark(sliderValue) === 5 && 'disabled')} onClick={() => this.handlePrevNextPriceZone('next')}>
               {/* <KeyboardArrowRightOutlinedIcon />*/}
                <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                  <path d="M25,95 L75,50 L25,5" stroke="black" stroke-width="2" fill="none"/>
                </svg>
              </span>
                  
            </div>



            <Divider className={classes.divider0} />


            <Typography variant="caption" lg="">
              <Trans>
                The smallest order unit consists of 10,000 words. If you specify a number of words manually, it is always rounded up to the next larger order unit.
                Used words cannot be refunded. Unused words lose their validity as soon as this app is removed and won't be refunded either.
              </Trans>
            </Typography>

          </Grid>
        </Grid>

        <IconButton variant="outlined" onClick={this.props.onClose} color="default" size="medium" style={{position: 'absolute', top: 10, right: 10, cursor: 'pointer'}}>
          <CloseIcon />
        </IconButton>
      </Dialog>
    );
  }
}

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: '20px'
  },
  dialog: {
    padding: '0',
  },
  paper: {
    height: '350px',
    width: '300px',
    padding: '20px',
    textAlign: 'center',
    overflow: 'visible',
  },
  scrollPaper: {
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  divider: {
    margin: '5rem 0',
  },
  divider0: {
    margin: '5rem 0 1rem 0',
  },
  divider1: {
    margin: '1rem 0 5rem 0',
  },
  box: {
    padding: 20,
  },
  boxHeadline: {
    margin: 0,
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1.25rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '1.6',
    letterSpacing: '0.0075em',
  },
  price: {
    marginTop: 20,
    marginBottom: 10,
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '2.25rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '500',
    lineHeight: '1.6',
    letterSpacing: '0.0075em',
  },
  words: {
    marginTop: 20,
    marginBottom: 10,
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: 'white',
    fontSize: '1.55rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '1.6',
    letterSpacing: '0.0075em',
  },
  buyButton: {
    marginTop: 30,
    position: 'absolute',
    bottom: -60,
    left: 0,
    right: 0,
  },
  chargeType: {
    marginTop: 20
  },

  sliderContainer: {
    minHeight: 100,
    [theme.breakpoints.up('md')]: {
      padding: '3rem'
    }
  },
  sliderRoot: {
    //color: '#52af77',
    marginBottom: 0,
    height: 16,
    '&::after': {
      content: '""',
      width: '100%',
      height: 150,
      backgroundColor: 'white',
      position: 'absolute',
      right: 'calc(-102% - 12px)',
      top: -50,
    }
  },
  thumb: {
    height: 32,
    width: 32,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 12px)',
  },
  track: {
    height: 16,
    borderRadius: 8,
    left: '-3% !important',
    paddingLeft: '3%',
  },
  rail: {
    height: 16,
    borderRadius: 8,
    width: 'calc(106% + 2px)',
    left: '-3%'
  },
  mark: {
    height: 16,
  },
  markLabel: {
    top: -30,
    backgroundColor: 'white',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: 50,
      height: 20,
      left: -50,
      background: 'rgb(255,255,255)',
      background: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)'
    }
  },

  buttonLoadingWrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },



  discountSliderRoot: {
    marginTop: -26,
    zIndex: -1,
    marginBottom: 0,
    display: 'block',
    '&::after': {
      content: '""',
      width: '100%',
      backgroundColor: 'white',
      position: 'absolute',
      right: 'calc(-102% - 12px)'
    },
  },
  discountSliderThumb: {
    display: 'none',
  },
  discountSliderActive: {
  },
  valueLabel: {
    left: 'calc(-50% + 12px)',
  },
  discountSliderTrack: {
    display: 'none',
  },
  discountSliderRail: {
    width: 'calc(106% + 2px)',
    left: '-3%',
    display: 'none',
  },
  discountSliderMark: {
    display: 'none',
  },
  discountMarkActive: {
    fontWeight: 'bold'
  },
  discountSliderMarkLabel: {
    //top: 40,
    transform: 'translateX(0)',
    backgroundColor: 'white',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: 50,
      height: 20,
      left: -50,
      background: 'rgb(255,255,255)',
      background: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)'
    }
  },
  

  
  title: {
    //[theme.breakpoints.down('md')]: {marginTop: '0.35em'}
    marginTop: '4rem'
  },
  boxes: {
    marginTop: 24,
  },
  card: {
    margin: 24,
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[700] : theme.palette.grey[200],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0 32px',
    flexDirection: 'column',
    '& > div': {
      display: 'flex',
      //justifyContent: 'center',
      alignItems: 'baseline',
      whiteSpace: 'nowrap',
      '&.blur *': {
        color: 'transparent',
        textShadow: '0 0 5px rgba(0,0,0,0.5)',
      }
    },
  },
  oldPrice: {
    textDecoration: 'line-through',
    color: 'darkred',
    opacity: .8,
    marginRight: 12,
    fontWeight: 100,
    fontSize: '1.5rem'
  },
  cardActions: {
    padding: 16,
  },
  wordNumInputRoot: {
    maxWidth: 275,
    '& .MuiInputBase-input': {
      fontSize: '2.75rem',
      textAlign: 'center'
    }
  },

  swiperSlide: {
    display: 'inline',
    cursor: 'pointer',
    '&>div': {
      transition: 'all 0.3s ease', //'all 0.5s ease'
      transform: 'scale(.75)',
      border: 'solid 1px #999',
      borderRadius: 8,
      margin: 20,
      padding: 20,
    },
    '&>div.active-slide': {
      transform: 'scale(1.1)',
      border: 'solid 2px #1a237e',
      boxShadow: '1px 1px 16px rgba(0,0,0,0.5)',
      //marginTop: -25
    },
    //margin: '0 25px !important',
    '& > p': {
      transition: 'all 1s',
      whiteSpace: 'nowrap',
    }
  },

  priceZoneBtn: {
    cursor: 'pointer',
    position: 'absolute',
    top: '50%',
    transform: 'translate(0, -50%)',
    zIndex: 100,
  '& svg': {
    width: '3rem',
    height: '3rem',
    '& path': {
      stroke: 'black',
      strokeWidth: 6
    }
  },
  '&:hover svg': {
    '& path': {
      stroke: 'rgb(26, 35, 126)',
    }
  },
  '&.disabled': {
    display: 'none',
  },
    [theme.breakpoints.down('sm')]: {
      '&.next': {
        right: '-3rem !important',
      },
      '&.prev': {
        left: '-3rem !important',
      },
      //display: 'none'
    },
    '&.next': {
      right: '-1.5rem',
    },
    '&.prev': {
      left: '-1.5rem',
    },
  },
});

export default withStyles(styles)(withTheme(withTranslation()(RechargePlans)));
