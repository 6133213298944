
export const _exportDownloadLinkDecorator = (url, options) => {
  if(!url) return url;
  let queryAppendix = '';
  if(url.indexOf('?') === -1) {
    queryAppendix = '?';
  }

  /*
  for (let key in options) {
    if(options[key] && options[key] === true) {
      queryAppendix += `&${key}=${options[key]}`
    }
  }
  */

  if(options && options.export_filter) {
    queryAppendix += `&export_filter=${options.export_filter}`;
  }
  queryAppendix = queryAppendix.replace('?&', '?');
  return url + queryAppendix;
}

export const _clearUnchangedFields = (items, selectedFields, subfieldsId) => {
  let cleanedList = [];
  items.forEach(item => {
    item.fields.filter(x => (x.type === 'subfields' && x.id === subfieldsId))[0].fields.forEach(z => { 
      //console.log(item, z)
      //if(selectedFields[`${item.id}-${subfieldsId}-${z.id}`] || selectedFields[`${item.id}-${subfieldsId}-${z.id}`] === '') {
        cleanedList.push({ 
          source: z.id, 
          target: z.target 
        });            
      //}
    });
  });
  return cleanedList;
}


export const _getAttributeProgress = (array, attribute) => {
  let countEmpty = 0;
  let countNonEmpty = 0;

  //debugger;

  // Durch das Array von Objekten iterieren
  for (let i = 0; i < array.length; i++) {
    const obj = array[i];

    // Überprüfen, ob das Attribut im aktuellen Objekt vorhanden ist
    if (obj.hasOwnProperty(attribute)) {
      const value = obj[attribute];

      //console.log(value);

      // Überprüfen, ob der Wert ein leerer String ist
      if (typeof value === 'string' && value.trim() === '' || !value) {
        countEmpty++;
      } else {
        countNonEmpty++;
      }
    }
  }
  const totalCount = countEmpty + countNonEmpty;

  // Fortschritt in Prozent berechnen
  const progress = (countNonEmpty / totalCount) * 100;
  return progress.toFixed(2);
}



export const _getValidationPropsFromValidations = (validations) => {
  let validationProps = {};
  validations.forEach(validation => {
    if(validation.name === 'max') {
      validationProps.maxLength = validation.value;
    }
  });
  return validationProps;
}

/**
 * This function implements the cyrb53 hash algorithm. It takes a string and an optional seed value 
 * as input and returns a hash value. The function uses a FNV-like approach, multiplying the current 
 * hash and the string's current code point. An additional mixing phase is performed before the final 
 * hash is returned to improve the uniform distribution of hashes.
 * - thank you for the explanation, chatGPT :)
 *
 * @param {String} str - The input string to be hashed.
 * @param {Number} seed - An optional seed value for the hashing algorithm. Default is 0.
 * @returns {Number} The calculated hash value.
 */
export const cyrb53 = (input, seed = 0) => {
  let str = JSON.stringify(input);
  let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;

  for(let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
  }

  h1  = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
  h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2  = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
  h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

/**
 * This function returns the item id, depending on the given value
 * 
 * @param {val} str - Probably a gid string
 */
export const parseItem2FieldIdsPath = (val) => {
  console.log(val);
  if(typeof val === 'string' && val.indexOf('gid://') === 0) {
    return parseItemId(val);
  }
  if(typeof val === 'object') {
    if(val.object && val.errors) {
      let pathsList = [];
      let itemId = parseItemId(val.object);
      val.errors.forEach((err, i) => {
        pathsList.push(`${itemId}-${err.object_key}`);
      });
      return pathsList;
    }
  }
}
export const parseItemId = (val) => {
  if(typeof val === 'string' && val.indexOf('gid://') === 0) {
    return val.split('/').pop();
  }
  else {
    return val
  }
}

export function deepMerge(obj1, obj2) {
  const mergedObj = { ...obj1 };

  for (let key in obj2) {
      if (obj2.hasOwnProperty(key)) {
          // Wenn der Key in beiden Objekten ein Objekt ist, dann merge diese tief.
          if (typeof obj1[key] === 'object' && obj1[key] !== null && typeof obj2[key] === 'object' && obj2[key] !== null) {
              mergedObj[key] = deepMerge(obj1[key], obj2[key]);
          } else {
              // Ansonsten überschreibe bzw. füge den Wert aus obj2 hinzu.
              mergedObj[key] = obj2[key];
          }
      }
  }

  return mergedObj;
}