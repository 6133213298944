import React from 'react';
import { setLangifyNotificationAsUnviewed, setLangifyNotificationAsViewed, getLangifyNotifications, resetErrors } from '../../../modules/langifyNotifications'
import withRouter from 'with-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ItemsList from '../../../components/ItemsList'
import ItemsListSkeleton from '../../../components/ItemsListSkeleton'
import LoadingIndicator from '../../../components/LoadingIndicator';
import EmptyView from '../../../components/EmptyView';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import OptionsMenu from '../../../components/OptionsMenu';
import NewFlexItemList from '../../../components/NewFlexItemList';
import cloneDeep from 'lodash/cloneDeep';
import Link from '@material-ui/core/Link';
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import { ThreeSixtySharp } from '@material-ui/icons';

const styles = theme => ({
  notificationContainer: {
    marginBottom: 24,
  },
  paper: {
    padding: 12,
  }
});

const notifications = [
  {
    id: 1,
    type: 'info',
    title: 'Lorem ipsum',
    body: 'Lorem ipsum dolor set amit',
    viewed: false,
  },
  {
    id: 2,
    type: 'alert',
    title: 'Lorem ipsum',
    body: 'Lorem ipsum dolor set amit',
    viewed: false,
  },
  {
    id: 3,
    type: 'warning',
    title: 'Lorem ipsum',
    body: 'Lorem ipsum dolor set amit',
    viewed: true,
  },
  {
    id: 4,
    type: 'mustAccept',
    title: 'Lorem ipsum',
    body: 'Lorem ipsum dolor set amit',
    viewed: true,
  },
  {
    id: 5,
    title: 'Lorem ipsum',
    body: 'Lorem ipsum dolor set amit',
    viewed: true,
  },
];

class Profile extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      
    }
  }

  handleMarkAsRead = (id) => {
    ThreeSixtySharp.props.setLangifyNotificationAsViewed(id);
  }

  render() {
    const { classes } = this.props;

    return <React.Fragment>
      <h1>Notifications</h1>
      <Link href='/profile/notifications'>Notifications</Link>
      <Divider />

      <div>
        {
          notifications.map(notification => {
            return <div key={`notification-${notification.id}`} className={classes.notificationContainer}>
              <Paper className={classes.paper}>
                <Typography variant='h6'>{notification.title}</Typography>
                <Typography>{notification.body}</Typography>
                {!notification.viewed &&
                  <Button onClick={() => this.handleMarkAsRead(notification.id)} variant={'outlined'} size={'small'}>Mark as read</Button>
                }
              </Paper>
            </div>
          })
        }
      </div>
    </React.Fragment>
  }
}
const mapStateToProps = ({ langifyNotifications, shop }) => ({
  notifications: langifyNotifications.notifications,
  editLanguage: shop.shop.edit_language,
  shopUrl: shop.shop.url,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getLangifyNotifications,
      setLangifyNotificationAsUnviewed,
      setLangifyNotificationAsViewed,
      resetErrors,
    },
    dispatch
  )

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(Profile))))