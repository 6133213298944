import API from '../api/api'
import Cookies from 'universal-cookie';
import { deepMerge } from '../helper'

const GET_SHOP_PENDING = 'GET_SHOP_PENDING'
const GET_SHOP_RESPONSE = 'GET_SHOP_RESPONSE'
const EDIT_LANGUAGE_CHANGED = 'EDIT_LANGUAGE_CHANGED'

const GET_SHOP_PROGRESS_PENDING = 'GET_SHOP_PROGRESS_PENDING'
const GET_SHOP_PROGRESS_PROGRESS = 'GET_SHOP_PROGRESS_PROGRESS'
const GET_SHOP_PROGRESS_RESPONSE = 'GET_SHOP_PROGRESS_RESPONSE'

const GET_SHOP_SEO_PENDING = 'GET_SHOP_SEO_PENDING'
const GET_SHOP_SEO_PROGRESS = 'GET_SHOP_SEO_PROGRESS'
const GET_SHOP_SEO_RESPONSE = 'GET_SHOP_SEO_RESPONSE'
const SAVE_SHOP_SEO_PENDING = 'SAVE_SHOP_SEO_PENDING'
const SAVE_SHOP_SEO_PROGRESS = 'SAVE_SHOP_SEO_PROGRESS'
const SAVE_SHOP_SEO_RESPONSE = 'SAVE_SHOP_SEO_RESPONSE'

const GET_SHOP_PAYMENT_GATEWAYS_PENDING = 'GET_SHOP_PAYMENT_GATEWAYS_PENDING'
const GET_SHOP_PAYMENT_GATEWAYS_PROGRESS = 'GET_SHOP_PAYMENT_GATEWAYS_PROGRESS'
const GET_SHOP_PAYMENT_GATEWAYS_RESPONSE = 'GET_SHOP_PAYMENT_GATEWAYS_RESPONSE'
const SAVE_SHOP_PAYMENT_GATEWAYS_PENDING = 'SAVE_SHOP_PAYMENT_GATEWAYS_PENDING'
const SAVE_SHOP_PAYMENT_GATEWAYS_PROGRESS = 'SAVE_SHOP_PAYMENT_GATEWAYS_PROGRESS'
const SAVE_SHOP_PAYMENT_GATEWAYS_RESPONSE = 'SAVE_SHOP_PAYMENT_GATEWAYS_RESPONSE'

const GET_SHOP_SHIPPING_METHODS_PENDING = 'GET_SHOP_SHIPPING_METHODS_PENDING'
const GET_SHOP_SHIPPING_METHODS_PROGRESS = 'GET_SHOP_SHIPPING_METHODS_PROGRESS'
const GET_SHOP_SHIPPING_METHODS_RESPONSE = 'GET_SHOP_SHIPPING_METHODS_RESPONSE'
const SAVE_SHOP_SHIPPING_METHODS_PENDING = 'SAVE_SHOP_SHIPPING_METHODS_PENDING'
const SAVE_SHOP_SHIPPING_METHODS_PROGRESS = 'SAVE_SHOP_SHIPPING_METHODS_PROGRESS'
const SAVE_SHOP_SHIPPING_METHODS_RESPONSE = 'SAVE_SHOP_SHIPPING_METHODS_RESPONSE'

const GET_SHOP_POLICIES_PENDING = 'GET_SHOP_POLICIES_PENDING'
const GET_SHOP_POLICIES_PROGRESS = 'GET_SHOP_POLICIES_PROGRESS'
const GET_SHOP_POLICIES_RESPONSE = 'GET_SHOP_POLICIES_RESPONSE'
const SAVE_SHOP_POLICIES_PENDING = 'SAVE_SHOP_POLICIES_PENDING'
const SAVE_SHOP_POLICIES_PROGRESS = 'SAVE_SHOP_POLICIES_PROGRESS'
const SAVE_SHOP_POLICIES_RESPONSE = 'SAVE_SHOP_POLICIES_RESPONSE'

const SYNC_SHOP_METAFIELDS_PENDING = 'SYNC_SHOP_METAFIELDS_PENDING'
const SYNC_SHOP_METAFIELDS_PROGRESS = 'SYNC_SHOP_METAFIELDS_PROGRESS'
const SYNC_SHOP_METAFIELDS_RESPONSE = 'SYNC_SHOP_METAFIELDS_RESPONSE'
const SAVE_SHOP_METAFIELDS_PENDING = 'SAVE_SHOP_METAFIELDS_PENDING'
const SAVE_SHOP_METAFIELDS_PROGRESS = 'SAVE_SHOP_METAFIELDS_PROGRESS'
const SAVE_SHOP_METAFIELDS_RESPONSE = 'SAVE_SHOP_METAFIELDS_RESPONSE'

const ENABLE_CROSS_DOMAIN_LINKS_PENDING = 'ENABLE_CROSS_DOMAIN_LINKS_PENDING'
const ENABLE_CROSS_DOMAIN_LINKS_RESPONSE = 'ENABLE_CROSS_DOMAIN_LINKS_RESPONSE'

const DISABLE_CROSS_DOMAIN_LINKS_PENDING = 'DISABLE_CROSS_DOMAIN_LINKS_PENDING'
const DISABLE_CROSS_DOMAIN_LINKS_RESPONSE = 'DISABLE_CROSS_DOMAIN_LINKS_RESPONSE'

const SAVE_SHOP_DATA = 'SAVE_SHOP_DATA'

const ENABLE_LANGIFY_FEATURE_PENDING = 'ENABLE_LANGIFY_FEATURE_PENDING';
const ENABLE_LANGIFY_FEATURE_RESPONSE = 'ENABLE_LANGIFY_FEATURE_RESPONSE';
const DISABLE_LANGIFY_FEATURE_PENDING = 'DISABLE_LANGIFY_FEATURE_PENDING';
const DISABLE_LANGIFY_FEATURE_RESPONSE = 'DISABLE_LANGIFY_FEATURE_RESPONSE';

const UPDATE_AVAILABLE_AUTO_TRANSLATION_WORDS = 'UPDATE_AVAILABLE_AUTO_TRANSLATION_WORDS'
const RESET_ERRORS = 'RESET_ERRORS'

const cookies = new Cookies();

const initialState = {
  get_shop_pending: true,
  get_shop_progress_pending: true,
  get_shop_seo_pending: false,
  get_shop_seo_synchronized: false,
  save_shop_seo_pending: false,
  get_shop_payment_gateways_pending: false,
  get_shop_payment_gateways_synchronized: false,
  save_shop_payment_gateways_pending: false,
  get_shop_shipping_methods_pending: false,
  get_shop_shipping_methods_synchronized: false,
  save_shop_shipping_methods_pending: false,
  get_shop_policies_pending: false,
  get_shop_policies_synchronized: false,
  save_shop_policies_pending: false,
  sync_shop_metafields_pending: false,
  save_shop_metafields_pending: false,
  shop_metafields_synchronized: false,
  enable_cross_domain_links_pending: false,
  disable_cross_domain_links_pending: false,
  progress: 0,
  error: false,
  shop: {},
  seo: {},
  payment_gateways_progress: 0,
  payment_gateways: [],
  shipping_methods_progress: 0,
  shipping_methods: [],
  policies_progress: 0,
  policies: [],
  metafields_progress: 0,
  metafields: []
}

// REDUCER
export default (state = initialState, action) => {
  switch(action.type) {
    case RESET_ERRORS: {
      return {
        ...state,
        error: false,
      }
    }
    case GET_SHOP_PROGRESS_PENDING: {
      return {
        ...state,
        get_shop_progress_pending: true,
        error: false,
      }
    }
    case GET_SHOP_PROGRESS_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case GET_SHOP_PROGRESS_RESPONSE: {
      if(action.response.success === true) {
        return {
          ...state,
          seo: {...state.seo, progress: action.response.seo_progress},
          metafields_progress: action.response.metafields_progress,
          payment_gateways_progress: action.response.payment_gateways_progress,
          shipping_methods_progress: action.response.shipping_methods_progress,
          policies_progress: action.response.policies_progress,
          get_shop_progress_pending: false,
          error: false
        }
      } else {
        return {
          ...state,
          get_shop_progress_pending: false,
          error: {
            message: action.response,
            action: action.action,
            level: 0,
          },
        }
      }
    }
    case GET_SHOP_PENDING: {
      return {
        ...state,
        get_shop_pending: true,
        error: false,
      }
    }
    case GET_SHOP_RESPONSE: {
      if(action.response.success === true) {
        let language = cookies.get('language')
        if(language) {
          action.response.edit_language = language
        } else {
          cookies.set('language', action.response.edit_language, {path: '/'})
        }

        return {
          ...state,
          get_shop_pending: false,
          error: false,
          shop: action.response,
          edit_language: action.response.edit_language
        }
      } else {
        return {
          ...state,
          get_shop_pending: false,
          error: {
            message: action.response,
            action: action.action,
            level: 0,
          },
        }
      }
    }
    case EDIT_LANGUAGE_CHANGED: {
      return {
        ...state,
        shop: {
          ...state.shop,
          edit_language: action.language
        }
      }
    }

    case GET_SHOP_SEO_PENDING: {
      return {
        ...state,
        progress: 0,
        get_shop_seo_pending: true,
        error: false,
      }
    }
    case GET_SHOP_SEO_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case GET_SHOP_SEO_RESPONSE: {
      if(action.response.success === true) {
        return {
          ...state,
          get_shop_seo_synchronized: true,
          get_shop_seo_pending: false,
          error: false,
          seo: action.response.seo
        }
      } else {
        return {
          ...state,
          get_shop_seo_pending: false,
          error: {
            message: action.response,
            action: action.action,
            level: 1
          },
        }
      }
    }

    case SAVE_SHOP_SEO_PENDING: {
      return {
        ...state,
        progress: 0,
        save_shop_seo_pending: true,
        error: false,
      }
    }
    case SAVE_SHOP_SEO_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case SAVE_SHOP_SEO_RESPONSE: {
      if(action.response.success === true) {
        return {
          ...state,
          save_shop_seo_pending: false,
          error: false,
          seo: action.response.seo
        }
      } else {
        return {
          ...state,
          save_shop_seo_pending: false,
          seo: {
            source: { 
              title: state.seo.source.title,
              description: state.seo.source.description,
            },
            target: { 
              title: action.item.title,
              description: action.item.description,
            },
          },
          error: {
            message: action.response,
            action: action.action,
          },
        }
      }
    }
    
    case GET_SHOP_PAYMENT_GATEWAYS_PENDING: {
      return {
        ...state,
        progress: 0,
        get_shop_payment_gateways_pending: true,
        error: false,
      }
    }
    case GET_SHOP_PAYMENT_GATEWAYS_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case GET_SHOP_PAYMENT_GATEWAYS_RESPONSE: {
      if(action.response.success === true) {
        return {
          ...state,
          get_shop_payment_gateways_synchronized: true,
          get_shop_payment_gateways_pending: false,
          error: false,
          payment_gateways: action.response.payment_gateways
        }
      } else {
        return {
          ...state,
          get_shop_payment_gateways_pending: false,
          error: {
            message: action.response,
            action: action.action,
            level: 1
          },
        }
      }
    }

    case SAVE_SHOP_PAYMENT_GATEWAYS_PENDING: {
      return {
        ...state,
        progress: 0,
        save_shop_payment_gateways_pending: true,
        error: false,
      }
    }
    case SAVE_SHOP_PAYMENT_GATEWAYS_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case SAVE_SHOP_PAYMENT_GATEWAYS_RESPONSE: {
      let error = false
      let newPaymentGateways = state.payment_gateways.slice()
      let newPaymentGatewaysProgress = state.payment_gateways_progress
      action.response.forEach(response => {
        if(response.success !== true) {
          error = {
            message: action.response,
            action: action.action,
          };
          action.items.forEach(paymentGateway => {
            newPaymentGateways.find(x => x.id == paymentGateway.id).target = paymentGateway.name
          })
        } else {
          newPaymentGatewaysProgress = response.payment_gateways_progress
          response.payment_gateways.forEach(paymentGateway => {
            newPaymentGateways.find(x => x.id == paymentGateway.id).target = paymentGateway.target
          })
        }
      })
      return {
        ...state,
        payment_gateways: newPaymentGateways,
        payment_gateways_progress: newPaymentGatewaysProgress,
        save_shop_payment_gateways_pending: false,
        error: error
      }
    }

    case GET_SHOP_SHIPPING_METHODS_PENDING: {
      return {
        ...state,
        progress: 0,
        get_shop_shipping_methods_pending: true,
        error: false,
      }
    }
    case GET_SHOP_SHIPPING_METHODS_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case GET_SHOP_SHIPPING_METHODS_RESPONSE: {
      if(action.response.success === true) {
        return {
          ...state,
          get_shop_shipping_methods_synchronized: true,
          get_shop_shipping_methods_pending: false,
          error: false,
          shipping_methods: action.response.shipping_methods
        }
      } else {
        return {
          ...state,
          get_shop_shipping_methods_pending: false,
          error: {
            message: action.response,
            action: action.action,
            level: 1
          },
        }
      }
    }

    case SAVE_SHOP_SHIPPING_METHODS_PENDING: {
      return {
        ...state,
        progress: 0,
        save_shop_shipping_methods_pending: true,
        error: false,
      }
    }
    case SAVE_SHOP_SHIPPING_METHODS_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case SAVE_SHOP_SHIPPING_METHODS_RESPONSE: {
      let error = false
      let newShippingMethods = state.shipping_methods.slice()
      let newShippingMethodsProgress = state.shipping_methods_progress
      action.response.forEach(response => {
        if(response.success !== true) {
          error = {
            message: action.response,
            action: action.action,
          };
          action.items.forEach(shippingMethod => {
            newShippingMethods.find(x => x.id == shippingMethod.id).target = shippingMethod.name
          })
        } else {
          newShippingMethodsProgress = response.shipping_methods_progress
          response.shipping_methods.forEach(shippingMethod => {
            newShippingMethods.find(x => x.id == shippingMethod.id).target = shippingMethod.target
          })
        }
      })
      return {
        ...state,
        shipping_methods: newShippingMethods,
        shipping_methods_progress: newShippingMethodsProgress,
        save_shop_shipping_methods_pending: false,
        error: error
      }
    }

    case GET_SHOP_POLICIES_PENDING: {
      return {
        ...state,
        progress: 0,
        get_shop_policies_pending: true,
        error: false,
      }
    }
    case GET_SHOP_POLICIES_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case GET_SHOP_POLICIES_RESPONSE: {
      if(action.response.success === true) {
        return {
          ...state,
          get_shop_policies_synchronized: true,
          get_shop_policies_pending: false,
          error: false,
          policies: action.response.policies.slice()
        }
      } else {
        return {
          ...state,
          get_shop_policies_pending: false,
          error: {
            message: action.response,
            action: action.action,
            level: 1
          },
        }
      }
    }

    case SAVE_SHOP_POLICIES_PENDING: {
      return {
        ...state,
        progress: 0,
        save_shop_policies_pending: true,
        error: false,
      }
    }
    case SAVE_SHOP_POLICIES_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case SAVE_SHOP_POLICIES_RESPONSE: {
      let error = false;
      let newPoliciesProgress = state.policies_progress
      let newPolicies = state.policies.slice()
      action.response.forEach((response, i) => {
        if(response.success !== true) {
          error = {
            message: action.response,
            action: action.action,
          };
          action.items.forEach(policy => {
            newPolicies.find(x => x.id == policy.id).target = policy.body
          })
        } else {
          newPoliciesProgress = response.policies_progress
          response.policies.forEach(policy => {
            newPolicies.find(x => x.id == policy.id).target = policy.target
          })
        }
      })
      return {
        ...state,
        policies: newPolicies,
        policies_progress: newPoliciesProgress,
        save_shop_policies_pending: false,
        error: error
      }
    }

    case SYNC_SHOP_METAFIELDS_PENDING: {
      return {
        ...state,
        progress: 0,
        sync_shop_metafields_pending: true,
        error: false,
      }
    }
    case SYNC_SHOP_METAFIELDS_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case SYNC_SHOP_METAFIELDS_RESPONSE: {
      if(action.response.success === true) {
        return {
          ...state,
          shop_metafields_synchronized: true,
          sync_shop_metafields_pending: false,
          metafields_progress: action.response.metafields_progress,
          metafields: action.response.metafields,
          error: false,
        }
      } else {
        return {
          ...state,
          sync_shop_metafields_pending: false,
          error: {
            message: action.response,
            action: action.action,
            level: 1
          },
        }
      }
    }

    case SAVE_SHOP_METAFIELDS_PENDING: {
      return {
        ...state,
        progress: 0,
        save_shop_metafields_pending: true,
        error: false,
      }
    }
    case SAVE_SHOP_METAFIELDS_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case SAVE_SHOP_METAFIELDS_RESPONSE: {
      let error = false
      let newMetafields = state.metafields.slice()
      let mfProgress = state.metafields_progress
      action.response.forEach(response => {
        if(response.success !== true) {
          error = {
            message: action.response,
            action: action.action,
          };
          action.items.forEach(metafield => {
            newMetafields.find(x => x.id == metafield.id).target = metafield.value
          })
        } else {
          mfProgress = response.metafields_progress
          response.metafields.forEach(metafield => {
            newMetafields.find(x => x.id == metafield.id).target = metafield.target
          })
        }
      })
      return {
        ...state,
        metafields_progress: mfProgress,
        metafields: newMetafields,
        save_shop_metafields_pending: false,
        error: error
      }
    }

    case UPDATE_AVAILABLE_AUTO_TRANSLATION_WORDS: {
      return {
        ...state,
        shop: {
          ...state.shop,
          auto_translations: {
            ...state.shop.auto_translations,
            total_used_words: action.totalUsedWords,
            available_words: action.availableWords
          }
        }
      }
    }

    case ENABLE_CROSS_DOMAIN_LINKS_PENDING: {
      return {
        ...state,
        progress: 0,
        enable_cross_domain_links_pending: true,
        error: false,
      }
    }
    case ENABLE_CROSS_DOMAIN_LINKS_RESPONSE: {
      if(action.response.success === true) {
        return {
          ...state,
          enable_cross_domain_links_pending: false,
          error: false,
          shop: {
            ...state.shop,
            cross_domain_links_enabled: action.response.cross_domain_links_enabled
          }
        }
      } else {
        return {
          ...state,
          enable_cross_domain_links_pending: false,
          error: {
            message: action.response,
            action: action.action,
            level: 1
          },
        }
      }
    }
    case DISABLE_CROSS_DOMAIN_LINKS_PENDING: {
      return {
        ...state,
        progress: 0,
        disable_cross_domain_links_pending: true,
        error: false,
      }
    }
    case DISABLE_CROSS_DOMAIN_LINKS_RESPONSE: {
      if(action.response.success === true) {
        return {
          ...state,
          disable_cross_domain_links_pending: false,
          error: false,
          shop: {
            ...state.shop,
            cross_domain_links_enabled: action.response.cross_domain_links_enabled
          }
        }
      } else {
        return {
          ...state,
          disable_cross_domain_links_pending: false,
          error: {
            message: action.response,
            action: action.action,
            level: 1
          },
        }
      }
    }
    case SAVE_SHOP_DATA: {
      let shop = deepMerge(state.shop,action.shop);
      return {
        ...state,
        shop: shop
      }
    }

    case ENABLE_LANGIFY_FEATURE_PENDING: {
      console.log(state)
      let shop = {...state.shop};
      let newFeatures = shop.langify_features.slice();
      let feature = newFeatures.find(x => x.name === action.featureName);
      console.log(feature)
      if(feature) {
        feature.is_enabled = true;
        shop.langify_features = newFeatures;
        return {
          ...state,
          shop: shop
        }
      } else {
        return state
      }
    }
    case DISABLE_LANGIFY_FEATURE_PENDING: {
      console.log(state)
      let shop = {...state.shop};
      let newFeatures = shop.langify_features.slice();
      let feature = newFeatures.find(x => x.name === action.featureName);
      console.log(feature)
      if(feature) {
        feature.is_enabled = false;
        shop.langify_features = newFeatures;
        return {
          ...state,
          shop: shop
        }
      } else {
        return state
      }
    }

    default:
      return state
  }
}

// ACTIONS
export const resetErrors = () => {
  return dispatch => {
    dispatch({
      type: RESET_ERRORS
    });
  }
}

export const getShopProgress = (language) => {  
  return dispatch => {
    dispatch({
      type: GET_SHOP_PROGRESS_PENDING
    })

    API.getShopProgress(
      language,
      progress => {
        dispatch({
          type: GET_SHOP_PROGRESS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: GET_SHOP_PROGRESS_RESPONSE,
          response: response
        }
      )
    })
  }
}

export const getShop = () => {  
  return dispatch => {
    dispatch({
      type: GET_SHOP_PENDING
    })

    API.getShop(response => {
      dispatch({
        type: GET_SHOP_RESPONSE,
        response: response
      })
    })
  }
}

export const setEditLanguage = (language) => {
  return dispatch => {
    cookies.set('language', language, {path: '/'})

    dispatch({
      type: EDIT_LANGUAGE_CHANGED,
      language: language
    })
  }
}

export const logout = (language) => {
  return dispatch => {
    API.logout(
      response => {
        if(response.success === true) {
          window.location.replace(response.redirect_uri);
        }
      }
    )
  }
}

export const getShopSEO = (language) => {  
  return dispatch => {
    dispatch({
      type: GET_SHOP_SEO_PENDING
    })

    API.getShopSEO(
      language,
      progress => {
        dispatch({
          type: GET_SHOP_SEO_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: GET_SHOP_SEO_RESPONSE,
          response: response,
          action: () => getShopSEO(language)
        }
      )
    })
  }
}

export const saveShopSEO = (language, item) => {  
  return dispatch => {
    dispatch({
      type: SAVE_SHOP_SEO_PENDING
    })

    API.saveShopSEO(
      language,
      item,
      progress => {
        dispatch({
          type: SAVE_SHOP_SEO_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SAVE_SHOP_SEO_RESPONSE,
          response: response,
          item: item,
          action: () => saveShopSEO(language, item),
        }
      )
    })
  }
}

export const getShopPaymentGateways = (language) => {  
  return dispatch => {
    dispatch({
      type: GET_SHOP_PAYMENT_GATEWAYS_PENDING
    })

    API.getShopPaymentGateways(
      language,
      progress => {
        dispatch({
          type: GET_SHOP_PAYMENT_GATEWAYS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: GET_SHOP_PAYMENT_GATEWAYS_RESPONSE,
          response: response,
          action: () => getShopPaymentGateways(language)
        }
      )
    })
  }
}

export const saveShopPaymentGateways = (language, items) => {  
  return dispatch => {
    dispatch({
      type: SAVE_SHOP_PAYMENT_GATEWAYS_PENDING
    })

    API.saveShopPaymentGateways(
      language,
      items,
      progress => {
        dispatch({
          type: SAVE_SHOP_PAYMENT_GATEWAYS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SAVE_SHOP_PAYMENT_GATEWAYS_RESPONSE,
          response: response,
          items: items,
          action: () => saveShopPaymentGateways(language, items)
        }
      )
    })
  }
}

export const getShopShippingMethods = (language) => {  
  return dispatch => {
    dispatch({
      type: GET_SHOP_SHIPPING_METHODS_PENDING
    })

    API.getShopShippingMethods(
      language,
      progress => {
        dispatch({
          type: GET_SHOP_SHIPPING_METHODS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: GET_SHOP_SHIPPING_METHODS_RESPONSE,
          response: response,
          action: () => getShopShippingMethods(language)
        }
      )
    })
  }
}

export const saveShopShippingMethods = (language, items) => {  
  return dispatch => {
    dispatch({
      type: SAVE_SHOP_SHIPPING_METHODS_PENDING
    })

    API.saveShopShippingMethods(
      language,
      items,
      progress => {
        dispatch({
          type: SAVE_SHOP_SHIPPING_METHODS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SAVE_SHOP_SHIPPING_METHODS_RESPONSE,
          response: response,
          items: items,
          action: () => saveShopShippingMethods(language, items)
        }
      )
    })
  }
}

export const getShopPolicies = (language) => {  
  return dispatch => {
    dispatch({
      type: GET_SHOP_POLICIES_PENDING
    })

    API.getShopPolicies(
      language,
      progress => {
        dispatch({
          type: GET_SHOP_POLICIES_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: GET_SHOP_POLICIES_RESPONSE,
          response: response,
          action: () => getShopPolicies(language)
        }
      )
    })
  }
}

export const saveShopPolicies = (language, items) => {  
  return dispatch => {
    dispatch({
      type: SAVE_SHOP_POLICIES_PENDING
    })

    API.saveShopPolicies(
      language,
      items,
      progress => {
        dispatch({
          type: SAVE_SHOP_POLICIES_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SAVE_SHOP_POLICIES_RESPONSE,
          response: response,
          items: items,
          action: () => saveShopPolicies(language, items)
        }
      )
    })
  }
}

export const syncMetafields = (language) => {  
  return dispatch => {
    dispatch({
      type: SYNC_SHOP_METAFIELDS_PENDING
    })

    API.syncShopMetafields(
      language,
      progress => {
        dispatch({
          type: SYNC_SHOP_METAFIELDS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SYNC_SHOP_METAFIELDS_RESPONSE,
          response: response,
          action: () => syncMetafields(language)
        }
      )
    })
  }
}

export const saveMetafields = (language, items) => {  
  return dispatch => {
    dispatch({
      type: SAVE_SHOP_METAFIELDS_PENDING
    })

    API.saveShopMetafields(
      language,
      items,
      progress => {
        dispatch({
          type: SAVE_SHOP_METAFIELDS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SAVE_SHOP_METAFIELDS_RESPONSE,
          response: response,
          items: items,
          action: () => saveMetafields(language, items)
        }
      )
    })
  }
}

export const updateAutoTranslations = (totalUsedWords, availableWords) => {
  return dispatch => {
    dispatch({
      type: UPDATE_AVAILABLE_AUTO_TRANSLATION_WORDS,
      totalUsedWords: totalUsedWords,
      availableWords: availableWords
    })
  }
}

export const enableCrossDomainLinks = () => {  
  return dispatch => {
    dispatch({
      type: ENABLE_CROSS_DOMAIN_LINKS_PENDING
    })

    API.enableCrossDomainLinks(
      response => {
        dispatch({
          type: ENABLE_CROSS_DOMAIN_LINKS_RESPONSE,
          response: response,
          action: () => enableCrossDomainLinks()
        }
      )
    })
  }
}

export const disableCrossDomainLinks = () => {  
  return dispatch => {
    dispatch({
      type: DISABLE_CROSS_DOMAIN_LINKS_PENDING
    })

    API.disableCrossDomainLinks(
      response => {
        dispatch({
          type: DISABLE_CROSS_DOMAIN_LINKS_RESPONSE,
          response: response,
          action: () => disableCrossDomainLinks()
        }
      )
    })
  }
}

export const saveShop = (shop) => {
  return dispatch => {
    dispatch({
      type: SAVE_SHOP_DATA,
      shop: shop
    })
  }
}




export const enableLangifyFeature = (featureName, callback) => {  
  return dispatch => {
    dispatch({
      type: ENABLE_LANGIFY_FEATURE_PENDING,
      featureName: featureName
    })

    API.enableLangifyFeature(
      featureName,
      response => {
        dispatch({
          type: ENABLE_LANGIFY_FEATURE_RESPONSE,
          response: response,
          action: () => enableCrossDomainLinks(featureName, callback)
        }
      )
    })
  }
}

export const disableLangifyFeature = (featureName, callback) => {  
  return dispatch => {
    dispatch({
      type: DISABLE_LANGIFY_FEATURE_PENDING,
      featureName: featureName
    })

    API.disableLangifyFeature(
      featureName,
      response => {
        dispatch({
          type: DISABLE_LANGIFY_FEATURE_RESPONSE,
          response: response,
          action: () => disableLangifyFeature(featureName, callback)
        }
      )
    })
  }
}