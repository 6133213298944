import React, {useState} from 'react';
import PropTypes from 'prop-types';
import withRouter from 'with-router'
import { bindActionCreators } from 'redux'
import { connect, useSelector, useDispatch } from 'react-redux'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import TextsmsOutlined from '@material-ui/icons/TextsmsOutlined';
import HelpOutlineOutlined from '@material-ui/icons/HelpOutlineOutlined';
import TrackChangesOutlined from '@material-ui/icons/TrackChangesOutlined';
import InfoTwoTone from '@material-ui/icons/InfoTwoTone';
import SmsTwoTone from '@material-ui/icons/SmsTwoTone';
import MailOutlined from '@material-ui/icons/MailOutlined';
import InfoIcon from '@material-ui/icons/Info';
import InsertChartOutlined from '@material-ui/icons/InsertChartOutlined';
import TranslateOutlined from '@material-ui/icons/TranslateOutlined';
import ChevronRightOutlined from '@material-ui/icons/ChevronRightOutlined';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom'
//import Joyride from 'react-joyride';
import {Radar, PieChart, Pie, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts';
import SimpleLineChart from './statistics/SimpleLineChart';
import RechargePlans from '../../components/RechargePlans';
import API from '../../api/api';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SmallProgressIndicator from '../../components/SmallProgressIndicator.js'
import {Tooltip as MUITooltip} from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import StarsRoundedIcon from '@material-ui/icons/StarsRounded';
import NotificationsNoneRoundedIcon from '@material-ui/icons/NotificationsNoneRounded';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Slide } from 'react-slideshow-image';
import { ResizeObserver } from '@juggle/resize-observer';
import GetSupport from '../../components/GetSupport';
import {BulkTranslationSettings} from '../../components/BulkTranslations';
import { Trans, withTranslation, useTranslation } from 'react-i18next';

const REACT_APP_ENABLE_AUTO_TRANSLATIONS = window.loadEnv("REACT_APP_ENABLE_AUTO_TRANSLATIONS", process.env.REACT_APP_ENABLE_AUTO_TRANSLATIONS);
const REACT_APP_DASHBOARD_SHOW_STATISTICS_TILE = window.loadEnv("REACT_APP_DASHBOARD_SHOW_STATISTICS_TILE", process.env.REACT_APP_DASHBOARD_SHOW_STATISTICS_TILE);

const onboardingSteps = [
  {
    target: '.languages-section',
    title: 'Languages',
    content: 'In this section you create and manage your different languages.',
    placement: 'bottom',
    spotlightPadding: 0,
    locale: { back: 'Back', close: 'Close', last: 'Last', skip: 'Skip', next: 'Next' },
    disableBeacon: true
  },
  {
    target: '.current-language-select',
    title: 'Current Language',
    content: 'Here you select the current translation language (If you have more than one).',
    placement: 'auto',
    placementBeacon: 'right',
    locale: { back: 'Back', close: 'Close', last: 'Last', skip: 'Skip', next: 'Next' },
    styles: { options: { zIndex: 90000 }},
    //disableBeacon: true
  },
  {
    target: '.current-theme-select',
    title: 'Current Theme',
    content: 'Select the theme you want to work in.',
    placement: 'auto',
    placementBeacon: 'right',
    locale: { back: 'Back', close: 'Close', last: 'Last', skip: 'Skip', next: 'Next' },
    //disableBeacon: true
  },


  {
    target: '.search-form',
    title: 'Search Feature',
    content: 'With your search feature you can find specific translations.',
    placement: 'right',
    placementBeacon: 'right',
    spotlightPadding: 0,
    styles: {options: { textAlign: 'left' }},
    locale: { back: 'Back', close: 'Close', last: 'Last', skip: 'Skip', next: 'Next' },
    //disableBeacon: true
  },
  {
    target: '.side-menu',
    title: 'Translation',
    content: 'Here you can add the translations for all contents you have in Shopify.',
    placement: 'auto',
    spotlightPadding: 0,
    locale: { back: 'Back', close: 'Close', last: 'Last', skip: 'Skip', next: 'Next' },
    //disableBeacon: true
  },
];

const data02 = [
  {
    "name": "Translated Fields",
    "value": 21400
  },
  {
    //"name": "Untranslated Fields",
    //"value": 4567
  },
];





const slideImages = [
  '/files/images/fast-simon-1.jpg',
  '/files/images/fast-simon-2.png',
  '/files/images/geolizr_banner_1.png',
  '/files/images/geolizr_banner_2_1.png',
  '/files/images/yoast1.png',
  '/files/images/yoast2.png',
  '/files/images/reconvert1.png',
  '/files/images/reconvert2.png',
  '/files/images/boost.jpg',
];
 
const properties = {
  duration: 10000,
  transitionDuration: 500,
  infinite: true,
  indicators: true,
  arrows: false,
  pauseOnHover: true,
  onChange: (oldIndex, newIndex) => {
    
  }
}
 
const Slideshow = () => {
  const [isPartner, setIsPartner] = useState(false);
  function changedSlide(oldIndex, newIndex) {
    if(newIndex < 2) {
      setIsPartner(true);
    } else {
      setIsPartner(false);
    }
  }
  return (
    <div id="bannerLoop" className="slide-container">
      <Slide className="raise-height" {...properties}
        onChange={changedSlide}
      >
        {/*
        <div className="each-slide" style={{backgroundColor: '#7077bc'}}>
          <div className="raise-height" style={{backgroundImage: `url('${slideImages[0]}')`, backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain'}}>
            <a href="https://shopify.instantsearchplus.com/track_referrer?src=langify" target="_blank" className="cta-btn"></a>
          </div>
        </div>
        <div className="each-slide" style={{backgroundColor: '#7077bc'}}>
          <div className="raise-height" style={{backgroundImage: `url('${slideImages[1]}')`, backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain'}}>
            <a href="https://shopify.instantsearchplus.com/track_referrer?src=langify" target="_blank" className="cta-btn"></a>
          </div>
        </div>
        <div className="each-slide" style={{backgroundColor: '#7077bc'}}>
          <div className="raise-height" style={{backgroundImage: `url('${slideImages[6]}')`, backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain'}}>
            <a href="https://apps.shopify.com/google-shopping-feed-socialshop?utm_source=partnership&utm_medium=banner&utm_campaign=langify&utm_content=" target="_blank" className="cta-btn"></a>
          </div>
        </div>
        <div className="each-slide" style={{backgroundColor: '#fff8ee'}}>
          <div className="raise-height" style={{backgroundImage: `url('${slideImages[7]}')`, backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain'}}>
            <a href="https://apps.shopify.com/reconvert-upsell-cross-sell?utm_source=banner-exchange&utm_medium=langify&utm_campaign=langify&utm_id=banner-exchange" target="_blank" className="cta-btn"></a>
          </div>
        </div>
        */}
        <div className="each-slide" style={{backgroundColor: '#7077bc'}}>
          <div className="raise-height" style={{backgroundImage: `url('${slideImages[2]}')`, backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain'}}>
            <a href="https://apps.shopify.com/geolizr?utm_source=Shopify&utm_medium=banner&utm_campaign=In-App-Banner" target="_blank" className="cta-btn"></a>
          </div>
        </div>
        <div className="each-slide" style={{backgroundColor: '#7077bc'}}>
          <div className="raise-height" style={{backgroundImage: `url('${slideImages[3]}')`, backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain'}}>
            <a href="https://apps.shopify.com/geolizr?utm_source=Shopify&utm_medium=banner&utm_campaign=In-App-Banner" target="_blank" className="cta-btn"></a>
          </div>
        </div>
        <div className="each-slide" style={{backgroundColor: '#fe647c'}}>
          <div className="raise-height" style={{backgroundImage: `url('${slideImages[8]}')`, backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain'}}>
            <a href="https://apps.shopify.com/product-filter-search?&utm_source=langify&utm_medium=inapp&utm_campaign=Partnership23" target="_blank" className="cta-btn"></a>
          </div>
        </div>
      </Slide>
      <span className="banner-label">
        <InfoOutlinedIcon style={{fill: 'white', fontSize: '1rem', margin: '-2px 2px 0 0'}} size="small" /> 
        {isPartner ? 'Partner App' : 'Featured Cooperation'}
      </span>
    </div>
  )
}




class TileRow extends React.Component {

  resizeObserver = null;

  constructor(props) {
    super(props);

    this.state = {
      showRechargePlans: false,
      showCircle: true,
      steps: onboardingSteps, 
      run: false,
    };
  }
  
  componentDidMount() {
    this.startAutoLoop();

    // Calculate which tile to show
    var mainTile = 'onboarding';
    if(REACT_APP_DASHBOARD_SHOW_STATISTICS_TILE == 'false' && REACT_APP_ENABLE_AUTO_TRANSLATIONS == 'true' && this.props.shop.auto_translations.enabled) mainTile = 'autotranslations';

    this.setState({
      activeMainTile: mainTile,
    }); 
  }

  componentWillReceiveProps(newProps) {}

  componentWillUnmount() {
    this.stopAutoLoop();
    if(this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  onClickGetSupportButton = () => {
    window.FreshWidget.show();
  }

  handleStartTour = () => {
    this.setState({
      run: true,
    });
  }

  callback = (data) => {
    const { action, index, type } = data;

    if(action === 'reset' || action === 'close') {
      this.setState({
        run: false,
      });
    }
  };

  openRechargePlans = () => {
    this.setState({
      showRechargePlans: true
    })
  }

  onCloseRechargePlans = () => {
    this.setState({
      showRechargePlans: false
    })
  }

  onBuyWords = (plan) => {
    API.buyWords(
      plan,
      res => {
        console.log(res)
      }
    )
  }

  tileLoaded = (ref) => {
    this.resizeObserver = new ResizeObserver((entries) => {
      const width = entries[0].contentRect.width;

      if(width > 575) {
        this.setState({showCircle: true});
      } else {
        this.setState({showCircle: false});
      }
    });
    this.resizeObserver.observe(document.getElementById("autoTranslationTile"));
  }

  setTileMode = (mode) => {
    this.setState({
      progressTileMode: mode,
      lockTileMode: true,
    });
    this.stopAutoLoop();
  }

  startAutoLoop = () => {
    if(!this.state.lockTileMode) {
      clearInterval(this.autoLoop);
      this.autoLoop = setInterval(() => {
        this.setState({progressTileMode: this.state.progressTileMode === 'statistic' ? 'newfields' : 'statistic'});
      }, 10000);
    }
  }

  stopAutoLoop = () => {
    if(this.autoLoop) {
      clearInterval(this.autoLoop);
    }
  }

  _isPastDate(dateString, days) {
    var givenDate = new Date(dateString);
    givenDate.setDate(givenDate.getDate() + days);
    var currentDate = new Date();
    if (currentDate < givenDate) {
      return false; // Given date is in the future
    } else {
      return true; // Given date is in the past or today
    }
  }

  renderAutoTranslationsTile = () => {
    const { t, classes, shop } = this.props;
    const { steps, run, progressTileMode, statisticsLoaded, showCircle } = this.state;
    const isNewMerchant = shop.charge ? this._isPastDate(shop.charge.created_at, shop.charge.trialDays) ? false : true : false;
    
    const format = num => {
      const n = String(num), p = n.indexOf('.')
      return n.replace(
        /\d(?=(?:\d{3})+(?:\.|$))/g,
        (m, i) => p < 0 || i < p ? `${m},` : m
      )
    }
    const availableWords = shop.auto_translations.available_words > 0 ? format(shop.auto_translations.available_words) : 0;
    const totalUsedWords = format(shop.auto_translations.total_used_words);
    const wordsLeft = shop.auto_translations.available_words;
    let colorClass = 'green';
    if(wordsLeft < 250) colorClass = 'yellowgreen';
    if(wordsLeft < 150) colorClass = 'orange'
    if(wordsLeft < 50) colorClass = 'red';

    let usedWordsLeftFontSize = '3.75rem';
    if(wordsLeft > 99999) {
      usedWordsLeftFontSize = '3rem';
    }
   
    return(<Grid item xs={12} md={8}>
      <Card id="autoTranslationTile" 
        className={classes.card} 
        ref={this.tileLoaded}
      >
        <CardContent className={classNames(classes.cardContent, classes.fluidContent)}>
          <Typography variant="subtitle1" style={{marginLeft: 24, position: 'relative', top: 9, fontSize: '1.17em', color: 'rgba(0, 0, 0, 0.87)', opacity: '0.65', paddingTop: 7, paddingBottom: 7}}>
            {t('autoTranslationsTile.title')}
          </Typography>
          <Divider style={{marginTop: 16, marginBottom: 0}} />  
          <div style={{display: 'flex', 
            //flexDirection: 'row-reverse', 
            position: 'relative', minHeight: 250}}>
            {showCircle &&
              <div style={{minWidth: 300, minHeight: 250, position: 'relative'}}>
                <PieChart width={300} height={250} style={{position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)'}}>
                  <Pie data={data02} endAngle={300} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={96} outerRadius={100} fill="#1a237e" />
                </PieChart>
                <Fade in={showCircle} style={{ transitionDelay: statisticsLoaded ? `1500ms` : '0ms' }}>
                  <div className={classes.progressNumContent}>                          
                    <Typography variant="h2" style={{'fontSize':usedWordsLeftFontSize}} classes={{root: this.props.classes.progressNum}}>{shop.auto_translations.available_words > 0 ? shop.auto_translations.available_words : 0}</Typography>
                    <Typography variant="h5" classes={{root: this.props.classes.progressNumText}}>{t('autoTranslationsTile.wordsLeft')}</Typography>
                  </div>            
                </Fade> 
              </div>
            }
            <div style={{textAlign: 'center', padding: '16px 24px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
              <Typography variant="h4" style={{margin: '0 0 12px 0'}}>
                <TranslateOutlined style={{marginRight: 12, fontSize: 35, verticalAlign: 'top' }} />
                {t('autoTranslationsTile.title')}
              </Typography>
              <Divider variant="middle" />
              <Typography variant="subtitle1" style={{marginBottom: 24, color: '#999', fontSize: '1.4rem', fontWeight:400, lineHeight: '1.4rem'}}>
                <small>
                  <Trans i18nKey='autoTranslationsTile.usedWordsInfo'>
                    {{availableWords}}
                    {{totalUsedWords}}
                  </Trans>
                </small>
              </Typography>

              <MUITooltip 
                title={
                  <React.Fragment>
                    <InfoIcon
                      style={{
                        float: 'right',
                        position: 'relative',
                        left: 8,
                        bottom: 6
                      }}
                    />
                    <div>
                      <Typography style={{ paddingBottom: 8 }}>
                        <span color="inherit">{t('buyAutoTranslationWordsDialog.tooltipTitle')}</span>
                      </Typography>
                      <span style={{ fontSize: 12 }}>
                        {t('buyAutoTranslationWordsDialog.tooltipDescription')}
                      </span>
                    </div>
                  </React.Fragment>
                } 
                //disableHoverListener={!isNewMerchant} 
                disableHoverListener={true} 
                arrow
              >
                <span>
                  <Button variant="outlined" color="primary" size="medium" onClick={this.openRechargePlans} 
                    //disabled={isNewMerchant} 
                    style={{marginBottom: 16}}
                  >
                    {t('autoTranslationsTile.getMoreWordsButton')} <ChevronRightOutlined style={{margin: '0 -10px 0 10px'}} />
                  </Button>                  
                </span>
              </MUITooltip>

              {(this.props.langify_features && this.props.langify_features.find(x => x.name === 'auto_translation_bulk') && this.props.langify_features.find(x => x.name === 'auto_translation_bulk').is_enabled === true ) &&
              //|| (installationDate >= bulkTranslationReleaseDate) && 
                <BulkTranslationSettings showInModalDialog />
              }
            </div>   
          </div>
        </CardContent>
      </Card>
    </Grid>);
  }

  renderSupportTile = () => {
    const { classes, shop } = this.props;
    const { steps, run, showCircle, currentTile, statisticsLoaded } = this.state;

    return <Card className={classes.card}>
      <CardContent className={classes.specialCardContent}>

        <div>
          <Typography className={classes.specialCardHeadline} gutterBottom variant="h6" style={{margin:0}}>
            Support
          </Typography>
          <Typography variant="body1" className={classes.cardBody} style={{ fontSize:'0.875rem' }}>
            No solution found in our <a href="http://support.langify-app.com/support/solutions/11000004136" target="_blank" style={{textDecoration: 'underline'}}>documentation</a>?<br/>Click "Get Support" and briefly describe your problem.
          </Typography>

          <div>
            <Button 
              variant="outlined" 
              size="medium"
              onClick={this.handleStartTour}
              style={{marginTop: 16, color: 'rgba(0,0,0,.5)', borderColor: '#ffffff', width: '100%'}}
            >
              Quick Tour <ChevronRightOutlined style={{margin: '0 -10px 0 10px', fill: '#ffffff'}} />
            </Button>

            <a href="http://support.langify-app.com/support/solutions/11000004136" target="_blank" style={{textDecoration: 'none'}}>
              <Button 
                variant="outlined" 
                size="medium"
                style={{marginTop: 16, color: 'rgba(0,0,0,.5)', borderColor: '#ffffff', width: '100%'}}
              >
                Documentation <ChevronRightOutlined style={{margin: '0 -10px 0 10px', fill: '#ffffff'}} />
              </Button>
            </a>
          </div>
        </div>

        <div>
          <Divider style={{marginTop: 16, marginBottom: 16, backgroundColor: 'rgba(255, 255, 255, 0.23)'}} />
          <Button 
            variant="contained" 
            size="large"
            fullWidth
            classes={{label: classes.specialCardBtn}}
            onClick={(event) => {this.onClickGetSupportButton(event)}}
          >
            Get Support <MailOutlined style={{marginLeft: 16}} />
          </Button>
        </div>
      </CardContent>
    </Card>;
  }

  render() {
    const { t, classes, shop } = this.props;
    const { steps, run, showCircle, currentTile, statisticsLoaded } = this.state;
    const format = num => {
      const n = String(num), p = n.indexOf('.')
      return n.replace(
        /\d(?=(?:\d{3})+(?:\.|$))/g,
        (m, i) => p < 0 || i < p ? `${m},` : m
      )
    }
    
    let wordsLeft = shop.auto_translations.available_words;
    let colorClass = 'green';
    if(wordsLeft < 250) colorClass = 'yellowgreen';
    if(wordsLeft < 150) colorClass = 'orange'
    if(wordsLeft < 50) colorClass = 'red';

    return(<React.Fragment>
      <Grid container alignItems="stretch" alignContent="stretch" spacing={3} style={{ marginTop: 8 }}>
  
        {/*<Joyride
          continuous
          spotlightClicks
          disableScrolling
          showProgress
          showSkipButton
          steps={steps}
          run={run}
          callback={this.callback}
          styles={{
            options: {
              zIndex: 9000,
              primaryColor: '#1a237e',
            }
          }}
        />*/}
        
        {this.state.activeMainTile === 'autotranslations' && 
          this.renderAutoTranslationsTile()
        }

        <Grid item xs={12} md={4}>
          { 
            //this.renderSupportTile()
          }
          <Card className={classes.card}>
            <CardContent className={classes.specialCardContent} style={{padding: 0, paddingBottom: '0px !important'}}>
              <Slideshow />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <RechargePlans
        visible={this.state.showRechargePlans}
        onClose={this.onCloseRechargePlans}
        onBuy={this.onBuyWords}
      />

      {/*
        <GetSupport
          onStartTour={this.handleStartTour} 
        />
      */}

      
    </React.Fragment>);
  }
}

const styles = theme => ({
  chartContainer: {
    marginLeft: -100,
  },

  radarChart: {
    padding: '10px 0',
    '& text': {
      fontSize: '.8rem',
    },
    '& .recharts-surface': {
      height: '230px !important',
      overflow: 'initial'
    }
  },


  autoTransText: {
    color: '#999 !important',
    fontSize: '1.75rem',
    fontWeight: '400 !important',
  },


  media: {
    width: '100%',
    height: 200,
    backgroundColor: '#fff',
  },
  icon: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: .1,
    transform: 'rotate(-15deg)'
  },

  card: {
    height: '100%',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: '#fff',
    background: 'linear-gradient(360deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.9) 100%)',
    position: 'relative',
    padding: '16px !important',
    [theme.breakpoints.down('sm')]: {
      minHeight: 0,
    },
  },
  statistics: {
    padding: '16px 0 0 0 !important',
  },
  fluidContent: {
    padding: '0 !important',
  },
  cardHeadline: {
    margin: '.5rem 0',
    fontWeight: 400,
    color: '#1a237e !important',
  },
  cardHeadline2: {
    fontSize: '1.8rem',
    fontWeight: 400,
    color: '#1a237e !important',
  },
  cardBody: {
    margin: '20px 0 0 0',
    color: '#999'
  },
  cardBtn: {
    marginTop: '.5rem 0',
    color: '#fff',
  },

  specialCardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    background: 'linear-gradient(360deg, rgba(26,35,126,1) 0%, rgba(26,35,126,0.9) 100%)',
    position: 'relative',
    //paddingBottom: '16px !important',
    [theme.breakpoints.down('sm')]: {
      minHeight: 0,
    },
    '& *': {
      color: '#fff',
      '& path': {
        color: 'rgba(0, 0, 0, 0.7) !important',
      }
    },
  },
  specialCardHeadline: {
    margin: '.5rem 0',
    fontWeight: 400,
    color: '#fff !important',
  },
  specialCardBtn: {
    marginTop: '.5rem 0',
    borderColor: 'rgba(255, 255, 255, 0.23)',
    color: 'rgba(0, 0, 0, 0.7) !important',
  },
  progressNumContent: {
    position: 'absolute',
    top: '50%',
    left: 0,
    transform: 'translateY(-50%)',
    textAlign: 'center',
    width: 300,
  },
  progressNum: {
    //color: '#1a237e !important',
    fontWeight: '300 !important',
  },
  progressNumText: {
    color: '#999 !important',
    fontWeight: '400 !important',
  },
  green: {
    color: 'green !important',
    //color: '#1a237e !important',
  },
  yellowgreen: {
    color: 'yellow !important',
  },
  orange: {
    color: 'orange !important',
  },
  red: {
    color: 'red !important',
  },
  label: {
    backgroundColor: '#5c6bc0',
    color: '#fff',
    borderRadius: 3,
    padding: '0 8px',
    wordBreak: 'normal',
    minWidth: '50px',
    textAlign: 'center'
  }
});

const mapStateToProps = ({ shop, stats }) => ({
  stats: stats,
  editLanguage: shop.shop.edit_language,
  shopUrl: shop.shop.url,
  langify_features: shop.shop.langify_features,
  charge: shop.shop.charge
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {},
    dispatch
  )

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(TileRow))))